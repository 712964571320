.layout4 {
  width: 1500px;
  height: 1500px;
}

.layout4_0 {
  position: absolute;
  height: 71px;
  left: 794px;
  /* border: 3px solid black;
border-radius: 2px; */
  top: 97px;
  width: 54px;
  clip-path: polygon(14% 7%, 88% 2%, 98% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
}

/* .layout4_1 {
    position: absolute;
    top: 141px;
    left: 793px;
    width: 57px;
    height: 30px;
    border: 3px solid black;
border-radius: 2px;
    clip-path: polygon(8% 0%, 90% 0%, 100% 92%, 0% 82%);
    cursor: pointer;
    text-align: center;
  } */
.layout4_2 {
  position: absolute;
  top: 167px;
  left: 789px;
  width: 64px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(8% 0, 92% 0, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
}

.layout4_3 {
  position: absolute;
  top: 199px;
  left: 787px;
  width: 68px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(5% 0%, 94% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
}

.layout4_4 {
  position: absolute;
  top: 231px;
  left: 780px;
  width: 78px;
  height: 35px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(8% 0%, 94% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
}

.layout4_5 {
  position: absolute;
  top: 263px;
  left: 776px;
  width: 84px;
  height: 36px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(8% 0%, 97% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
}

.layout4_6 {
  position: absolute;
  top: 296px;
  left: 774px;
  width: 90px;
  height: 48px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(4% 0%, 96% 7%, 100% 98%, 0% 100%);
  cursor: pointer;
  text-align: center;
}

.layout4_7 {
  position: absolute;
  top: 355px;
  left: 491px;
  width: 65px;
  height: 168px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(100% 0, 0 58%, 71% 100%);
  cursor: pointer;
  text-align: center;
}

.layout4_8 {
  position: absolute;
  top: 331px;
  left: 595px;
  width: 68px;
  height: 77px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(15% 0%, 100% 21%, 98% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(1deg);
  transform-origin: right top;
}

.layout4_9 {
  position: absolute;
  top: 351px;
  left: 659px;
  width: 68px;
  height: 62px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(2% 0%, 100% 15%, 98% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_10 {
  position: absolute;
  top: 339px;
  left: 771px;
  width: 98px;
  height: 36px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(4% 0%, 95% 15%, 100% 100%, 0% 95%);
  cursor: pointer;
  text-align: center;
}

.layout4_11 {
  position: absolute;
  top: 407px;
  left: 591px;
  width: 68px;
  height: 35px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(2% 0%, 100% 0%, 98% 100%, 0% 95%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_12 {
  position: absolute;
  top: 411px;
  left: 657px;
  width: 64px;
  height: 36px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(2% 0%, 100% 0%, 95% 98%, 0% 95%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_13 {
  position: absolute;
  top: 375px;
  left: 767px;
  width: 107px;
  height: 41px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 97% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(2deg);
  transform-origin: right top;
}

.layout4_14 {
  position: absolute;
  top: 440px;
  left: 587px;
  width: 67px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(5% 0%, 100% 3%, 99% 100%, 0% 94%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_15 {
  position: absolute;
  top: 445px;
  left: 652px;
  width: 64px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(2% 0%, 100% 3%, 97% 100%, 0% 94%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_16 {
  position: absolute;
  top: 415px;
  left: 763px;
  width: 115px;
  height: 40px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(2% 0%, 97% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_17 {
  position: absolute;
  top: 472px;
  left: 583px;
  width: 67px;
  height: 33px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 5%, 98% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_18 {
  position: absolute;
  top: 477px;
  left: 648px;
  width: 64px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(2% 0%, 100% 0%, 97% 100%, 0% 98%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_19 {
  position: absolute;
  top: 453px;
  left: 755px;
  width: 127px;
  height: 33px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(4% 0%, 96% 0%, 100% 100%, 2% 100%);
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_20 {
  position: absolute;
  top: 484px;
  left: 754px;
  width: 128px;
  height: 36px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_21 {
  position: absolute;
  top: 504px;
  left: 579px;
  width: 68px;
  height: 58px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 65%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_22 {
  position: absolute;
  top: 508px;
  left: 644px;
  width: 64px;
  height: 33px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(2% 0%, 100% 0%, 98% 99%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_23 {
  position: absolute;
  top: 517px;
  left: 750px;
  width: 130px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_24 {
  position: absolute;
  top: 540px;
  left: 642px;
  width: 63px;
  height: 40px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 72%);
  cursor: pointer;
  text-align: center;
  transform: rotate(5deg);
  transform-origin: right top;
}

.layout4_25 {
  position: absolute;
  top: 548px;
  left: 744px;
  width: 130px;
  height: 36px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_26 {
  position: absolute;
  top: 582px;
  left: 740px;
  width: 130px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_27 {
  position: absolute;
  top: 614px;
  left: 736px;
  width: 130px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_28 {
  position: absolute;
  top: 646px;
  left: 733px;
  width: 131px;
  height: 37px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_29 {
  position: absolute;
  top: 678px;
  left: 730px;
  width: 66px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_30 {
  position: absolute;
  top: 681px;
  left: 792px;
  width: 67px;
  height: 31px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_31 {
  position: absolute;
  top: 710px;
  left: 726px;
  width: 67px;
  height: 44px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_32 {
  position: absolute;
  top: 711px;
  left: 791px;
  width: 66px;
  height: 48px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_33 {
  position: absolute;
  top: 783px;
  left: 719px;
  width: 65px;
  height: 46px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_34 {
  position: absolute;
  top: 787px;
  left: 780px;
  width: 65px;
  height: 46px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_35 {
  position: absolute;
  top: 790px;
  left: 874px;
  width: 58px;
  height: 135px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(27% 0%, 66% 0%, 100% 100%, 0% 98%);
  cursor: pointer;
  text-align: center;
}

.layout4_36 {
  position: absolute;
  top: 828px;
  left: 713px;
  width: 65px;
  height: 30px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_37 {
  position: absolute;
  top: 832px;
  left: 777px;
  width: 65px;
  height: 30px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(2% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_38 {
  position: absolute;
  top: 855px;
  left: 710px;
  width: 65px;
  height: 33px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_39 {
  position: absolute;
  top: 861px;
  left: 773px;
  width: 65px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(2% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_40 {
  position: absolute;
  top: 883px;
  left: 583px;
  width: 80px;
  height: 52px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(0% 49%, 100% 7%, 100% 100%, 14% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_41 {
  position: absolute;
  top: 887px;
  left: 707px;
  width: 65px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_42 {
  position: absolute;
  top: 892px;
  left: 770px;
  width: 65px;
  height: 31px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_43 {
  position: absolute;
  top: 917px;
  left: 703px;
  width: 65px;
  height: 31px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_44 {
  position: absolute;
  top: 921px;
  left: 765px;
  width: 65px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_45 {
  position: absolute;
  top: 924px;
  left: 869px;
  width: 65px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(8% 0%, 97% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(2deg);
  transform-origin: right top;
}

.layout4_46 {
  position: absolute;
  top: 933px;
  left: 591px;
  width: 68px;
  height: 33px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(0% 0%, 100% 5%, 100% 100%, 16% 101%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_47 {
  position: absolute;
  top: 946px;
  left: 699px;
  width: 65px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_48 {
  position: absolute;
  top: 951px;
  left: 761px;
  width: 65px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_49 {
  position: absolute;
  top: 955px;
  left: 867px;
  width: 71px;
  height: 33px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(5% 0%, 94% 0%, 100% 100%, 0% 96%);
  cursor: pointer;
  text-align: center;
  transform: rotate(2deg);
  transform-origin: right top;
}

.layout4_50 {
  position: absolute;
  top: 964px;
  left: 575px;
  width: 81px;
  height: 71px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(29% 0, 100% 0, 100% 96%, 0 100%, 50% 84%);
  cursor: pointer;
  text-align: center;
  transform: rotate(6deg);
  transform-origin: right top;
}

.layout4_51 {
  position: absolute;
  top: 977px;
  left: 695px;
  width: 65px;
  height: 30px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_52 {
  position: absolute;
  top: 982px;
  left: 757px;
  width: 66px;
  height: 30px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_53 {
  position: absolute;
  top: 987px;
  left: 862px;
  width: 79px;
  height: 30px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(4% 0%, 94% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(2deg);
  transform-origin: right top;
}

.layout4_54 {
  position: absolute;
  top: 1005px;
  left: 691px;
  width: 66px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_55 {
  position: absolute;
  top: 1010px;
  left: 755px;
  width: 66px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_56 {
  position: absolute;
  top: 1014px;
  left: 861px;
  width: 79px;
  height: 33px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(4% 0%, 95% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(2deg);
  transform-origin: right top;
}

.layout4_57 {
  position: absolute;
  top: 1032px;
  left: 567px;
  width: 81px;
  height: 30px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(8% 0%, 100% 0%, 98% 100%, 11% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_58 {
  position: absolute;
  top: 1035px;
  left: 688px;
  width: 65px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_59 {
  position: absolute;
  top: 1040px;
  left: 751px;
  width: 67px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_60 {
  position: absolute;
  top: 1044px;
  left: 857px;
  width: 89px;
  height: 33px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(4% 0%, 95% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_61 {
  position: absolute;
  top: 1059px;
  left: 574px;
  width: 70px;
  height: 33px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 10% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_62 {
  position: absolute;
  top: 1066px;
  left: 684px;
  width: 65px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_63 {
  position: absolute;
  top: 1071px;
  left: 747px;
  width: 67px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_64 {
  position: absolute;
  top: 1074px;
  left: 852px;
  width: 97px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(4% 0%, 95% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_65 {
  position: absolute;
  top: 1090px;
  left: 578px;
  width: 62px;
  height: 30px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(0% 0%, 100% 3%, 100% 100%, 8% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_66 {
  position: absolute;
  top: 1095px;
  left: 681px;
  width: 65px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_67 {
  position: absolute;
  top: 1101px;
  left: 743px;
  width: 67px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_68 {
  position: absolute;
  top: 1105px;
  left: 850px;
  width: 103px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(4% 0%, 95% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_69 {
  position: absolute;
  top: 1118px;
  left: 583px;
  width: 54px;
  height: 116px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(0% 0%, 100% 0%, 92% 100%, 46% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_70 {
  position: absolute;
  top: 1126px;
  left: 677px;
  width: 65px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_71 {
  position: absolute;
  top: 1131px;
  left: 739px;
  width: 68px;
  height: 32px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_72 {
  position: absolute;
  top: 1134px;
  left: 845px;
  width: 112px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(4% 0%, 95% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_73 {
  position: absolute;
  top: 1157px;
  left: 674px;
  width: 66px;
  height: 29px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(5deg);
  transform-origin: right top;
}

.layout4_74 {
  position: absolute;
  top: 1162px;
  left: 738px;
  width: 65px;
  height: 29px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_75 {
  position: absolute;
  top: 1166px;
  left: 844px;
  width: 117px;
  height: 34px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 88% 100%, 0 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_76 {
  position: absolute;
  top: 1184px;
  left: 670px;
  width: 67px;
  height: 57px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(5deg);
  transform-origin: right top;
}

.layout4_77 {
  position: absolute;
  top: 1190px;
  left: 734px;
  width: 66px;
  height: 54px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0%, 100% 0%, 96% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(4deg);
  transform-origin: right top;
}

.layout4_78 {
  position: absolute;
  top: 1194px;
  left: 839px;
  width: 111px;
  height: 58px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(3% 0, 100% 0%, 70% 98%, 0% 100%);
  cursor: pointer;
  text-align: center;
  transform: rotate(3deg);
  transform-origin: right top;
}

.layout4_79 {
  position: absolute;
  top: 1277px;
  left: 818px;
  width: 100px;
  height: 101px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(75% 0, 43% 62%, 100% 100%, 1% 100%, 13% 0%);
  cursor: pointer;
  text-align: center;
}

.layout4_80 {
  position: absolute;
  top: 1377px;
  left: 815px;
  width: 147px;
  height: 46px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(70% 0, 100% 50%, 100% 97%, 0 95%, 3% 0%);
  cursor: pointer;
  text-align: center;
}

.layout4_81 {
  position: absolute;
  top: 1421px;
  left: 807px;
  width: 155px;
  height: 66px;
  border: 3px solid black;
  border-radius: 2px;
  clip-path: polygon(100% 0%, 100% 33%, 57% 74%, 0 100%, 5% 0);
  cursor: pointer;
  text-align: center;
}