.layout3 {
  width: 1500px;
  height: 2000px;
}

.layout3_0 {
  position: absolute;
  top: 418px;
  left: 239px;
  width: 61px;
  height: 50px;
  border: 2px solid black;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
}

.layout3_1 {
  position: absolute;
  top: 434px;
  left: 300px;
  width: 52px;
  height: 74px;
  border: 2px solid black;
  clip-path: polygon(0 0, 100% 18%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_2 {
  position: absolute;
  top: 451px;
  left: 383px;
  width: 50px;
  height: 33px;
  border: 2px solid black;
  clip-path: polygon(0 5%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_3 {
  position: absolute;
  top: 450px;
  left: 433px;
  width: 51px;
  height: 34px;
  border: 2px solid black;
  clip-path: polygon(0 0%, 100% 3%, 96% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_4 {
  position: absolute;
  top: 466px;
  left: 515px;
  width: 51px;
  height: 46px;
  border: 2px solid black;
  clip-path: polygon(0 0%, 100% 46%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_5 {
  position: absolute;
  top: 486px;
  left: 566px;
  width: 46px;
  height: 80px;
  border: 2px solid black;
  clip-path: polygon(0 0%, 100% 31%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_6 {
  position: absolute;
  top: 529px;
  left: 643px;
  width: 50px;
  height: 64px;
  border: 2px solid black;
  clip-path: polygon(0 0%, 100% 43%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_7 {
  position: absolute;
  top: 560px;
  left: 695px;
  width: 49px;
  height: 88px;
  border: 2px solid black;
  clip-path: polygon(0 0%, 100% 31%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_8 {
  position: absolute;
  top: 597px;
  left: 782px;
  width: 52px;
  height: 50px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(60% 0, 100% 27%, 100% 100%, 0 100%, 0% 26%);
}

.layout3_9 {
  position: absolute;
  top: 611px;
  left: 834px;
  width: 44px;
  height: 64px;
  border: 2px solid black;
  clip-path: polygon(0% 1%, 100% 34%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_10 {
  position: absolute;
  top: 657px;
  left: 910px;
  width: 50px;
  height: 71px;
  border: 1px solid black;
  clip-path: polygon(0% 1%, 100% 34%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_11 {
  position: absolute;
  top: 686px;
  left: 961px;
  width: 52px;
  height: 70px;
  border: 2px solid black;
  clip-path: polygon(0 0%, 100% 43%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_12 {
  position: absolute;
  top: 734px;
  left: 1041px;
  width: 89px;
  height: 51px;
  border: 2px solid black;
  clip-path: polygon(0 0%, 100% 98%, 100% 100%, 0% 100%);
  cursor: pointer;
}

.layout3_13 {
  position: absolute;
  top: 468px;
  left: 239px;
  width: 61px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_14 {
  position: absolute;
  top: 485px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_15 {
  position: absolute;
  top: 485px;
  left: 434px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_16 {
  position: absolute;
  top: 512px;
  left: 515px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_17 {
  position: absolute;
  top: 566px;
  left: 566px;
  width: 47px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_18 {
  position: absolute;
  top: 594px;
  left: 643px;
  width: 51px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_19 {
  position: absolute;
  top: 648px;
  left: 694px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_20 {
  position: absolute;
  top: 648px;
  left: 782px;
  width: 52px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_21 {
  position: absolute;
  top: 675px;
  left: 833px;
  width: 45px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_22 {
  position: absolute;
  top: 730px;
  left: 910px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_23 {
  position: absolute;
  top: 757px;
  left: 961px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_24 {
  position: absolute;
  top: 786px;
  left: 1042px;
  width: 90px;
  height: 32px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
}

.layout3_25 {
  position: absolute;
  top: 510px;
  left: 238px;
  width: 62px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_26 {
  position: absolute;
  top: 510px;
  left: 301px;
  width: 50px;
  height: 39px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_27 {
  position: absolute;
  top: 513px;
  left: 383px;
  width: 49px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_28 {
  position: absolute;
  top: 513px;
  left: 433px;
  width: 50px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_29 {
  position: absolute;
  top: 539px;
  left: 515px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_30 {
  position: absolute;
  top: 594px;
  left: 566px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_31 {
  position: absolute;
  top: 621px;
  left: 642px;
  width: 52px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_32 {
  position: absolute;
  top: 675px;
  left: 693px;
  width: 51px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_33 {
  position: absolute;
  top: 676px;
  left: 782px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_34 {
  position: absolute;
  top: 702px;
  left: 832px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_35 {
  position: absolute;
  top: 757px;
  left: 910px;
  width: 51px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_36 {
  position: absolute;
  top: 784px;
  left: 961px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_37 {
  position: absolute;
  top: 819px;
  left: 1043px;
  width: 77px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 86% 100%, 0% 100%);
}

.layout3_38 {
  position: absolute;
  top: 550px;
  left: 239px;
  width: 61px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_39 {
  position: absolute;
  top: 550px;
  left: 301px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_40 {
  position: absolute;
  top: 539px;
  left: 382px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_41 {
  position: absolute;
  top: 539px;
  left: 434px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_42 {
  position: absolute;
  top: 566px;
  left: 515px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_43 {
  position: absolute;
  top: 621px;
  left: 566px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_44 {
  position: absolute;
  top: 648px;
  left: 642px;
  width: 52px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_45 {
  position: absolute;
  top: 702px;
  left: 694px;
  width: 50px;
  height: 65px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_46 {
  position: absolute;
  top: 703px;
  left: 782px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_47 {
  position: absolute;
  top: 729px;
  left: 832px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_48 {
  position: absolute;
  top: 784px;
  left: 910px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_49 {
  position: absolute;
  top: 811px;
  left: 961px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_50 {
  position: absolute;
  top: 845px;
  left: 1042px;
  width: 68px;
  height: 42px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 84% 100%, 0% 100%);
}

.layout3_51 {
  position: absolute;
  top: 590px;
  left: 239px;
  width: 61px;
  height: 42px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_52 {
  position: absolute;
  top: 590px;
  left: 301px;
  width: 50px;
  height: 42px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_53 {
  position: absolute;
  top: 565px;
  left: 382px;
  width: 50px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_54 {
  position: absolute;
  top: 565px;
  left: 433px;
  width: 50px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_55 {
  position: absolute;
  top: 593px;
  left: 515px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_56 {
  position: absolute;
  top: 647px;
  left: 566px;
  width: 46px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_57 {
  position: absolute;
  top: 674px;
  left: 642px;
  width: 51px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_58 {
  position: absolute;
  top: 729px;
  left: 782px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_59 {
  position: absolute;
  top: 755px;
  left: 833px;
  width: 46px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_60 {
  position: absolute;
  top: 811px;
  left: 910px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_61 {
  position: absolute;
  top: 838px;
  left: 961px;
  width: 50px;
  height: 50px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_62 {
  position: absolute;
  top: 632px;
  left: 239px;
  width: 61px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_63 {
  position: absolute;
  top: 632px;
  left: 301px;
  width: 50px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_64 {
  position: absolute;
  top: 593px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_65 {
  position: absolute;
  top: 593px;
  left: 434px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_66 {
  position: absolute;
  top: 621px;
  left: 515px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_67 {
  position: absolute;
  top: 675px;
  left: 567px;
  width: 46px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_68 {
  position: absolute;
  top: 702px;
  left: 642px;
  width: 51px;
  height: 65px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_69 {
  position: absolute;
  top: 756px;
  left: 782px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_70 {
  position: absolute;
  top: 783px;
  left: 833px;
  width: 46px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_71 {
  position: absolute;
  top: 838px;
  left: 910px;
  width: 50px;
  height: 49px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_72 {
  position: absolute;
  top: 674px;
  left: 239px;
  width: 61px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_73 {
  position: absolute;
  top: 674px;
  left: 301px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_74 {
  position: absolute;
  top: 620px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_75 {
  position: absolute;
  top: 621px;
  left: 433px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_76 {
  position: absolute;
  top: 647px;
  left: 515px;
  width: 51px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_77 {
  position: absolute;
  top: 702px;
  left: 567px;
  width: 45px;
  height: 65px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_78 {
  position: absolute;
  top: 784px;
  left: 782px;
  width: 51px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_79 {
  position: absolute;
  top: 810px;
  left: 833px;
  width: 46px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_80 {
  position: absolute;
  top: 714px;
  left: 239px;
  width: 61px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_81 {
  position: absolute;
  top: 714px;
  left: 301px;
  width: 50px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_82 {
  position: absolute;
  top: 648px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_83 {
  position: absolute;
  top: 648px;
  left: 434px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_84 {
  position: absolute;
  top: 675px;
  left: 515px;
  width: 52px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_85 {
  position: absolute;
  top: 811px;
  left: 783px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_86 {
  position: absolute;
  top: 838px;
  left: 832px;
  width: 48px;
  height: 49px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_87 {
  position: absolute;
  top: 756px;
  left: 239px;
  width: 61px;
  height: 42px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_88 {
  position: absolute;
  top: 756px;
  left: 301px;
  width: 50px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_89 {
  position: absolute;
  top: 675px;
  left: 383px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_90 {
  position: absolute;
  top: 675px;
  left: 433px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_91 {
  position: absolute;
  top: 702px;
  left: 515px;
  width: 52px;
  height: 65px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_92 {
  position: absolute;
  top: 838px;
  left: 782px;
  width: 50px;
  height: 49px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_93 {
  position: absolute;
  top: 798px;
  left: 239px;
  width: 61px;
  height: 39px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_94 {
  position: absolute;
  top: 797px;
  left: 301px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_95 {
  position: absolute;
  top: 702px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_96 {
  position: absolute;
  top: 702px;
  left: 434px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_97 {
  position: absolute;
  top: 838px;
  left: 239px;
  width: 61px;
  height: 49px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_98 {
  position: absolute;
  top: 838px;
  left: 301px;
  width: 50px;
  height: 49px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_99 {
  position: absolute;
  top: 730px;
  left: 383px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_100 {
  position: absolute;
  top: 730px;
  left: 433px;
  width: 50px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_101 {
  position: absolute;
  top: 756px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_102 {
  position: absolute;
  top: 756px;
  left: 434px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_103 {
  position: absolute;
  top: 784px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_104 {
  position: absolute;
  top: 784px;
  left: 434px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_105 {
  position: absolute;
  top: 811px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_106 {
  position: absolute;
  top: 811px;
  left: 433px;
  width: 51px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_107 {
  position: absolute;
  top: 838px;
  left: 383px;
  width: 49px;
  height: 49px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_108 {
  position: absolute;
  top: 838px;
  left: 433px;
  width: 51px;
  height: 49px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_109 {
  position: absolute;
  top: 929px;
  left: 239px;
  width: 61px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_110 {
  position: absolute;
  top: 929px;
  left: 301px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_111 {
  position: absolute;
  top: 929px;
  left: 383px;
  width: 49px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_112 {
  position: absolute;
  top: 929px;
  left: 434px;
  width: 49px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_113 {
  position: absolute;
  top: 929px;
  left: 515px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_114 {
  position: absolute;
  top: 929px;
  left: 566px;
  width: 46px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_115 {
  position: absolute;
  top: 929px;
  left: 643px;
  width: 49px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_116 {
  position: absolute;
  top: 929px;
  left: 694px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_117 {
  position: absolute;
  top: 929px;
  left: 782px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_118 {
  position: absolute;
  top: 929px;
  left: 833px;
  width: 46px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_119 {
  position: absolute;
  top: 929px;
  left: 910px;
  width: 51px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_120 {
  position: absolute;
  top: 929px;
  left: 961px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_121 {
  position: absolute;
  top: 929px;
  left: 1043px;
  width: 40px;
  height: 134px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}

.layout3_122 {
  position: absolute;
  top: 970px;
  left: 239px;
  width: 61px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_123 {
  position: absolute;
  top: 969px;
  left: 301px;
  width: 50px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_124 {
  position: absolute;
  top: 970px;
  left: 383px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_125 {
  position: absolute;
  top: 970px;
  left: 434px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_126 {
  position: absolute;
  top: 970px;
  left: 515px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_127 {
  position: absolute;
  top: 970px;
  left: 566px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_128 {
  position: absolute;
  top: 970px;
  left: 643px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_129 {
  position: absolute;
  top: 970px;
  left: 694px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_130 {
  position: absolute;
  top: 970px;
  left: 783px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_131 {
  position: absolute;
  top: 970px;
  left: 833px;
  width: 45px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_132 {
  position: absolute;
  top: 970px;
  left: 910px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_133 {
  position: absolute;
  top: 970px;
  left: 961px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_134 {
  position: absolute;
  top: 1011px;
  left: 239px;
  width: 61px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_135 {
  position: absolute;
  top: 1011px;
  left: 301px;
  width: 50px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_136 {
  position: absolute;
  top: 998px;
  left: 383px;
  width: 49px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_137 {
  position: absolute;
  top: 998px;
  left: 434px;
  width: 49px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_138 {
  position: absolute;
  top: 997px;
  left: 515px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_139 {
  position: absolute;
  top: 998px;
  left: 567px;
  width: 46px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_140 {
  position: absolute;
  top: 998px;
  left: 643px;
  width: 50px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_141 {
  position: absolute;
  top: 998px;
  left: 694px;
  width: 50px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_142 {
  position: absolute;
  top: 998px;
  left: 783px;
  width: 49px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_143 {
  position: absolute;
  top: 998px;
  left: 833px;
  width: 45px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_144 {
  position: absolute;
  top: 998px;
  left: 910px;
  width: 50px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_145 {
  position: absolute;
  top: 998px;
  left: 961px;
  width: 50px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_146 {
  position: absolute;
  top: 1024px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_147 {
  position: absolute;
  top: 1024px;
  left: 433px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_148 {
  position: absolute;
  top: 1024px;
  left: 515px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_149 {
  position: absolute;
  top: 1024px;
  left: 567px;
  width: 45px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_150 {
  position: absolute;
  top: 1024px;
  left: 643px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_151 {
  position: absolute;
  top: 1024px;
  left: 694px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_152 {
  position: absolute;
  top: 1024px;
  left: 783px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_153 {
  position: absolute;
  top: 1024px;
  left: 833px;
  width: 46px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_154 {
  position: absolute;
  top: 1024px;
  left: 910px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_155 {
  position: absolute;
  top: 1024px;
  left: 961px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_156 {
  position: absolute;
  top: 1052px;
  left: 238px;
  width: 62px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_157 {
  position: absolute;
  top: 1053px;
  left: 301px;
  width: 50px;
  height: 39px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_158 {
  position: absolute;
  top: 1052px;
  left: 383px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_159 {
  position: absolute;
  top: 1052px;
  left: 434px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_160 {
  position: absolute;
  top: 1051px;
  left: 514px;
  width: 51px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_161 {
  position: absolute;
  top: 1052px;
  left: 566px;
  width: 46px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_162 {
  position: absolute;
  top: 1052px;
  left: 642px;
  width: 51px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_163 {
  position: absolute;
  top: 1052px;
  left: 694px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_164 {
  position: absolute;
  top: 1052px;
  left: 782px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_165 {
  position: absolute;
  top: 1052px;
  left: 833px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_166 {
  position: absolute;
  top: 1051px;
  left: 909px;
  width: 102px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_167 {
  position: absolute;
  top: 1094px;
  left: 238px;
  width: 113px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_168 {
  position: absolute;
  top: 1078px;
  left: 383px;
  width: 49px;
  height: 81px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_169 {
  position: absolute;
  top: 1078px;
  left: 434px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_170 {
  position: absolute;
  top: 1078px;
  left: 515px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_171 {
  position: absolute;
  top: 1079px;
  left: 566px;
  width: 46px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_172 {
  position: absolute;
  top: 1079px;
  left: 642px;
  width: 51px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_173 {
  position: absolute;
  top: 1079px;
  left: 693px;
  width: 51px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_174 {
  position: absolute;
  top: 1078px;
  left: 783px;
  width: 49px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_175 {
  position: absolute;
  top: 1078px;
  left: 833px;
  width: 46px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_176 {
  position: absolute;
  top: 1078px;
  left: 910px;
  width: 101px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_177 {
  position: absolute;
  top: 1134px;
  left: 238px;
  width: 114px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_178 {
  position: absolute;
  top: 1160px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_179 {
  position: absolute;
  top: 1105px;
  left: 434px;
  width: 50px;
  height: 81px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_180 {
  position: absolute;
  top: 1106px;
  left: 515px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_181 {
  position: absolute;
  top: 1106px;
  left: 567px;
  width: 44px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_182 {
  position: absolute;
  top: 1106px;
  left: 642px;
  width: 50px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_183 {
  position: absolute;
  top: 1106px;
  left: 695px;
  width: 49px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_184 {
  position: absolute;
  top: 1106px;
  left: 783px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_185 {
  position: absolute;
  top: 1106px;
  left: 833px;
  width: 45px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_186 {
  position: absolute;
  top: 1118px;
  left: 910px;
  width: 101px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_187 {
  position: absolute;
  top: 1133px;
  left: 515px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_188 {
  position: absolute;
  top: 1133px;
  left: 566px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_189 {
  position: absolute;
  top: 1134px;
  left: 643px;
  width: 50px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_190 {
  position: absolute;
  top: 1133px;
  left: 695px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_191 {
  position: absolute;
  top: 1133px;
  left: 783px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_192 {
  position: absolute;
  top: 1133px;
  left: 833px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_193 {
  position: absolute;
  top: 1175px;
  left: 239px;
  width: 113px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_194 {
  position: absolute;
  top: 1187px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_195 {
  position: absolute;
  top: 1187px;
  left: 434px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_196 {
  position: absolute;
  top: 1161px;
  left: 515px;
  width: 97px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_197 {
  position: absolute;
  top: 1161px;
  left: 642px;
  width: 52px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_198 {
  position: absolute;
  top: 1161px;
  left: 695px;
  width: 49px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_199 {
  position: absolute;
  top: 1160px;
  left: 782px;
  width: 50px;
  height: 56px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_200 {
  position: absolute;
  top: 1160px;
  left: 832px;
  width: 46px;
  height: 56px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_201 {
  position: absolute;
  top: 1160px;
  left: 910px;
  width: 50px;
  height: 56px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_202 {
  position: absolute;
  top: 1161px;
  left: 961px;
  width: 50px;
  height: 55px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(100% 0, 100% 48%, 83% 100%, 0 100%, 0 0);
}

.layout3_203 {
  position: absolute;
  top: 1217px;
  left: 239px;
  width: 112px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_204 {
  position: absolute;
  top: 1214px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_205 {
  position: absolute;
  top: 1213px;
  left: 434px;
  width: 49px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_206 {
  position: absolute;
  top: 1202px;
  left: 515px;
  width: 97px;
  height: 33px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_207 {
  position: absolute;
  top: 1187px;
  left: 643px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_208 {
  position: absolute;
  top: 1187px;
  left: 695px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_209 {
  position: absolute;
  top: 1214px;
  left: 643px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_210 {
  position: absolute;
  top: 1214px;
  left: 694px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_211 {
  position: absolute;
  top: 1258px;
  left: 239px;
  width: 112px;
  height: 49px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_212 {
  position: absolute;
  top: 1242px;
  left: 383px;
  width: 49px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_213 {
  position: absolute;
  top: 1242px;
  left: 433px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_214 {
  position: absolute;
  top: 1236px;
  left: 515px;
  width: 97px;
  height: 32px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_215 {
  position: absolute;
  top: 1241px;
  left: 643px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_216 {
  position: absolute;
  top: 1242px;
  left: 694px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_217 {
  position: absolute;
  top: 1244px;
  left: 782px;
  width: 66px;
  height: 32px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_218 {
  position: absolute;
  top: 1244px;
  left: 847px;
  width: 31px;
  height: 33px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_219 {
  position: absolute;
  top: 1244px;
  left: 879px;
  width: 29px;
  height: 32px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}

.layout3_220 {
  position: absolute;
  top: 1244px;
  left: 909px;
  width: 31px;
  height: 32px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_221 {
  position: absolute;
  top: 1244px;
  left: 940px;
  width: 54px;
  height: 32px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(100% 0, 100% 0%, 83% 100%, 0 100%, 0 0);
}

.layout3_222 {
  position: absolute;
  top: 1267px;
  left: 383px;
  width: 49px;
  height: 42px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_223 {
  position: absolute;
  top: 1269px;
  left: 433px;
  width: 51px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_224 {
  position: absolute;
  top: 1269px;
  left: 515px;
  width: 97px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_225 {
  position: absolute;
  top: 1269px;
  left: 643px;
  width: 49px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_226 {
  position: absolute;
  top: 1269px;
  left: 692px;
  width: 52px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_227 {
  position: absolute;
  top: 1277px;
  left: 782px;
  width: 66px;
  height: 32px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_228 {
  position: absolute;
  top: 1277px;
  left: 849px;
  width: 30px;
  height: 32px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_229 {
  position: absolute;
  top: 1277px;
  left: 879px;
  width: 29px;
  height: 32px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_230 {
  position: absolute;
  top: 1278px;
  left: 910px;
  width: 30px;
  height: 31px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_231 {
  position: absolute;
  top: 1277px;
  left: 940px;
  width: 44px;
  height: 32px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(100% 0, 100% 0%, 83% 100%, 0 100%, 0 0);
}

.layout3_232 {
  position: absolute;
  top: 1308px;
  left: 227px;
  width: 124px;
  height: 126px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}

.layout3_233 {
  position: absolute;
  top: 1310px;
  left: 382px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_234 {
  position: absolute;
  top: 1310px;
  left: 433px;
  width: 51px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_235 {
  position: absolute;
  top: 1337px;
  left: 383px;
  width: 49px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_236 {
  position: absolute;
  top: 1337px;
  left: 433px;
  width: 51px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_237 {
  position: absolute;
  top: 1337px;
  left: 515px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_238 {
  position: absolute;
  top: 1337px;
  left: 566px;
  width: 46px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_239 {
  position: absolute;
  top: 1337px;
  left: 643px;
  width: 51px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_240 {
  position: absolute;
  top: 1337px;
  left: 694px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_241 {
  position: absolute;
  top: 1337px;
  left: 782px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_242 {
  position: absolute;
  top: 1337px;
  left: 833px;
  width: 46px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_243 {
  position: absolute;
  top: 1337px;
  left: 910px;
  width: 59px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0%, 86% 100%, 0% 100%);
}

.layout3_244 {
  position: absolute;
  top: 1377px;
  left: 383px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_245 {
  position: absolute;
  top: 1378px;
  left: 434px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_246 {
  position: absolute;
  top: 1377px;
  left: 515px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_247 {
  position: absolute;
  top: 1377px;
  left: 566px;
  width: 46px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_248 {
  position: absolute;
  top: 1378px;
  left: 643px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_249 {
  position: absolute;
  top: 1377px;
  left: 694px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_250 {
  position: absolute;
  top: 1377px;
  left: 783px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_251 {
  position: absolute;
  top: 1377px;
  left: 833px;
  width: 45px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_252 {
  position: absolute;
  top: 1364px;
  left: 910px;
  width: 52px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 81% 100%, 0% 100%);
}

.layout3_253 {
  position: absolute;
  top: 1406px;
  left: 383px;
  width: 49px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_254 {
  position: absolute;
  top: 1406px;
  left: 433px;
  width: 50px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_255 {
  position: absolute;
  top: 1405px;
  left: 515px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_256 {
  position: absolute;
  top: 1406px;
  left: 567px;
  width: 45px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_257 {
  position: absolute;
  top: 1406px;
  left: 643px;
  width: 49px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_258 {
  position: absolute;
  top: 1406px;
  left: 694px;
  width: 50px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_259 {
  position: absolute;
  top: 1405px;
  left: 783px;
  width: 49px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_260 {
  position: absolute;
  top: 1406px;
  left: 833px;
  width: 45px;
  height: 25px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_261 {
  position: absolute;
  top: 1391px;
  left: 910px;
  width: 43px;
  height: 142px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(100% 0, 0 0, 0 100%);
}

.layout3_262 {
  position: absolute;
  top: 1433px;
  left: 383px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_263 {
  position: absolute;
  top: 1433px;
  left: 434px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_264 {
  position: absolute;
  top: 1433px;
  left: 515px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_265 {
  position: absolute;
  top: 1433px;
  left: 566px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_266 {
  position: absolute;
  top: 1433px;
  left: 643px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_267 {
  position: absolute;
  top: 1433px;
  left: 694px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_268 {
  position: absolute;
  top: 1433px;
  left: 782px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_269 {
  position: absolute;
  top: 1433px;
  left: 833px;
  width: 45px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_270 {
  position: absolute;
  top: 1460px;
  left: 382px;
  width: 101px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 18% 100%, 0 30%);
}

.layout3_271 {
  position: absolute;
  top: 1460px;
  left: 515px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_272 {
  position: absolute;
  top: 1460px;
  left: 567px;
  width: 45px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_273 {
  position: absolute;
  top: 1460px;
  left: 643px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_274 {
  position: absolute;
  top: 1460px;
  left: 694px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_275 {
  position: absolute;
  top: 1460px;
  left: 782px;
  width: 51px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_276 {
  position: absolute;
  top: 1460px;
  left: 833px;
  width: 45px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_277 {
  position: absolute;
  top: 1487px;
  left: 401px;
  width: 82px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 29% 100%);
}
.layout3_278 {
  position: absolute;
  top: 1486px;
  left: 515px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_279 {
  position: absolute;
  top: 1487px;
  left: 566px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_280 {
  position: absolute;
  top: 1487px;
  left: 643px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_281 {
  position: absolute;
  top: 1487px;
  left: 694px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_282 {
  position: absolute;
  top: 1487px;
  left: 782px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_283 {
  position: absolute;
  top: 1487px;
  left: 833px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_284 {
  position: absolute;
  top: 1514px;
  left: 428px;
  width: 55px;
  height: 56px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}

.layout3_285 {
  position: absolute;
  top: 1514px;
  left: 515px;
  width: 97px;
  height: 53px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_286 {
  position: absolute;
  top: 1514px;
  left: 643px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_287 {
  position: absolute;
  top: 1514px;
  left: 694px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_288 {
  position: absolute;
  top: 1514px;
  left: 782px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_289 {
  position: absolute;
  top: 1514px;
  left: 833px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_290 {
  position: absolute;
  top: 1541px;
  left: 643px;
  width: 49px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_291 {
  position: absolute;
  top: 1540px;
  left: 694px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_292 {
  position: absolute;
  top: 1541px;
  left: 782px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_293 {
  position: absolute;
  top: 1541px;
  left: 833px;
  width: 46px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_294 {
  position: absolute;
  top: 1568px;
  left: 515px;
  width: 97px;
  height: 54px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 18% 100%, 0 73%);
}

.layout3_295 {
  position: absolute;
  top: 1568px;
  left: 643px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_296 {
  position: absolute;
  top: 1568px;
  left: 694px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_297 {
  position: absolute;
  top: 1568px;
  left: 782px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_298 {
  position: absolute;
  top: 1568px;
  left: 833px;
  width: 46px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_299 {
  position: absolute;
  top: 1545px;
  left: 910px;
  width: 114px;
  height: 43px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}

.layout3_300 {
  position: absolute;
  top: 1622px;
  left: 527px;
  width: 85px;
  height: 80px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}

.layout3_301 {
  position: absolute;
  top: 1595px;
  left: 643px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_302 {
  position: absolute;
  top: 1596px;
  left: 694px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_303 {
  position: absolute;
  top: 1595px;
  left: 782px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_304 {
  position: absolute;
  top: 1595px;
  left: 833px;
  width: 45px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_305 {
  position: absolute;
  top: 1621px;
  left: 643px;
  width: 50px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_306 {
  position: absolute;
  top: 1622px;
  left: 694px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_307 {
  position: absolute;
  top: 1622px;
  left: 782px;
  width: 50px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_308 {
  position: absolute;
  top: 1622px;
  left: 832px;
  width: 47px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_309 {
  position: absolute;
  top: 1650px;
  left: 643px;
  width: 50px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_310 {
  position: absolute;
  top: 1650px;
  left: 694px;
  width: 50px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_311 {
  position: absolute;
  top: 1650px;
  left: 783px;
  width: 49px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_312 {
  position: absolute;
  top: 1650px;
  left: 833px;
  width: 46px;
  height: 41px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_313 {
  position: absolute;
  top: 1724px;
  left: 636px;
  width: 72px;
  height: 56px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}

.layout3_314 {
  position: absolute;
  top: 1724px;
  left: 707px;
  width: 37px;
  height: 84px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 68%);
}

.layout3_315 {
  position: absolute;
  top: 1724px;
  left: 782px;
  width: 50px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_316 {
  position: absolute;
  top: 1724px;
  left: 833px;
  width: 46px;
  height: 40px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_317 {
  position: absolute;
  top: 1765px;
  left: 782px;
  width: 50px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_318 {
  position: absolute;
  top: 1764px;
  left: 833px;
  width: 46px;
  height: 27px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_319 {
  position: absolute;
  top: 1792px;
  left: 783px;
  width: 49px;
  height: 80px;
  border: 2px solid black;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 60%);
}

.layout3_320 {
  position: absolute;
  top: 1792px;
  left: 833px;
  width: 45px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_321 {
  position: absolute;
  top: 1818px;
  left: 833px;
  width: 45px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
}

.layout3_322 {
  position: absolute;
  top: 1846px;
  left: 833px;
  width: 45px;
  height: 29px;
  border: 2px solid black;
  cursor: pointer;
}