#container {
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    /* height: 1000px; */
    /* margin-left: 50px; */
    /* margin-right: 50px; */
}

.layout11 {
    /* display: inline-block; */
    /* Styling */
    /*  */
    border: 1px solid white;
    width: 1600px;
    height: 1500px;
}

.cen {
    text-align: center;
}

.center1 {
    position: relative;
    text-align: center;
}

.layout11_0 {
    position: absolute;
    top: 615px;
    left: 82px;
    width: 40px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout11_1 {
    position: absolute;
    top: 634px;
    left: 82px;
    width: 40px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_2 {
    position: absolute;
    top: 653px;
    left: 82px;
    width: 40px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_3 {
    position: absolute;
    top: 673px;
    left: 82px;
    width: 40px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_4 {
    position: absolute;
    top: 692px;
    left: 82px;
    width: 40px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_5 {
    position: absolute;
    top: 711px;
    left: 82px;
    width: 40px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_6 {
    position: absolute;
    top: 730px;
    left: 82px;
    width: 40px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_7 {
    position: absolute;
    top: 749px;
    left: 82px;
    width: 40px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_8 {
    position: absolute;
    top: 768px;
    left: 82px;
    width: 40px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_9 {
    position: absolute;
    top: 787px;
    left: 82px;
    width: 40px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_10 {
    position: absolute;
    top: 807px;
    left: 82px;
    width: 40px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout11_11 {
    position: absolute;
    top: 587px;
    left: 146px;
    width: 44px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_12 {
    position: absolute;
    top: 615px;
    left: 146px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_13 {
    position: absolute;
    top: 635px;
    left: 146px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_14 {
    position: absolute;
    top: 655px;
    left: 146px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_15 {
    position: absolute;
    top: 673px;
    left: 146px;
    width: 44px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_16 {
    position: absolute;
    top: 692px;
    left: 146px;
    width: 44px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_17 {
    position: absolute;
    top: 711px;
    left: 146px;
    width: 44px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_18 {
    position: absolute;
    top: 730px;
    left: 146px;
    width: 44px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_19 {
    position: absolute;
    top: 749px;
    left: 146px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_20 {
    position: absolute;
    top: 769px;
    left: 146px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_21 {
    position: absolute;
    top: 788px;
    left: 146px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_22 {
    position: absolute;
    top: 808px;
    left: 146px;
    width: 44px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_23 {
    position: absolute;
    top: 856px;
    left: 146px;
    width: 44px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout11_24 {
    position: absolute;
    top: 886px;
    left: 146px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_25 {
    position: absolute;
    top: 904px;
    left: 146px;
    width: 44px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_26 {
    position: absolute;
    top: 923px;
    left: 146px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_27 {
    position: absolute;
    top: 942px;
    left: 146px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_28 {
    position: absolute;
    top: 962px;
    left: 146px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_29 {
    position: absolute;
    top: 981px;
    left: 146px;
    width: 44px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_30 {
    position: absolute;
    top: 1000px;
    left: 146px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_31 {
    position: absolute;
    top: 1019px;
    left: 146px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_32 {
    position: absolute;
    top: 1037px;
    left: 146px;
    width: 88px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_33 {
    position: absolute;
    top: 1057px;
    left: 161px;
    width: 73px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_34 {
    position: absolute;
    top: 1077px;
    left: 184px;
    width: 50px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_35 {
    position: absolute;
    top: 1020px;
    left: 189px;
    width: 46px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_36 {
    position: absolute;
    top: 1001px;
    left: 189px;
    width: 46px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_37 {
    position: absolute;
    top: 982px;
    left: 189px;
    width: 46px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_38 {
    position: absolute;
    top: 963px;
    left: 189px;
    width: 46px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_39 {
    position: absolute;
    top: 944px;
    left: 189px;
    width: 46px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_40 {
    position: absolute;
    top: 924px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_41 {
    position: absolute;
    top: 905px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_42 {
    position: absolute;
    top: 886px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_43 {
    position: absolute;
    top: 856px;
    left: 189px;
    width: 46px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_44 {
    position: absolute;
    top: 808px;
    left: 189px;
    width: 46px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_45 {
    position: absolute;
    top: 788px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_46{
    position: absolute;
    top: 769px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_47{
    position: absolute;
    top: 750px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_48{
    position: absolute;
    top: 731px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_49{
    position: absolute;
    top: 712px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_50{
    position: absolute;
    top: 692px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_51{
    position: absolute;
    top: 673px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_52{
    position: absolute;
    top: 654px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_53{
    position: absolute;
    top: 636px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_54{
    position: absolute;
    top: 616px;
    left: 189px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_55 {
    position: absolute;
    top: 587px;
    left: 189px;
    width: 46px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_56 {
    position: absolute;
    top: 587px;
    left: 258px;
    width: 46px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_57 {
    position: absolute;
    top: 616px;
    left: 258px;
    width: 46px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_58 {
    position: absolute;
    top: 635px;
    left: 258px;
    width: 46px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_59 {
    position: absolute;
    top: 653px;
    left: 258px;
    width: 46px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_60 {
    position: absolute;
    top: 672px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_61 {
    position: absolute;
    top: 692px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_62 {
    position: absolute;
    top: 710px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_63 {
    position: absolute;
    top: 729px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_64 {
    position: absolute;
    top: 749px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_65 {
    position: absolute;
    top: 768px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_66 {
    position: absolute;
    top: 787px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_67 {
    position: absolute;
    top: 807px;
    left: 258px;
    width: 46px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_68 {
    position: absolute;
    top: 855px;
    left: 258px;
    width: 46px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_69 {
    position: absolute;
    top: 885px;
    left: 258px;
    width: 46px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_70 {
    position: absolute;
    top: 904px;
    left: 258px;
    width: 46px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_71 {
    position: absolute;
    top: 923px;
    left: 258px;
    width: 46px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_72 {
    position: absolute;
    top: 942px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_73 {
    position: absolute;
    top: 961px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_74 {
    position: absolute;
    top: 980px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_75 {
    position: absolute;
    top: 999px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_76 {
    position: absolute;
    top: 1019px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_77 {
    position: absolute;
    top: 1038px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_78 {
    position: absolute;
    top: 1057px;
    left: 258px;
    width: 46px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_79 {
    position: absolute;
    top: 1077px;
    left: 258px;
    width: 46px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_80 {
    position: absolute;
    top: 1135px;
    left: 258px;
    width: 46px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_81 {
    position: absolute;
    top: 1172px;
    left: 274px;
    width: 38px;
    height: 25px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_82 {
    position: absolute;
    top: 1172px;
    left: 312px;
    width: 34px;
    height: 25px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_83 {
    position: absolute;
    top: 1135px;
    left: 304px;
    width: 41px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_84 {
    position: absolute;
    top: 1078px;
    left: 304px;
    width: 42px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_85 {
    position: absolute;
    top: 1057px;
    left: 304px;
    width: 42px;
    height: 21px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_86 {
    position: absolute;
    top: 1039px;
    left: 304px;
    width: 42px;
    height: 21px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_87 {
    position: absolute;
    top: 1020px;
    left: 304px;
    width: 42px;
    height: 21px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_88 {
    position: absolute;
    top: 1000px;
    left: 304px;
    width: 42px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_89 {
    position: absolute;
    top: 981px;
    left: 304px;
    width: 42px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_90 {
    position: absolute;
    top: 963px;
    left: 304px;
    width: 42px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_91 {
    position: absolute;
    top: 943px;
    left: 304px;
    width: 42px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_92 {
    position: absolute;
    top: 923px;
    left: 304px;
    width: 42px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_93 {
    position: absolute;
    top: 905px;
    left: 304px;
    width: 42px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_94 {
    position: absolute;
    top: 886px;
    left: 304px;
    width: 42px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_95 {
    position: absolute;
    top: 856px;
    left: 304px;
    width: 42px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_96 {
    position: absolute;
    top: 808px;
    left: 304px;
    width: 42px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_97 {
    position: absolute;
    top: 788px;
    left: 304px;
    width: 42px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_98 {
    position: absolute;
    top: 769px;
    left: 304px;
    width: 42px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_99 {
    position: absolute;
    top: 751px;
    left: 304px;
    width: 42px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_100 {
    position: absolute;
    top: 731px;
    left: 304px;
    width: 42px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_101 {
    position: absolute;
    top: 712px;
    left: 304px;
    width: 42px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_102 {
    position: absolute;
    top: 693px;
    left: 304px;
    width: 42px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_103 {
    position: absolute;
    top: 674px;
    left: 304px;
    width: 42px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_104 {
    position: absolute;
    top: 655px;
    left: 304px;
    width: 42px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_105{
    position: absolute;
    top: 636px;
    left: 304px;
    width: 42px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_106{
    position: absolute;
    top: 617px;
    left: 304px;
    width: 42px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_107 {
    position: absolute;
    top: 587px;
    left: 304px;
    width: 42px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_108 {
    position: absolute;
    top: 539px;
    left: 303px;
    width: 43px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_109 {
    position: absolute;
    top: 521px;
    left: 303px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_110 {
    position: absolute;
    top: 502px;
    left: 303px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_111 {
    position: absolute;
    top: 472px;
    left: 303px;
    width: 43px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_112 {
    position: absolute;
    top: 472px;
    left: 372px;
    width: 43px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_113 {
    position: absolute;
    top: 501px;
    left: 372px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_114 {
    position: absolute;
    top: 519px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_115 {
    position: absolute;
    top: 539px;
    left: 372px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_116 {
    position: absolute;
    top: 586px;
    left: 372px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_117 {
    position: absolute;
    top: 615px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_118 {
    position: absolute;
    top: 635px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_119 {
    position: absolute;
    top: 654px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_120 {
    position: absolute;
    top: 673px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_121 {
    position: absolute;
    top: 692px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_122 {
    position: absolute;
    top: 711px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_123 {
    position: absolute;
    top: 730px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_124 {
    position: absolute;
    top: 749px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_125 {
    position: absolute;
    top: 768px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_126 {
    position: absolute;
    top: 788px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_127 {
    position: absolute;
    top: 808px;
    left: 372px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_128 {
    position: absolute;
    top: 856px;
    left: 372px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_129 {
    position: absolute;
    top: 885px;
    left: 372px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_130 {
    position: absolute;
    top: 885px;
    left: 372px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_131 {
    position: absolute;
    top: 904px;
    left: 372px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_132 {
    position: absolute;
    top: 923px;
    left: 372px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_133 {
    position: absolute;
    top: 942px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_134 {
    position: absolute;
    top: 962px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_135 {
    position: absolute;
    top: 982px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}


.layout11_136 {
    position: absolute;
    top: 1002px;
    left: 372px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_137 {
    position: absolute;
    top: 1020px;
    left: 372px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_138 {
    position: absolute;
    top: 1039px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_139 {
    position: absolute;
    top: 1059px;
    left: 372px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_140 {
    position: absolute;
    top: 1079px;
    left: 372px;
    width: 43px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_141 {
    position: absolute;
    top: 1135px;
    left: 372px;
    width: 43px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_142 {
    position: absolute;
    top: 1172px;
    left: 372px;
    width: 43px;
    height: 24px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_143 {
    position: absolute;
    top: 1196px;
    left: 372px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_144 {
    position: absolute;
    top: 1215px;
    left: 372px;
    width: 43px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_145 {
    position: absolute;
    top: 1221px;
    left: 416px;
    width: 43px;
    height: 25px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_146 {
    position: absolute;
    top: 1203px;
    left: 416px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_147 {
    position: absolute;
    top: 1184px;
    left: 416px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_148 {
    position: absolute;
    top: 1164px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_149 {
    position: absolute;
    top: 1135px;
    left: 416px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_150 {
    position: absolute;
    top: 1078px;
    left: 416px;
    width: 43px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout11_151 {
    position: absolute;
    top: 1059px;
    left: 416px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_152 {
    position: absolute;
    top: 1039px;
    left: 416px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_153 {
    position: absolute;
    top: 1020px;
    left: 416px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_154 {
    position: absolute;
    top: 1001px;
    left: 416px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_155 {
    position: absolute;
    top: 982px;
    left: 416px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_156 {
    position: absolute;
    top: 963px;
    left: 416px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_157 {
    position: absolute;
    top: 944px;
    left: 416px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_158 {
    position: absolute;
    top: 924px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_159 {
    position: absolute;
    top: 905px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_160 {
    position: absolute;
    top: 887px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_161 {
    position: absolute;
    top: 856px;
    left: 416px;
    width: 43px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_162 {
    position: absolute;
    top: 807px;
    left: 416px;
    width: 43px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_163 {
    position: absolute;
    top: 789px;
    left: 416px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_164 {
    position: absolute;
    top: 770px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_165 {
    position: absolute;
    top: 750px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_166 {
    position: absolute;
    top: 731px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_167 {
    position: absolute;
    top: 711px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_168 {
    position: absolute;
    top: 693px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_169 {
    position: absolute;
    top: 674px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_170 {
    position: absolute;
    top: 655px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_171{
    position: absolute;
    top: 636px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_172{
    position: absolute;
    top: 616px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_173 {
    position: absolute;
    top: 586px;
    left: 416px;
    width: 43px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_174 {
    position: absolute;
    top: 539px;
    left: 416px;
    width: 43px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_175 {
    position: absolute;
    top: 520px;
    left: 416px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_176 {
    position: absolute;
    top: 502px;
    left: 416px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_177 {
    position: absolute;
    top: 482px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_178 {
    position: absolute;
    top: 463px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_179 {
    position: absolute;
    top: 444px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_180 {
    position: absolute;
    top: 425px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_181 {
    position: absolute;
    top: 405px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_182{
    position: absolute;
    top: 386px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_183{
    position: absolute;
    top: 367px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_184{
    position: absolute;
    top: 347px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_185{
    position: absolute;
    top: 328px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_186{
    position: absolute;
    top: 309px;
    left: 416px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_187 {
    position: absolute;
    top: 268px;
    left: 416px;
    width: 43px;
    height: 41px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(88% 10%, 100% 0, 100% 100%, 0% 100%);
}
.layout11_188 {
    position: absolute;
    top: 199px;
    left: 486px;
    width: 43px;
    height: 71px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 58%, 100% 0, 102% 100%, 0% 100%);
}
.layout11_189 {
    position: absolute;
    top: 270px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_190 {
    position: absolute;
    top: 289px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_191 {
    position: absolute;
    top: 308px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_192 {
    position: absolute;
    top: 327px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_193 {
    position: absolute;
    top: 347px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_194 {
    position: absolute;
    top: 366px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_195 {
    position: absolute;
    top: 384px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_196 {
    position: absolute;
    top: 404px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_197 {
    position: absolute;
    top: 424px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_198 {
    position: absolute;
    top: 443px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_199 {
    position: absolute;
    top: 462px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_200 {
    position: absolute;
    top: 481px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_201 {
    position: absolute;
    top: 500px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_202 {
    position: absolute;
    top: 519px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_203 {
    position: absolute;
    top: 539px;
    left: 486px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_204{
    position: absolute;
    top: 586px;
    left: 486px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_205 {
    position: absolute;
    top: 615px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_206 {
    position: absolute;
    top: 634px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_207 {
    position: absolute;
    top: 653px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_208 {
    position: absolute;
    top: 673px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_209 {
    position: absolute;
    top: 692px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_210 {
    position: absolute;
    top: 711px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_211 {
    position: absolute;
    top: 729px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_212 {
    position: absolute;
    top: 749px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_213 {
    position: absolute;
    top: 768px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_214 {
    position: absolute;
    top: 787px;
    left: 486px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_215 {
    position: absolute;
    top: 807px;
    left: 486px;
    width: 43px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_216 {
    position: absolute;
    top: 857px;
    left: 486px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_217 {
    position: absolute;
    top: 886px;
    left: 486px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_218 {
    position: absolute;
    top: 904px;
    left: 486px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_219 {
    position: absolute;
    top: 923px;
    left: 486px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_220{
    position: absolute;
    top: 943px;
    left: 486px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_221 {
    position: absolute;
    top: 962px;
    left: 486px;
    width: 51px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_222 {
    position: absolute;
    top: 981px;
    left: 486px;
    width: 59px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_223 {
    position: absolute;
    top: 1000px;
    left: 486px;
    width: 68px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_224 {
    position: absolute;
    top: 1019px;
    left: 486px;
    width: 75px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_225 {
    position: absolute;
    top: 1038px;
    left: 486px;
    width: 84px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_226 {
    position: absolute;
    top: 1057px;
    left: 486px;
    width: 86px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_227 {
    position: absolute;
    top: 1077px;
    left: 485px;
    width: 44px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_228 {
    position: absolute;
    top: 1134px;
    left: 485px;
    width: 44px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_229 {
    position: absolute;
    top: 1163px;
    left: 485px;
    width: 44px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_230 {
    position: absolute;
    top: 1182px;
    left: 485px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_231 {
    position: absolute;
    top: 1182px;
    left: 485px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_232 {
    position: absolute;
    top: 1200px;
    left: 485px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout11_233 {
    position: absolute;
    top: 1220px;
    left: 485px;
    width: 35px;
    height: 26px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout11_234 {
    position: absolute;
    top: 1220px;
    left: 520px;
    width: 48px;
    height: 26px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_235 {
    position: absolute;
    top: 1202px;
    left: 528px;
    width: 45px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_236 {
    position: absolute;
    top: 1183px;
    left: 528px;
    width: 45px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout11_237 {
    position: absolute;
    top: 1163px;
    left: 528px;
    width: 45px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_238 {
    position: absolute;
    top: 1134px;
    left: 528px;
    width: 45px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_239 {
    position: absolute;
    top: 1077px;
    left: 528px;
    width: 45px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_240 {
    position: absolute;
    top: 1126px;
    left: 596px;
    width: 45px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_241 {
    position: absolute;
    top: 1163px;
    left: 596px;
    width: 41px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_242 {
    position: absolute;
    top: 1183px;
    left: 596px;
    width: 35px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_243 {
    position: absolute;
    top: 1120px;
    left: 642px;
    width: 44px;
    height: 26px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-14deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 95%);
}
.layout11_244 {
    position: absolute;
    top: 1109px;
    left: 691px;
    width: 22px;
    height: 47px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-23deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 95%);
}
.layout11_245 {
    position: absolute;
    top: 1101px;
    left: 716px;
    width: 25px;
    height: 63px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-23deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 95%);
}
.layout11_246 {
    position: absolute;
    top: 1096px;
    left: 741px;
    width: 25px;
    height: 63px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-23deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 95%);
}
.layout11_247 {
    position: absolute;
    top: 1089px;
    left: 765px;
    width: 25px;
    height: 63px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 95%);
}
.layout11_248 {
    position: absolute;
    top: 1082px;
    left: 789px;
    width: 25px;
    height: 63px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 95%);
}
.layout11_249 {
    position: absolute;
    top: 1076px;
    left: 814px;
    width: 25px;
    height: 63px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 95%);
}
.layout11_250 {
    position: absolute;
    top: 1070px;
    left: 837px;
    width: 25px;
    height: 60px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_251 {
    position: absolute;
    top: 1064px;
    left: 862px;
    width: 25px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_252 {
    position: absolute;
    top: 1057px;
    left: 886px;
    width: 25px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_253 {
    position: absolute;
    top: 1050px;
    left: 910px;
    width: 25px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_254 {
    position: absolute;
    top: 1045px;
    left: 935px;
    width: 25px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_255 {
    position: absolute;
    top: 1038px;
    left: 959px;
    width: 25px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout11_256 {
    position: absolute;
    top: 1031px;
    left: 984px;
    width: 25px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_257 {
    position: absolute;
    top: 1026px;
    left: 1008px;
    width: 25px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_258 {
    position: absolute;
    top: 1019px;
    left: 1028px;
    width: 25px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_259 {
    position: absolute;
    top: 1013px;
    left: 1051px;
    width: 25px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_260 {
    position: absolute;
    top: 1043px;
    left: 1039px;
    width: 50px;
    height: 22px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_261 {
    position: absolute;
    top: 977px;
    left: 1101px;
    width: 18px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_262 {
    position: absolute;
    top: 985px;
    left: 1121px;
    width: 17px;
    height: 39px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_263 {
    position: absolute;
    top: 980px;
    left: 1138px;
    width: 17px;
    height: 39px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_264 {
    position: absolute;
    top: 976px;
    left: 1154px;
    width: 17px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_265 {
    position: absolute;
    top: 973px;
    left: 1170px;
    width: 17px;
    height: 34px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_266 {
    position: absolute;
    top: 969px;
    left: 1187px;
    width: 17px;
    height: 33px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_267 {
    position: absolute;
    top: 965px;
    left: 1204px;
    width: 17px;
    height: 33px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_268 {
    position: absolute;
    top: 962px;
    left: 1220px;
    width: 17px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_269 {
    position: absolute;
    top: 958px;
    left: 1237px;
    width: 17px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout11_270 {
    position: absolute;
    top: 954px;
    left: 1254px;
    width: 14px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_271 {
    position: absolute;
    top: 951px;
    left: 1268px;
    width: 17px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_272 {
    position: absolute;
    top: 947px;
    left: 1285px;
    width: 17px;
    height: 23px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_273 {
    position: absolute;
    top: 942px;
    left: 1302px;
    width: 17px;
    height: 23px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_274 {
    position: absolute;
    top: 938px;
    left: 1318px;
    width: 17px;
    height: 21px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-25deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_275 {
    position: absolute;
    top: 930px;
    left: 1334px;
    width: 54px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-20deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_276 {
    position: absolute;
    top: 955px;
    left: 1054px;
    width: 51px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_277 {
    position: absolute;
    top: 966px;
    left: 1030px;
    width: 26px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_278 {
    position: absolute;
    top: 973px;
    left: 1005px;
    width: 26px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_279 {
    position: absolute;
    top: 979px;
    left: 980px;
    width: 26px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_280 {
    position: absolute;
    top: 985px;
    left: 956px;
    width: 24px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_281 {
    position: absolute;
    top: 992px;
    left: 932px;
    width: 24px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_282 {
    position: absolute;
    top: 999px;
    left: 908px;
    width: 24px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_283 {
    position: absolute;
    top: 1004px;
    left: 883px;
    width: 24px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_284 {
    position: absolute;
    top: 1011px;
    left: 859px;
    width: 24px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_285 {
    position: absolute;
    top: 1018px;
    left: 834px;
    width: 25px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_286 {
    position: absolute;
    top: 1024px;
    left: 810px;
    width: 25px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_287{
    position: absolute;
    top: 1024px;
    left: 810px;
    width: 25px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_288 {
    position: absolute;
    top: 1030px;
    left: 785px;
    width: 25px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_289 {
    position: absolute;
    top: 1036px;
    left: 760px;
    width: 25px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_290 {
    position: absolute;
    top: 1042px;
    left: 736px;
    width: 25px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_291 {
    position: absolute;
    top: 1049px;
    left: 712px;
    width: 25px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_292 {
    position: absolute;
    top: 1056px;
    left: 687px;
    width: 25px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_293 {
    position: absolute;
    top: 1056px;
    left: 687px;
    width: 25px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_294 {
    position: absolute;
    top: 1063px;
    left: 662px;
    width: 26px;
    height: 27px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-24deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_295 {
    position: absolute;
    top: 1068px;
    left: 636px;
    width: 26px;
    height: 27px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-20deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_296 {
    position: absolute;
    top: 1077px;
    left: 600px;
    width: 38px;
    height: 27px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-16deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_297 {
    position: absolute;
    top: 1090px;
    left: 573px;
    width: 26px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_298 {
    position: absolute;
    top: 923px;
    left: 529px;
    width: 43px;
    height: 39px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_299 {
    position: absolute;
    top: 905px;
    left: 529px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_300 {
    position: absolute;
    top: 886px;
    left: 529px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_301 {
    position: absolute;
    top: 857px;
    left: 529px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_302 {
    position: absolute;
    top: 807px;
    left: 529px;
    width: 43px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_303 {
    position: absolute;
    top: 788px;
    left: 529px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_304 {
    position: absolute;
    top: 770px;
    left: 529px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_305 {
    position: absolute;
    top: 750px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_306 {
    position: absolute;
    top: 731px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_307 {
    position: absolute;
    top: 712px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_308 {
    position: absolute;
    top: 693px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_309 {
    position: absolute;
    top: 673px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_310{
    position: absolute;
    top: 654px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_311{
    position: absolute;
    top: 635px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_312{
    position: absolute;
    top: 615px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_313 {
    position: absolute;
    top: 586px;
    left: 529px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_314 {
    position: absolute;
    top: 539px;
    left: 529px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_315 {
    position: absolute;
    top: 520px;
    left: 529px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}


.layout11_316 {
    position: absolute;
    top: 502px;
    left: 529px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_317 {
    position: absolute;
    top: 482px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_318 {
    position: absolute;
    top: 463px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout11_319 {
    position: absolute;
    top: 444px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_320 {
    position: absolute;
    top: 424px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_321 {
    position: absolute;
    top: 405px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_322 {
    position: absolute;
    top: 386px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_323 {
    position: absolute;
    top: 367px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_324 {
    position: absolute;
    top: 348px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_325 {
    position: absolute;
    top: 328px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_326 {
    position: absolute;
    top: 310px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_327 {
    position: absolute;
    top: 291px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_328 {
    position: absolute;
    top: 271px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_329{
    position: absolute;
    top: 252px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_330{
    position: absolute;
    top: 233px;
    left: 529px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_331 {
    position: absolute;
    top: 198px;
    left: 529px;
    width: 43px;
    height: 35px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 21%, 102% 100%, 0% 100%);
}
.layout11_332 {
    position: absolute;
    top: 213px;
    left: 598px;
    width: 48px;
    height: 87px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 3% 0, 102% 100%, 0% 100%);
}
.layout11_333 {
    position: absolute;
    top: 299px;
    left: 598px;
    width: 63px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 79% 0, 102% 100%, 0% 100%);
}
.layout11_334 {
    position: absolute;
    top: 328px;
    left: 598px;
    width: 73px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 87% 0, 102% 100%, 0% 100%);
}
.layout11_335 {
    position: absolute;
    top: 347px;
    left: 598px;
    width: 85px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 87% 0, 102% 100%, 0% 100%);
}
.layout11_336 {
    position: absolute;
    top: 366px;
    left: 598px;
    width: 87px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_337 {
    position: absolute;
    top: 385px;
    left: 598px;
    width: 87px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_338 {
    position: absolute;
    top: 404px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_339 {
    position: absolute;
    top: 424px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_340 {
    position: absolute;
    top: 443px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_341 {
    position: absolute;
    top: 462px;
    left: 598px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_342 {
    position: absolute;
    top: 481px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_343 {
    position: absolute;
    top: 501px;
    left: 598px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_344 {
    position: absolute;
    top: 520px;
    left: 598px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_345 {
    position: absolute;
    top: 539px;
    left: 598px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_346 {
    position: absolute;
    top: 587px;
    left: 598px;
    width: 43px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_347 {
    position: absolute;
    top: 615px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_348 {
    position: absolute;
    top: 634px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_349 {
    position: absolute;
    top: 634px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_350 {
    position: absolute;
    top: 653px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_351 {
    position: absolute;
    top: 673px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_352 {
    position: absolute;
    top: 692px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_353 {
    position: absolute;
    top: 712px;
    left: 598px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_354{
    position: absolute;
    top: 731px;
    left: 598px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_355{
    position: absolute;
    top: 750px;
    left: 598px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_356{
    position: absolute;
    top: 768px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_357{
    position: absolute;
    top: 787px;
    left: 598px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_358 {
    position: absolute;
    top: 807px;
    left: 598px;
    width: 43px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_359 {
    position: absolute;
    top: 857px;
    left: 598px;
    width: 43px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_360 {
    position: absolute;
    top: 886px;
    left: 598px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_361 {
    position: absolute;
    top: 904px;
    left: 598px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_361 {
    position: absolute;
    top: 904px;
    left: 598px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_362 {
    position: absolute;
    top: 904px;
    left: 598px;
    width: 43px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_363 {
    position: absolute;
    top: 923px;
    left: 598px;
    width: 43px;
    height: 26px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_364 {
    position: absolute;
    top: 693px;
    left: 641px;
    width: 44px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_365 {
    position: absolute;
    top: 674px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_366 {
    position: absolute;
    top: 655px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_367 {
    position: absolute;
    top: 635px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_368{
    position: absolute;
    top: 615px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_369 {
    position: absolute;
    top: 587px;
    left: 641px;
    width: 44px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_370 {
    position: absolute;
    top: 540px;
    left: 641px;
    width: 44px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_371 {
    position: absolute;
    top: 520px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_372 {
    position: absolute;
    top: 502px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_373 {
    position: absolute;
    top: 482px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_374 {
    position: absolute;
    top: 463px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_375 {
    position: absolute;
    top: 444px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_376 {
    position: absolute;
    top: 425px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_377 {
    position: absolute;
    top: 405px;
    left: 641px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout11_378 {
    position: absolute;
    top: 520px;
    left: 711px;
    width: 40px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 85% 0, 109% 100%, 0% 100%);
}
.layout11_379 {
    position: absolute;
    top: 538px;
    left: 711px;
    width: 51px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 78% 0, 109% 100%, 0% 100%);
}
.layout11_380 {
    position: absolute;
    top: 567px;
    left: 711px;
    width: 58px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 86% 0, 109% 100%, 0% 100%);
}
.layout11_381 {
    position: absolute;
    top: 587px;
    left: 711px;
    width: 68px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 84% 0, 109% 100%, 0% 100%);
}
.layout11_382 {
    position: absolute;
    top: 615px;
    left: 711px;
    width: 75px;
    height: 21px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 90% 0, 109% 100%, 0% 100%);
}
.layout11_383 {
    position: absolute;
    top: 636px;
    left: 711px;
    width: 82px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 92% 0, 109% 100%, 0% 100%);
}
.layout11_384 {
    position: absolute;
    top: 654px;
    left: 711px;
    width: 89px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 92% 0, 109% 100%, 0% 100%);
}
.layout11_385 {
    position: absolute;
    top: 673px;
    left: 711px;
    width: 95px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 92% 0, 109% 100%, 0% 100%);
}