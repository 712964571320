#container {
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    /* height: 1000px; */
    /* margin-left: 50px; */
    /* margin-right: 50px; */
}

.layout9 {
    /* display: inline-block; */
    /* Styling */
    /*  */
    border: 1px solid white;
    width: 1500px;
    height: 700px;
}

.cen {
    text-align: center;
}

.center1 {
    position: relative;
    text-align: center;
}

.layout9_0 {
    position: absolute;
    top: 228px;
    left: 77px;
    width: 39px;
    height: 22px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(0 0, 100% 0, 80% 110%, -5% 100%);
}
.layout9_1 {
    position: absolute;
    top: 250px;
    left: 109px;
    width: 42px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_2 {
    position: absolute;
    top: 259px;
    left: 125px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_3 {
    position: absolute;
    top: 269px;
    left: 141px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_4 {
    position: absolute;
    top: 278px;
    left: 157px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_5 {
    position: absolute;
    top: 287px;
    left: 173px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_6 {
    position: absolute;
    top: 296px;
    left: 189px;
    width: 43px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_7 {
    position: absolute;
    top: 301px;
    left: 212px;
    width: 46px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-76deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_8 {
    position: absolute;
    top: 198px;
    left: 86px;
    width: 32px;
    height: 21px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 7%, 98% 0, 78% 107%, 0% 101%);
}
.layout9_9 {
    position: absolute;
    top: 164px;
    left: 86px;
    width: 46px;
    height: 22px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 7%, 98% 0, 78% 107%, 0% 101%);
}
.layout9_10 {
    position: absolute;
    top: 180px;
    left: 126px;
    width: 46px;
    height: 36px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% -9%, 98% 0, 75% 106%, -6% 109%);
}
.layout9_11 {
    position: absolute;
    top: 204px;
    left: 152px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}

.layout9_12 {
    position: absolute;
    top: 213px;
    left: 169px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_13 {
    position: absolute;
    top: 222px;
    left: 185px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_14 {
    position: absolute;
    top: 232px;
    left: 201px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_15 {
    position: absolute;
    top: 231px;
    left: 227px;
    width: 39px;
    height: 47px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-64deg);
    clip-path: polygon(0% 28%, 107% 2%, 94% 84%, -13% 114%);
}
.layout9_16 {
    position: absolute;
    top: 192px;
    left: 235px;
    width: 39px;
    height: 47px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-64deg);
    clip-path: polygon(0% 28%, 107% 2%, 94% 84%, -13% 114%);
}
.layout9_17 {
    position: absolute;
    top: 194px;
    left: 209px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_18 {
    position: absolute;
    top: 184px;
    left: 193px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_19 {
    position: absolute;
    top: 175px;
    left: 177px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_20 {
    position: absolute;
    top: 166px;
    left: 161px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_21 {
    position: absolute;
    top: 166px;
    left: 161px;
    width: 44px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}

.layout9_21 {
    position: absolute;
    top: 143px;
    left: 135px;
    width: 44px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-74deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}

.layout9_22 {
    position: absolute;
    top: 126px;
    left: 97px;
    width: 42px;
    height: 24px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-74deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_23 {
    position: absolute;
    top: 58px;
    left: 108px;
    width: 44px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_24 {
    position: absolute;
    top: 67px;
    left: 122px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(13% 7%, 98% 0, 88% 107%, 1% 101%);
}
.layout9_25 {
    position: absolute;
    top: 70px;
    left: 133px;
    width: 64px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(13% 1%, 88% 0, 88% 107%, 1% 101%);
}
.layout9_26 {
    position: absolute;
    top: 76px;
    left: 146px;
    width: 72px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(13% 1%, 88% 0, 88% 107%, 1% 101%);
}
.layout9_27 {
    position: absolute;
    top: 81px;
    left: 156px;
    width: 86px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(13% 1%, 88% 0, 88% 107%, 1% 101%);
}

.layout9_28 {
    position: absolute;
    top: 87px;
    left: 170px;
    width: 91px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(13% 1%, 88% 0, 88% 107%, 1% 101%);
}
.layout9_29 {
    position: absolute;
    top: 94px;
    left: 187px;
    width: 91px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(10% -6%, 94% 0, 88% 107%, 1% 101%);
}
.layout9_30 {
    position: absolute;
    top: 103px;
    left: 203px;
    width: 91px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(10% -6%, 94% 0, 88% 107%, 1% 101%);
}
.layout9_31 {
    position: absolute;
    top: 124px;
    left: 248px;
    width: 45px;
    height: 40px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-76deg);
    clip-path: polygon(14% 2%, 98% 0, 73% 100%, -7% 92%);
}
.layout9_32 {
    position: absolute;
    top: 86px;
    left: 256px;
    width: 45px;
    height: 40px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-76deg);
    clip-path: polygon(14% 2%, 98% 0, 73% 100%, -7% 92%);
}
.layout9_33 {
    position: absolute;
    top: 142px;
    left: 285px;
    width: 66px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(9% -1%, 79% 0, 65% 100%, -8% 97%);
}
.layout9_34 {
    position: absolute;
    top: 159px;
    left: 315px;
    width: 66px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(9% -1%, 79% 0, 65% 100%, -8% 97%);
}
.layout9_35 {
    position: absolute;
    top: 109px;
    left: 304px;
    width: 42px;
    height: 33px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(21% 4%, 86% 0, 64% 100%, 0% 97%);
}
.layout9_36 {
    position: absolute;
    top: 127px;
    left: 333px;
    width: 42px;
    height: 33px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(21% 4%, 86% 0, 64% 100%, 0% 97%);
}
.layout9_37 {
    position: absolute;
    top: 77px;
    left: 303px;
    width: 55px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(21% 4%, 79% 0, 83% 101%, 0% 114%);
}
.layout9_38 {
    position: absolute;
    top: 94px;
    left: 332px;
    width: 56px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(21% 4%, 98% 0, 96% 101%, 0% 114%);
}
.layout9_39 {
    position: absolute;
    top: 372px;
    left: 334px;
    width: 47px;
    height: 35px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(21% 4%, 98% 0, 80% 96%, -3% 100%);
}
.layout9_40 {
    position: absolute;
    top: 394px;
    left: 352px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 87% 0, 74% 100%, 9% 103%);
}
.layout9_41 {
    position: absolute;
    top: 406px;
    left: 368px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 81% 100%, 13% 103%);
}
.layout9_42 {
    position: absolute;
    top: 415px;
    left: 384px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 81% 100%, 13% 103%);
}
.layout9_43 {
    position: absolute;
    top: 424px;
    left: 400px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 81% 100%, 13% 103%);
}
.layout9_44 {
    position: absolute;
    top: 433px;
    left: 416px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 81% 100%, 13% 103%);
}
.layout9_45 {
    position: absolute;
    top: 442px;
    left: 432px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 81% 100%, 13% 103%);
}

.layout9_46 {
    position: absolute;
    top: 451px;
    left: 448px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 81% 100%, 13% 103%);
}
.layout9_47 {
    position: absolute;
    top: 460px;
    left: 465px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 81% 100%, 13% 103%);
}
.layout9_48 {
    position: absolute;
    top: 469px;
    left: 481px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 81% 100%, 13% 103%);
}
.layout9_49 {
    position: absolute;
    top: 478px;
    left: 497px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 81% 100%, 13% 103%);
}
.layout9_50 {
    position: absolute;
    top: 487px;
    left: 514px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 81% 100%, 13% 103%);
}
.layout9_51 {
    position: absolute;
    top: 488px;
    left: 539px;
    width: 59px;
    height: 39px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(23% 0%, 89% 0, 70% 95%, 13% 95%);
}


.layout9_52 {
    position: absolute;
    top: 303px;
    left: 340px;
    width: 59px;
    height: 39px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(28% 0%, 89% 0, 70% 95%, 7% 98%);
}
.layout9_53 {
    position: absolute;
    top: 330px;
    left: 365px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_54 {
    position: absolute;
    top: 339px;
    left: 381px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_55 {
    position: absolute;
    top: 348px;
    left: 397px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_56 {
    position: absolute;
    top: 357px;
    left: 413px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_57 {
    position: absolute;
    top: 366px;
    left: 429px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_58 {
    position: absolute;
    top: 375px;
    left: 445px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_59 {
    position: absolute;
    top: 384px;
    left: 461px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_60 {
    position: absolute;
    top: 393px;
    left: 478px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_61 {
    position: absolute;
    top: 403px;
    left: 494px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_62 {
    position: absolute;
    top: 412px;
    left: 510px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_63 {
    position: absolute;
    top: 421px;
    left: 526px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 2%, 89% 0, 78% 97%, 16% 98%);
}
.layout9_64 {
    position: absolute;
    top: 424px;
    left: 551px;
    width: 59px;
    height: 36px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 1%, 89% 1%, 71% 102%, 8% 102%);
}

.layout9_65 {
    position: absolute;
    top: 267px;
    left: 348px;
    width: 59px;
    height: 36px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(25% 1%, 89% 1%, 71% 102%, 8% 102%);
}
.layout9_66 {
    position: absolute;
    top: 289px;
    left: 373px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(22% 3%, 85% 5%, 73% 98%, 12% 95%);
}
.layout9_67 {
    position: absolute;
    top: 299px;
    left: 389px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(22% 0%, 85% 5%, 75% 98%, 12% 95%);
}
.layout9_68 {
    position: absolute;
    top: 308px;
    left: 405px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-78deg);
    clip-path: polygon(22% 0%, 85% 5%, 75% 98%, 12% 95%);
}
.layout9_69 {
    position: absolute;
    top: 317px;
    left: 421px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% 5%, 75% 98%, 12% 95%);
}
.layout9_70 {
    position: absolute;
    top: 326px;
    left: 437px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% 5%, 75% 98%, 12% 95%);
}
.layout9_71 {
    position: absolute;
    top: 335px;
    left: 453px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% 5%, 75% 98%, 12% 95%);
}
.layout9_72 {
    position: absolute;
    top: 344px;
    left: 469px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% 5%, 75% 98%, 12% 95%);
}
.layout9_73 {
    position: absolute;
    top: 353px;
    left: 485px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% 5%, 75% 98%, 12% 95%);
}
.layout9_74 {
    position: absolute;
    top: 363px;
    left: 501px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% 5%, 75% 98%, 12% 95%);
}
.layout9_75 {
    position: absolute;
    top: 372px;
    left: 517px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% 5%, 75% 98%, 12% 95%);
}
.layout9_76 {
    position: absolute;
    top: 381px;
    left: 533px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(23% 0%, 86% 5%, 75% 99%, 12% 104%);
}
.layout9_77 {
    position: absolute;
    top: 384px;
    left: 559px;
    width: 59px;
    height: 36px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(24% -2%, 86% 1%, 66% 99%, 3% 104%);
}
.layout9_78 {
    position: absolute;
    top: 194px;
    left: 362px;
    width: 59px;
    height: 34px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(21% 0%, 86% 1%, 66% 99%, 1% 104%);
}

.layout9_79 {
    position: absolute;
    top: 219px;
    left: 386px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}

.layout9_80 {
    position: absolute;
    top: 228px;
    left: 402px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}

.layout9_81 {
    position: absolute;
    top: 237px;
    left: 418px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}
.layout9_82 {
    position: absolute;
    top: 247px;
    left: 434px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}
.layout9_83 {
    position: absolute;
    top: 255px;
    left: 450px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}
.layout9_84 {
    position: absolute;
    top: 264px;
    left: 466px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}
.layout9_85 {
    position: absolute;
    top: 274px;
    left: 482px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}
.layout9_86 {
    position: absolute;
    top: 283px;
    left: 499px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}
.layout9_87 {
    position: absolute;
    top: 292px;
    left: 515px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}
.layout9_88 {
    position: absolute;
    top: 301px;
    left: 531px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}
.layout9_89 {
    position: absolute;
    top: 310px;
    left: 547px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(22% 0%, 85% -1%, 75% 99%, 12% 104%);
}
.layout9_90 {
    position: absolute;
    top: 312px;
    left: 572px;
    width: 59px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(25% 0%, 87% 0%, 67% 99%, 1% 103%);
}
.layout9_91 {
    position: absolute;
    top: 155px;
    left: 369px;
    width: 59px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(25% 0%, 87% 0%, 67% 99%, 1% 103%);
}
.layout9_92 {
    position: absolute;
    top: 178px;
    left: 394px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_93 {
    position: absolute;
    top: 187px;
    left: 410px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_94 {
    position: absolute;
    top: 196px;
    left: 426px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_95 {
    position: absolute;
    top: 205px;
    left: 442px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_96 {
    position: absolute;
    top: 214px;
    left: 458px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_97 {
    position: absolute;
    top: 223px;
    left: 474px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_98 {
    position: absolute;
    top: 232px;
    left: 490px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_99 {
    position: absolute;
    top: 241px;
    left: 507px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_100 {
    position: absolute;
    top: 251px;
    left: 523px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_101 {
    position: absolute;
    top: 260px;
    left: 539px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_102 {
    position: absolute;
    top: 269px;
    left: 555px;
    width: 59px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 81% 0%, 70% 99%, 6% 102%);
}
.layout9_103 {
    position: absolute;
    top: 274px;
    left: 580px;
    width: 59px;
    height: 36px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 86% 0%, 66% 99%, 3% 102%);
}
.layout9_104 {
    position: absolute;
    top: 274px;
    left: 580px;
    width: 59px;
    height: 36px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 86% 0%, 66% 99%, 3% 102%);
}
.layout9_105 {
    position: absolute;
    top: 209px;
    left: 580px;
    width: 54px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 85% 0%, 73% 99%, 3% 102%);
}
.layout9_106 {
    position: absolute;
    top: 218px;
    left: 599px;
    width: 54px;
    height: 24px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 85% 0%, 73% 99%, 3% 102%);
}
.layout9_107 {
    position: absolute;
    top: 187px;
    left: 611px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 85% 0%, 73% 99%, 3% 102%);
}
.layout9_108 {
    position: absolute;
    top: 177px;
    left: 592px;
    width: 43px;
    height: 20px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 85% 0%, 73% 99%, 3% 102%);
}
.layout9_109 {
    position: absolute;
    top: 140px;
    left: 589px;
    width: 59px;
    height: 18px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 80% 0%, 92% 99%, 3% 102%);
}
.layout9_110 {
    position: absolute;
    top: 158px;
    left: 618px;
    width: 36px;
    height: 22px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 87% 0%, 70% 98%, 3% 110%);
}
.layout9_111 {
    position: absolute;
    top: 132px;
    left: 623px;
    width: 36px;
    height: 22px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(26% 0%, 92% 0%, 102% 98%, 3% 110%);
}
.layout9_112 {
    position: absolute;
    top: 524px;
    left: 586px;
    width: 58px;
    height: 33px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(17% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_113 {
    position: absolute;
    top: 543px;
    left: 609px;
    width: 57px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_114 {
    position: absolute;
    top: 553px;
    left: 626px;
    width: 57px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_115 {
    position: absolute;
    top: 562px;
    left: 643px;
    width: 57px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_116 {
    position: absolute;
    top: 571px;
    left: 659px;
    width: 57px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_117 {
    position: absolute;
    top: 579px;
    left: 675px;
    width: 57px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_118 {
    position: absolute;
    top: 588px;
    left: 691px;
    width: 57px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_119 {
    position: absolute;
    top: 597px;
    left: 707px;
    width: 57px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_120 {
    position: absolute;
    top: 607px;
    left: 723px;
    width: 57px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_121 {
    position: absolute;
    top: 616px;
    left: 740px;
    width: 57px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_122 {
    position: absolute;
    top: 624px;
    left: 762px;
    width: 57px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 116% 0%, 84% 97%, -14% 108%);
}
.layout9_123 {
    position: absolute;
    top: 556px;
    left: 780px;
    width: 46px;
    height: 27px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(23% 0%, 101% 0%, 82% 80%, 0% 104%);
}
.layout9_124 {
    position: absolute;
    top: 549px;
    left: 759px;
    width: 48px;
    height: 19px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(23% 0%, 101% 0%, 82% 98%, 0% 104%);
}
.layout9_125 {
    position: absolute;
    top: 541px;
    left: 742px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(23% 0%, 101% 0%, 82% 98%, 0% 104%);
}
.layout9_126 {
    position: absolute;
    top: 532px;
    left: 726px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(23% 0%, 101% 0%, 82% 98%, 0% 104%);
}

.layout9_127 {
    position: absolute;
    top: 523px;
    left: 710px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(23% 0%, 101% 0%, 82% 98%, 0% 104%);
}
.layout9_128 {
    position: absolute;
    top: 512px;
    left: 694px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_129 {
    position: absolute;
    top: 502px;
    left: 678px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_130 {
    position: absolute;
    top: 493px;
    left: 662px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_131 {
    position: absolute;
    top: 484px;
    left: 646px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}

.layout9_132 {
    position: absolute;
    top: 475px;
    left: 630px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_133 {
    position: absolute;
    top: 452px;
    left: 604px;
    width: 47px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(26% 0%, 104% 0%, 81% 98%, 0% 99%);
}
.layout9_134 {
    position: absolute;
    top: 414px;
    left: 613px;
    width: 47px;
    height: 37px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(26% 0%, 104% 0%, 81% 98%, 0% 99%);
}
.layout9_135 {
    position: absolute;
    top: 436px;
    left: 637px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_136 {
    position: absolute;
    top: 446px;
    left: 653px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_137{
    position: absolute;
    top: 446px;
    left: 653px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_138 {
    position: absolute;
    top: 454px;
    left: 669px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_139 {
    position: absolute;
    top: 463px;
    left: 685px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_140 {
    position: absolute;
    top: 473px;
    left: 701px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_141 {
    position: absolute;
    top: 482px;
    left: 717px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_142 {
    position: absolute;
    top: 491px;
    left: 733px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_143 {
    position: absolute;
    top: 499px;
    left: 749px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 81% 98%, 0% 99%);
}
.layout9_144 {
    position: absolute;
    top: 502px;
    left: 774px;
    width: 48px;
    height: 38px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(15% 0%, 101% 0%, 70% 83%, 0% 106%);
}
.layout9_145 {
    position: absolute;
    top: 341px;
    left: 623px;
    width: 54px;
    height: 33px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 95% 0%, 61% 120%, 0% 96%);
}

.layout9_146 {
    position: absolute;
    top: 361px;
    left: 647px;
    width: 54px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 84% 0%, 67% 120%, 0% 96%);
}
.layout9_147 {
    position: absolute;
    top: 371px;
    left: 663px;
    width: 54px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 84% 0%, 67% 120%, 0% 96%);
}
.layout9_148 {
    position: absolute;
    top: 380px;
    left: 679px;
    width: 54px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 84% 0%, 67% 120%, 0% 96%);
}
.layout9_149 {
    position: absolute;
    top: 389px;
    left: 696px;
    width: 54px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 84% 0%, 67% 120%, 0% 96%);
}
.layout9_150 {
    position: absolute;
    top: 399px;
    left: 712px;
    width: 54px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 84% 0%, 67% 120%, 0% 96%);
}
.layout9_151 {
    position: absolute;
    top: 408px;
    left: 728px;
    width: 54px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 84% 0%, 67% 120%, 0% 96%);
}
.layout9_152 {
    position: absolute;
    top: 408px;
    left: 728px;
    width: 54px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 84% 0%, 67% 120%, 0% 96%);
}
.layout9_153 {
    position: absolute;
    top: 417px;
    left: 744px;
    width: 54px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 84% 0%, 67% 120%, 0% 96%);
}
.layout9_154 {
    position: absolute;
    top: 419px;
    left: 769px;
    width: 54px;
    height: 39px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 85% 0%, 55% 130%, -6% 109%);
}
.layout9_155 {
    position: absolute;
    top: 303px;
    left: 632px;
    width: 52px;
    height: 35px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(23% 0%, 93% 0%, 64% 130%, -6% 109%);
}
.layout9_156 {
    position: absolute;
    top: 325px;
    left: 656px;
    width: 52px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 93% 0%, 64% 130%, -6% 109%);
}
.layout9_157 {
    position: absolute;
    top: 333px;
    left: 672px;
    width: 52px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_158 {
    position: absolute;
    top: 343px;
    left: 688px;
    width: 52px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_159 {
    position: absolute;
    top: 351px;
    left: 705px;
    width: 52px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_160 {
    position: absolute;
    top: 361px;
    left: 720px;
    width: 52px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_161 {
    position: absolute;
    top: 371px;
    left: 737px;
    width: 52px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_162 {
    position: absolute;
    top: 379px;
    left: 753px;
    width: 52px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_163 {
    position: absolute;
    top: 384px;
    left: 775px;
    width: 51px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_164 {
    position: absolute;
    top: 311px;
    left: 773px;
    width: 51px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(16% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_165 {
    position: absolute;
    top: 304px;
    left: 751px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_166 {
    position: absolute;
    top: 295px;
    left: 734px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_167 {
    position: absolute;
    top: 286px;
    left: 718px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_168 {
    position: absolute;
    top: 277px;
    left: 702px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_169 {
    position: absolute;
    top: 267px;
    left: 686px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_170 {
    position: absolute;
    top: 258px;
    left: 670px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_171 {
    position: absolute;
    top: 235px;
    left: 645px;
    width: 51px;
    height: 36px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(11% 0%, 85% 0%, 64% 130%, -6% 109%);
}
.layout9_172 {
    position: absolute;
    top: 199px;
    left: 651px;
    width: 51px;
    height: 36px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(24% 0%, 95% 0%, 64% 130%, -6% 109%);
}
.layout9_173 {
    position: absolute;
    top: 219px;
    left: 676px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 82% 0%, 64% 130%, -6% 109%);
}
.layout9_174 {
    position: absolute;
    top: 227px;
    left: 692px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 82% 0%, 64% 130%, -6% 109%);
}
.layout9_175 {
    position: absolute;
    top: 238px;
    left: 708px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(13% 0%, 82% 0%, 64% 130%, -6% 109%);
}
.layout9_176 {
    position: absolute;
    top: 247px;
    left: 725px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(10% 0%, 83% 0%, 68% 130%, -6% 109%);
}
.layout9_177 {
    position: absolute;
    top: 256px;
    left: 741px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(10% 0%, 83% 0%, 68% 130%, -6% 109%);
}
.layout9_178 {
    position: absolute;
    top: 266px;
    left: 757px;
    width: 51px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(10% 0%, 83% 0%, 68% 130%, -6% 109%);
}
.layout9_179 {
    position: absolute;
    top: 271px;
    left: 779px;
    width: 51px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-79deg);
    clip-path: polygon(10% 0%, 83% 0%, 68% 130%, -6% 90%);
}
.layout9_180 {
    position: absolute;
    top: 248px;
    left: 826px;
    width: 44px;
    height: 30px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_181 {
    position: absolute;
    top: 255px;
    left: 851px;
    width: 44px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_182 {
    position: absolute;
    top: 255px;
    left: 868px;
    width: 44px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_183 {
    position: absolute;
    top: 255px;
    left: 885px;
    width: 44px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_184 {
    position: absolute;
    top: 255px;
    left: 902px;
    width: 44px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_185 {
    position: absolute;
    top: 256px;
    left: 918px;
    width: 45px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_186 {
    position: absolute;
    top: 256px;
    left: 936px;
    width: 45px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_187 {
    position: absolute;
    top: 256px;
    left: 952px;
    width: 45px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_188 {
    position: absolute;
    top: 254px;
    left: 970px;
    width: 48px;
    height: 22px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_189 {
    position: absolute;
    top: 174px;
    left: 825px;
    width: 48px;
    height: 29px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_190 {
    position: absolute;
    top: 180px;
    left: 849px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_191 {
    position: absolute;
    top: 180px;
    left: 849px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_192 {
    position: absolute;
    top: 180px;
    left: 865px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_193 {
    position: absolute;
    top: 180px;
    left: 883px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_194 {
    position: absolute;
    top: 180px;
    left: 900px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_195 {
    position: absolute;
    top: 180px;
    left: 917px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_196 {
    position: absolute;
    top: 180px;
    left: 934px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_197 {
    position: absolute;
    top: 180px;
    left: 951px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_198 {
    position: absolute;
    top: 178px;
    left: 970px;
    width: 48px;
    height: 21px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_199 {
    position: absolute;
    top: 180px;
    left: 989px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_200 {
    position: absolute;
    top: 175px;
    left: 1013px;
    width: 48px;
    height: 28px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_201 {
    position: absolute;
    top: 181px;
    left: 1036px;
    width: 48px;
    height: 15px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_202 {
    position: absolute;
    top: 172px;
    left: 1061px;
    width: 48px;
    height: 34px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_203 {
    position: absolute;
    top: 122px;
    left: 1061px;
    width: 48px;
    height: 34px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_204 {
    position: absolute;
    top: 131px;
    left: 1036px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_205 {
    position: absolute;
    top: 131px;
    left: 1019px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_206 {
    position: absolute;
    top: 131px;
    left: 1002px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_207 {
    position: absolute;
    top: 131px;
    left: 985px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_208 {
    position: absolute;
    top: 131px;
    left: 968px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_209 {
    position: absolute;
    top: 131px;
    left: 951px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_210 {
    position: absolute;
    top: 131px;
    left: 951px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_211 {
    position: absolute;
    top: 131px;
    left: 934px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_212 {
    position: absolute;
    top: 131px;
    left: 917px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_213 {
    position: absolute;
    top: 131px;
    left: 901px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_214 {
    position: absolute;
    top: 131px;
    left: 884px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_215 {
    position: absolute;
    top: 131px;
    left: 867px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_216 {
    position: absolute;
    top: 131px;
    left: 849px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_217 {
    position: absolute;
    top: 123px;
    left: 825px;
    width: 48px;
    height: 31px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_218 {
    position: absolute;
    top: 173px;
    left: 1112px;
    width: 48px;
    height: 32px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_219 {
    position: absolute;
    top: 181px;
    left: 1137px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_220 {
    position: absolute;
    top: 180px;
    left: 1154px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_221 {
    position: absolute;
    top: 180px;
    left: 1172px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_222 {
    position: absolute;
    top: 180px;
    left: 1189px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}

.layout9_223 {
    position: absolute;
    top: 180px;
    left: 1205px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_224 {
    position: absolute;
    top: 172px;
    left: 1231px;
    width: 48px;
    height: 33px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_225 {
    position: absolute;
    top: 123px;
    left: 1231px;
    width: 48px;
    height: 33px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_226 {
    position: absolute;
    top: 131px;
    left: 1206px;
    width: 48px;
    height: 16px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_227 {
    position: absolute;
    top: 131px;
    left: 1190px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_228{
    position: absolute;
    top: 131px;
    left: 1172px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_229{
    position: absolute;
    top: 131px;
    left: 1155px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_230{
    position: absolute;
    top: 131px;
    left: 1139px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_231 {
    position: absolute;
    top: 123px;
    left: 1112px;
    width: 48px;
    height: 34px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}

.layout9_232 {
    position: absolute;
    top: 9px;
    left: 861px;
    width: 48px;
    height: 92px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 9% 0%, 100% 130%, -6% 100%);
}


.layout9_233 {
    position: absolute;
    top: 50px;
    left: 920px;
    width: 41px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}

.layout9_234 {
    position: absolute;
    top: 48px;
    left: 936px;
    width: 44px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_235 {
    position: absolute;
    top: 46px;
    left: 951px;
    width: 48px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_236 {
    position: absolute;
    top: 44px;
    left: 966px;
    width: 52px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_237 {
    position: absolute;
    top: 41px;
    left: 980px;
    width: 58px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_238 {
    position: absolute;
    top: 39px;
    left: 995px;
    width: 63px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_239 {
    position: absolute;
    top: 39px;
    left: 1010px;
    width: 65px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}

.layout9_240 {
    position: absolute;
    top: 39px;
    left: 1026px;
    width: 68px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_241 {
    position: absolute;
    top: 28px;
    left: 1052px;
    width: 68px;
    height: 36px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_242 {
    position: absolute;
    top: 54px;
    left: 1110px;
    width: 35px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_243 {
    position: absolute;
    top: 54px;
    left: 1128px;
    width: 35px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_244 {
    position: absolute;
    top: 18px;
    left: 1110px;
    width: 36px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_245 {
    position: absolute;
    top: 18px;
    left: 1127px;
    width: 36px;
    height: 17px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 130%, -6% 100%);
}
.layout9_246 {
    position: absolute;
    top: 195px;
    left: 1281px;
    width: 43px;
    height: 25px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 103% 0%, 100% 109%, 20% 98%);
}
.layout9_247 {
    position: absolute;
    top: 155px;
    left: 1284px;
    width: 36px;
    height: 26px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 103% 0%, 100% 109%, -9% 98%);
}
.layout9_248 {
    position: absolute;
    top: 119px;
    left: 1284px;
    width: 36px;
    height: 26px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 103% 0%, 100% 109%, -9% 98%);
}
.layout9_249 {
    position: absolute;
    top: 49px;
    left: 1284px;
    width: 36px;
    height: 26px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 103% 0%, 100% 109%, -9% 98%);
}
.layout9_250 {
    position: absolute;
    top: 14px;
    left: 1284px;
    width: 36px;
    height: 26px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 103% 0%, 100% 109%, -9% 98%);
}
.layout9_251 {
    position: absolute;
    top: 163px;
    left: 1304px;
    width: 68px;
    height: 44px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 112% 0%, 100% 109%, 13% 101%);
}
.layout9_252 {
    position: absolute;
    top: 112px;
    left: 1318px;
    width: 35px;
    height: 41px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 112% 0%, 100% 109%, -13% 101%);
}
.layout9_253 {
    position: absolute;
    top: 30px;
    left: 1291px;
    width: 75px;
    height: 27px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(-90deg);
    clip-path: polygon(0% 0%, 113% 0%, 105% 61%, -13% 101%);
}