.layout8 {
    width: 1366px;
    height: 1366px;
  }
  
  .layout8_0 {
    position: absolute;
    height: 62px;
    left: 121px;
    top: 386px;
    width: 54px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_1 {
    position: absolute;
    height: 29px;
    left: 121px;
    top: 451px;
    width: 54px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_2 {
    position: absolute;
    height: 29px;
    left: 121px;
    top: 483px;
    width: 54px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_3 {
    position: absolute;
    height: 29px;
    left: 121px;
    top: 515px;
    width: 54px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_4 {
    position: absolute;
    height: 29px;
    left: 121px;
    top: 547px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  
  .layout8_5 {
    position: absolute;
    height: 29px;
    left: 121px;
    top: 579px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_6 {
    position: absolute;
    height: 29px;
    left: 121px;
    top: 611px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_7 {
    position: absolute;
    height: 29px;
    left: 121px;
    top: 643px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_8 {
    position: absolute;
    height: 60px;
    left: 121px;
    top: 676px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }

  .layout8_9 {
    position: absolute;
    height: 60px;
    left: 149px;
    top: 676px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_10 {
    position: absolute;
    height: 30px;
    left: 149px;
    top: 643px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_11 {
    position: absolute;
    height: 30px;
    left: 149px;
    top: 611px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_12 {
    position: absolute;
    height: 30px;
    left: 149px;
    top: 579px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_13 {
    position: absolute;
    height: 30px;
    left: 149px;
    top: 547px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_14 {
    position: absolute;
    height: 45px;
    left: 199px;
    top: 403px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_15 {
    position: absolute;
    height: 30px;
    left: 199px;
    top: 450px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_16 {
    position: absolute;
    height: 30px;
    left: 199px;
    top: 482px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_17 {
    position: absolute;
    height: 30px;
    left: 199px;
    top: 514px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_18 {
    position: absolute;
    height: 30px;
    left: 199px;
    top: 547px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_19 {
    position: absolute;
    height: 30px;
    left: 199px;
    top: 579px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_20 {
    position: absolute;
    height: 30px;
    left: 199px;
    top: 611px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_21 {
    position: absolute;
    height: 30px;
    left: 199px;
    top: 643px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_22 {
    position: absolute;
    height: 61px;
    left: 199px;
    top: 675px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_23 {
    position: absolute;
    height: 61px;
    left: 226px;
    top: 675px;
    width: 30px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_24 {
    position: absolute;
    height: 30px;
    left: 226px;
    top: 643px;
    width: 30px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_25 {
    position: absolute;
    height: 30px;
    left: 226px;
    top: 611px;
    width: 30px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_26 {
    position: absolute;
    height: 30px;
    left: 226px;
    top: 579px;
    width: 30px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_27 {
    position: absolute;
    height: 30px;
    left: 226px;
    top: 547px;
    width: 30px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_28 {
    position: absolute;
    height: 30px;
    left: 226px;
    top: 514px;
    width: 30px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_29{
    position: absolute;
    height: 30px;
    left: 226px;
    top: 482px;
    width: 30px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_30{
    position: absolute;
    height: 30px;
    left: 226px;
    top: 451px;
    width: 30px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_31{
    position: absolute;
    height: 39px;
    left: 226px;
    top: 409px;
    width: 30px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  
  .layout8_32{
    position: absolute;
    height: 57px;
    left: 280px;
    top: 423px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_33{
    position: absolute;
    height: 29px;
    left: 280px;
    top: 483px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_34{
    position: absolute;
    height: 29px;
    left: 280px;
    top: 515px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_35{
    position: absolute;
    height: 29px;
    left: 280px;
    top: 547px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_36{
    position: absolute;
    height: 29px;
    left: 280px;
    top: 579px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_37{
    position: absolute;
    height: 29px;
    left: 280px;
    top: 611px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_38{
    position: absolute;
    height: 29px;
    left: 280px;
    top: 643px;
    width: 26px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_39{
    position: absolute;
    height: 61px;
    left: 280px;
    top: 675px;
    width: 26px; 
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_40{
    position: absolute;
    height: 61px;
    left: 308px;
    top: 675px;
    width: 28px; 
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_41{
    position: absolute;
    height: 30px;
    left: 308px;
    top: 643px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_42{
    position: absolute;
    height: 30px;
    left: 308px;
    top: 611px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_43{
    position: absolute;
    height: 30px;
    left: 308px;
    top: 579px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_44{
    position: absolute;
    height: 30px;
    left: 308px;
    top: 547px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_45{
    position: absolute;
    height: 30px;
    left: 308px;
    top: 515px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_46{
    position: absolute;
    height: 30px;
    left: 308px;
    top: 483px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_47{
    position: absolute;
    height: 52px;
    left: 308px;
    top: 428px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_48{
    position: absolute;
    height: 38px;
    left: 361px;
    top: 602px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_49{
    position: absolute;
    height: 30px;
    left: 361px;
    top: 643px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_50{
    position: absolute;
    height: 61px;
    left: 361px;
    top: 675px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_51{
    position: absolute;
    height: 61px;
    left: 392px;
    top: 675px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_52{
    position: absolute;
    height: 30px;
    left: 392px;
    top: 643px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_53{
    position: absolute;
    height: 38px;
    left: 392px;
    top: 602px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  
  .layout8_54{
    position: absolute;
    height: 38px;
    left: 447px;
    top: 602px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_55{
    position: absolute;
    height: 30px;
    left: 447px;
    top: 643px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_56{
    position: absolute;
    height: 30px;
    left: 447px;
    top: 643px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
 
  .layout8_57{
    position: absolute;
    height: 61px;
    left: 447px;
    top: 675px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }

  .layout8_58{
    position: absolute;
    height: 61px;
    left: 477px;
    top: 675px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_59{
    position: absolute;
    height: 30px;
    left: 477px;
    top: 643px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }

  .layout8_60{
    position: absolute;
    height: 38px;
    left: 477px;
    top: 602px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_61{
    position: absolute;
    height: 38px;
    left: 531px;
    top: 602px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_62{
    position: absolute;
    height: 29px;
    left: 531px;
    top: 643px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_63{
    position: absolute;
    height: 61px;
    left: 531px;
    top: 675px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_64{
    position: absolute;
    height: 61px;
    left: 561px;
    top: 675px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_65{
    position: absolute;
    height: 29px;
    left: 561px;
    top: 643px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_66{
    position: absolute;
    height: 38px;
    left: 561px;
    top: 602px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_67{
    position: absolute;
    height: 38px;
    left: 613px;
    top: 602px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_68{
    position: absolute;
    height: 29px;
    left: 613px;
    top: 643px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_69{
    position: absolute;
    height: 60px;
    left: 613px;
    top: 676px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_70{
    position: absolute;
    height: 62px;
    left: 361px;
    top: 776px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_71{
    position: absolute;
    height: 30px;
    left: 361px;
    top: 841px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_72{
    position: absolute;
    height: 30px;
    left: 361px;
    top: 873px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_73{
    position: absolute;
    height: 61px;
    left: 361px;
    top: 906px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_74{
    position: absolute;
    height: 61px;
    left: 392px;
    top: 906px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_75{
    position: absolute;
    height: 29px;
    left: 392px;
    top: 874px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_76{
    position: absolute;
    height: 29px;
    left: 392px;
    top: 842px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_77{
    position: absolute;
    height: 62px;
    left: 392px;
    top: 776px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_78{
    position: absolute;
    height: 62px;
    left: 447px;
    top: 776px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_79{
    position: absolute;
    height: 62px;
    left: 447px;
    top: 776px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_80{
    position: absolute;
    height: 30px;
    left: 447px;
    top: 841px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_81{
    position: absolute;
    height: 30px;
    left: 447px;
    top: 873px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_82{
    position: absolute;
    height: 59px;
    left: 447px;
    top: 905px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_83{
    position: absolute;
    height: 59px;
    left: 477px;
    top: 905px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_84{
    position: absolute;
    height: 30px;
    left: 477px;
    top: 873px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_85{
    position: absolute;
    height: 30px;
    left: 477px;
    top: 841px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_86{
    position: absolute;
    height: 62px;
    left: 477px;
    top: 776px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_87{
    position: absolute;
    height: 62px;
    left: 531px;
    top: 776px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_88{
    position: absolute;
    height: 30px;
    left: 531px;
    top: 841px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_89{
    position: absolute;
    height: 30px;
    left: 531px;
    top: 873px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }

  .layout8_90{
    position: absolute;
    height: 55px;
    left: 531px;
    top: 905px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_91{
    position: absolute;
    height: 55px;
    left: 561px;
    top: 905px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_92{
    position: absolute;
    height: 29px;
    left: 561px;
    top: 874px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_93{
    position: absolute;
    height: 29px;
    left: 561px;
    top: 841px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_94{
    position: absolute;
    height: 62px;
    left: 561px;
    top: 776px;
    width: 28px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }

  .layout8_95{
    position: absolute;
    height: 62px;
    left: 731px;
    top: 773px;
    width: 36px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_96{
    position: absolute;
    height: 62px;
    left: 731px;
    top: 773px;
    width: 36px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_97{
    position: absolute;
    height: 62px;
    left: 731px;
    top: 838px;
    width: 36px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }

  .layout8_98{
    position: absolute;
    height: 57px;
    left: 731px;
    top: 902px;
    width: 36px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_99{
    position: absolute;
    height: 57px;
    left: 768px;
    top: 902px;
    width: 41px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_100{
    position: absolute;
    height: 62px;
    left: 768px;
    top: 839px;
    width: 43px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_101{
    position: absolute;
    height: 62px;
    left: 768px;
    top: 774px;
    width: 54px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_102{
    position: absolute;
    height: 62px;
    left: 768px;
    top: 774px;
    width: 54px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout8_103{
    position: absolute;
    height: 59px;
    left: 849px;
    top: 778px;
    width: 35px;
    clip-path: polygon(11% 2%, 76%, 71% 113%, -1% 117%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }
  .layout8_104{
    position: absolute;
    height: 59px;
    left: 841px;
    top: 843px;
    width: 35px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }
  .layout8_105{
    position: absolute;
    height: 63px;
    left: 841px;
    top: 840px;
    width: 35px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }
  .layout8_106{
    position: absolute;
    height: 53px;
    left: 834px;
    top: 904px;
    width: 36px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }
  .layout8_107{
    position: absolute;
    height: 53px;
    left: 872px;
    top: 912px;
    width: 36px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }
  .layout8_108{
    position: absolute;
    height: 65px;
    left: 878px;
    top: 848px;
    width: 36px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }

  .layout8_109{
    position: absolute;
    height: 65px;
    left: 885px;
    top: 784px;
    width: 36px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }
  .layout8_110{
    position: absolute;
    height: 65px;
    left: 946px;
    top: 801px;
    width: 29px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }
  .layout8_111{
    position: absolute;
    height: 35px;
    left: 938px;
    top: 866px;
    width: 29px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }
  .layout8_112{
    position: absolute;
    height: 33px;
    left: 935px;
    top: 902px;
    width: 29px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }
  .layout8_113{
    position: absolute;
    height: 38px;
    left: 931px;
    top: 938px;
    width: 29px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
  }
  .layout8_114 {
    position: absolute;
    height: 38px;
    left: 962px;
    top: 944px;
    width: 29px;
    clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(7deg);
    border: 2px;
}
.layout8_115 {
  position: absolute;
  height: 34px;
  left: 966px;
  top: 909px;
  width: 29px;
  clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_116 {
  position: absolute;
  height: 34px;
  left: 970px;
  top: 873px;
  width: 29px;
  clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_117 {
  position: absolute;
  height: 69px;
  left: 976px;
  top: 805px;
  width: 29px;
  clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_118 {
  position: absolute;
  height: 69px;
  left: 1031px;
  top: 819px;
  width: 29px;
  clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_119 {
  position: absolute;
  height: 34px;
  left: 1025px;
  top: 887px;
  width: 29px;
  clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_120 {
  position: absolute;
  height: 34px;
  left: 1020px;
  top: 923px;
  width: 29px;
  clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_121 {
  position: absolute;
  height: 34px;
  left: 1016px;
  top: 959px;
  width: 29px;
  clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_122 {
  position: absolute;
  height: 33px;
  left: 1047px;
  top: 967px;
  width: 29px;
  clip-path: polygon(1% 1%, 98% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_123 {
  position: absolute;
  height: 33px;
  left: 1050px;
  top: 931px;
  width: 29px;
  clip-path: polygon(1% -5%, 108% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_124 {
  position: absolute;
  height: 33px;
  left: 1055px;
  top: 896px;
  width: 29px;
  clip-path: polygon(1% -5%, 108% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_125 {
  position: absolute;
  height: 68px;
  left: 1061px;
  top: 827px;
  width: 29px;
  clip-path: polygon(1% -5%, 108% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_126 {
  position: absolute;
  height: 57px;
  left: 1118px;
  top: 841px;
  width: 36px;
  clip-path: polygon(1% -5%, 108% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(7deg);
  border: 2px;
}
.layout8_127 {
  position: absolute;
  height: 35px;
  left: 1111px;
  top: 901px;
  width: 36px;
  clip-path: polygon(1% -5%, 108% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(12deg);
  border: 2px;
}
.layout8_128 {
  position: absolute;
  height: 35px;
  left: 1106px;
  top: 940px;
  width: 36px;
  clip-path: polygon(1% -5%, 108% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(12deg);
  border: 2px;
}
.layout8_129 {
  position: absolute;
  height: 35px;
  left: 1102px;
  top: 979px;
  width: 36px;
  clip-path: polygon(1% -5%, 108% 0, 99% 100%, 0% 93%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(12deg);
  border: 2px;
}
.layout8_130 {
  position: absolute;
  height: 66px;
  left: 1142px;
  top: 957px;
  width: 36px;
  clip-path: polygon(0% 1%, 122% 33%, 78% 98%, 0% 94%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(8deg);
  border: 2px;
}
.layout8_131 {
  position: absolute;
  height: 38px;
  left: 1135px;
  top: 923px;
  width: 59px;
  clip-path: polygon(3% 0%, 99% 0%, 100% 100%, 33% 98%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(35deg);
  border: 2px;
}
.layout8_132 {
  position: absolute;
  height: 62px;
  left: 1149px;
  top: 848px;
  width: 32px;
  clip-path: polygon(3% 0%, 99% 0%, 100% 100%, 33% 98%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(24deg);
  border: 2px;
}
.layout8_133 {
  position: absolute;
  height: 33px;
  left: 1186px;
  top: 859px;
  width: 37px;
  clip-path: polygon(3% 0%, 99% 0%, 100% 100%, 9% 98%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(33deg);
  border: 2px;
}