.layout7 {
    width: 1366px;
    height: 1366px;
  }
  
  .layout7_0 {
    position: absolute;
    height: 43px;
    left: 1180px;
    top: 193px;
    width: 79px;
    /* clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    /* transform: rotateY(0deg) rotate(34deg); */
    border: 2px;
  }
  .layout7_1 {
    position: absolute;
    height: 36px;
    left: 1180px;
    top: 237px;
    width: 84px;
    clip-path: polygon(0% 0%, 95% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  .layout7_2 {
    position: absolute;
    height: 38px;
    left: 1180px;
    top: 274px;
    width: 87px;
    clip-path: polygon(0% 0%, 95% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  .layout7_3 {
    position: absolute;
    height: 38px;
    left: 1180px;
    top: 312px;
    width: 90px;
    clip-path: polygon(0% 0%, 95% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  .layout7_4 {
    position: absolute;
    height: 38px;
    left: 1180px;
    top: 350px;
    width: 93px;
    clip-path: polygon(0% 0%, 95% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  .layout7_5 {
    position: absolute;
    height: 37px;
    left: 1180px;
    top: 387px;
    width: 96px;
    clip-path: polygon(0% 0%, 95% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  .layout7_6 {
    position: absolute;
    height: 38px;
    left: 1180px;
    top: 424px;
    width: 99px;
    clip-path: polygon(0% 0%, 95% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  
  .layout7_7 {
    position: absolute;
    height: 38px;
    left: 1180px;
    top: 462px;
    width: 108px;
    clip-path: polygon(0% 0%, 95% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  
  .layout7_8 {
    position: absolute;
    height: 36px;
    left: 1180px;
    top: 500px;
    width: 110px;
    /* clip-path: polygon(0% 0%, 95% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  .layout7_9 {
    position: absolute;
    height: 37px;
    left: 1180px;
    top: 537px;
    width: 110px;
    /* clip-path: polygon(0% 0%, 95% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
   
  .layout7_10 {
    position: absolute;
    height: 37px;
    left: 1180px;
    top: 575px;
    width: 110px;
    /* clip-path: polygon(0% 0%, 95% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }

  .layout7_11 {
    position: absolute;
    height: 37px;
    left: 1180px;
    top: 612px;
    width: 114px;
    clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  .layout7_12 {
    position: absolute;
    height: 55px;
    left: 1180px;
    top: 650px;
    width: 116px;
    clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  .layout7_13 {
    position: absolute;
    height: 73px;
    left: 1239px;
    top: 740px;
    width: 63px;
    clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  .layout7_14 {
    position: absolute;
    height: 73px;
    left: 1179px;
    top: 740px;
    width: 62px;
    clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
  .layout7_15 {
    position: absolute;
    height: 73px;
    left: 1179px;
    top: 740px;
    width: 62px;
    clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
  }
    .layout7_16 {
      position: absolute;
      height: 73px;
      left: 1069px;
      top: 740px;
      width: 75px;
      clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%);
      cursor: pointer;
      text-align: center;
      transform: rotateY(0deg) rotate(0deg);
      border: 2px;
  }
  .layout7_17 {
    position: absolute;
    height: 55px;
    left: 1069px;
    top: 650px;
    width: 75px;
    /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
} 
.layout7_18{
  position: absolute;
  height: 38px;
  left: 1069px;
  top: 611px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_19{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 574px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_20{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 536px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_21{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 498px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_22{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 460px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_23{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 422px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_24{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 384px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_25{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 346px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_26{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 308px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_27{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 270px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_28{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 232px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_29{
  position: absolute;
  height: 38px;
  left: 1068px;
  top: 194px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_30{
  position: absolute;
  height: 41px;
  left: 992px;
  top: 194px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_31{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 235px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_32{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 273px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_33{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 311px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_34{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 349px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_35{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 387px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_36{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 425px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_37{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 463px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_38{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 501px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_39{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 539px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_40{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 577px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_41{
  position: absolute;
  height: 38px;
  left: 993px;
  top: 615px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_42{
  position: absolute;
  height: 52px;
  left: 993px;
  top: 653px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_43{
  position: absolute;
  height: 68px;
  left: 993px;
  top: 741px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_44{
  position: absolute;
  height: 68px;
  left: 882px;
  top: 741px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_45{
  position: absolute;
  height: 57px;
  left: 882px;
  top: 649px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_46{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 612px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_47{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 574px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_48{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 536px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_49{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 498px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_50{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 460px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_51{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 422px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_52{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 384px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_53{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 346px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_54{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 308px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_55{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 270px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_56{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 232px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_57{
  position: absolute;
  height: 38px;
  left: 882px;
  top: 194px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_58{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 195px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_59{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 233px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 


.layout7_60{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 271px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_61{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 309px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_62{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 347px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} .layout7_63{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 385px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_64{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 423px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_65{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 461px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} .layout7_66{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 499px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_67{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 537px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} .layout7_68{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 575px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_69{
  position: absolute;
  height: 40px;
  left: 806px;
  top: 613px;
  width: 75px;
  /* clip-path: polygon(0% 0%, 98% 0, 100% 100%, 0% 100%); */
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_70{
  position: absolute;
  height: 52px;
  left: 806px;
  top: 653px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_71{
  position: absolute;
  height: 55px;
  left: 807px;
  top: 741px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 


.layout7_72{
  position: absolute;
  height: 36px;
  left: 807px;
  top: 797px;
  width: 85px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_73{
  position: absolute;
  height: 38px;
  left: 807px;
  top: 834px;
  width: 88px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_74{
  position: absolute;
  height: 38px;
  left: 807px;
  top: 872px;
  clip-path: polygon(0% 0%, 96% 0, 100% 100%, 0% 100%);
  width: 94px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_75{
  position: absolute;
  height: 38px;
  left: 807px;
  top: 910px;
  clip-path: polygon(0% 0%, 96% 0, 100% 100%, 0% 100%);
  width: 99px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_76{
  position: absolute;
  height: 38px;
  left: 807px;
  top: 947px;
  clip-path: polygon(0% 0%, 96% 0, 100% 100%, 0% 100%);
  width: 103px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_77{
  position: absolute;
  height: 38px;
  left: 807px;
  top: 984px;
  clip-path: polygon(0% 0%, 96% 0, 100% 100%, 0% 100%);
  width: 108px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_78{
  position: absolute;
  height: 38px;
  left: 807px;
  top: 1022px;
  clip-path: polygon(0% 0%, 96% 0, 100% 100%, 0% 100%);
  width: 114px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_79{
  position: absolute;
  height: 38px;
  left: 807px;
  top: 1060px;
  clip-path: polygon(0% 0%, 96% 0, 100% 100%, 0% 100%);
  width: 121px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}

.layout7_80{
  position: absolute;
  height: 38px;
  left: 807px;
  top: 1097px;
  clip-path: polygon(0% 0%, 96% 0, 100% 100%, 0% 100%);
  width: 126px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_81{
  position: absolute;
  height: 38px;
  left: 807px;
  top: 1134px;
  clip-path: polygon(0% 0%, 96% 0, 100% 100%, 0% 100%);
  width: 132px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_82{
  position: absolute;
  height: 110px;
  left: 883px;
  top: 1173px;
  clip-path: polygon(1% 0%, 100% 0, 100% 100%, 0% 72%);
  width: 56px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_83{
  position: absolute;
  height: 79px;
  left: 844px;
  top: 1173px;
  clip-path: polygon(1% 0%, 100% 0, 100% 100%, 0% 86%);
  width: 38px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_84{
  position: absolute;
  height: 69px;
  left: 806px;
  top: 1172px;
  clip-path: polygon(1% 0%, 100% 0, 100% 100%, 0% 86%);
  width: 38px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_85{
  position: absolute;
  height: 60px;
  left: 697px;
  top: 1172px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_86{
  position: absolute;
  height: 38px;
  left: 697px;
  top: 1134px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_87{
  position: absolute;
  height: 38px;
  left: 697px;
  top: 1096px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_88{
  position: absolute;
  height: 38px;
  left: 697px;
  top: 1058px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_89{
  position: absolute;
  height: 38px;
  left: 697px;
  top: 1020px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_90{
  position: absolute;
  height: 38px;
  left: 697px;
  top: 982px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_91{
  position: absolute;
  height: 38px;
  left: 697px;
  top: 944px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_92{
  position: absolute;
  height: 38px;
  left: 697px;
  top: 906px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_93{
  position: absolute;
  height: 38px;
  left: 697px;
  top: 868px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} .layout7_94{
  position: absolute;
  height: 38px;
  left: 697px;
  top: 830px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_95{
  position: absolute;
  height: 38px;
  left: 697px;
  top: 792px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_96{
  position: absolute;
  height: 52px;
  left: 697px;
  top: 740px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_97{
  position: absolute;
  height: 55px;
  left: 697px;
  top: 650px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_98{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 610px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_99{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 574px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_100{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 535px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_101{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 497px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_102{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 459px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_103{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 421px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_104{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 383px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_105{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 345px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_106{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 307px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_107{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 269px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_108{
  position: absolute;
  height: 39px;
  left: 697px;
  top: 231px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_109{
  position: absolute;
  height: 38px;
  left: 696px;
  top: 194px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_110{
  position: absolute;
  height: 41px;
  left: 621px;
  top: 194px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_111{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 235px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_112{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 273px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_113{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 311px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_114{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 349px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_115{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 387px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_116{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 425px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_117{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 463px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_118{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 501px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} .layout7_119{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 539px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_120{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 577px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_121{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 615px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_122{
  position: absolute;
  height: 52px;
  left: 621px;
  top: 653px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_123{
  position: absolute;
  height: 55px;
  left: 621px;
  top: 740px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_124{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 796px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 


.layout7_125{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 834px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 


.layout7_126{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 872px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_127{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 910px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_128{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 948px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_129{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 986px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_130{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 1024px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_131{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 1062px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_132{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 1100px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_133{
  position: absolute;
  height: 38px;
  left: 621px;
  top: 1138px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 

.layout7_134{
  position: absolute;
  height: 56px;
  left: 621px;
  top: 1175px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 


.layout7_135{
  position: absolute;
  height: 54px;
  left: 510px;
  top: 1173px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 87%);
} 
.layout7_136{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 1135px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_137{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 1097px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_138{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 1059px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_139{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 1021px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_140{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 983px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_141{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 945px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_142{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 907px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_143{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 869px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_144{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 831px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_145{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 793px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_146{
  position: absolute;
  height: 52px;
  left: 510px;
  top: 741px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 
.layout7_147{
  position: absolute;
  height: 55px;
  left: 510px;
  top: 650px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 


.layout7_148{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 612px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
} 


.layout7_149{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 574px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}
.layout7_150{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 536px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}
.layout7_151{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 498px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}
.layout7_152{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 460px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}
.layout7_153{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 422px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}
.layout7_154{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 384px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}
.layout7_155{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 346px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}
.layout7_156{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 308px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}.layout7_157{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 270px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}

.layout7_158{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 232px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}
.layout7_159{
  position: absolute;
  height: 38px;
  left: 510px;
  top: 194px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}.layout7_160{
  position: absolute;
  height: 41px;
  left: 419px;
  top: 194px;
  width: 90px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(21% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_161{
  position: absolute;
  height: 38px;
  left: 419px;
  top: 236px;
  width: 90px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
}
.layout7_162{
  position: absolute;
  height: 38px;
  left: 419px;
  top: 273px;
  width: 90px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 2% 100%);
}

.layout7_163{
  position: absolute;
  height: 38px;
  left: 420px;
  top: 312px;
  width: 89px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%);
}
.layout7_164{
  position: absolute;
  height: 38px;
  left: 421px;
  top: 349px;
  width: 88px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%);
}
.layout7_165{
  position: absolute;
  height: 38px;
  left: 421px;
  top: 386px;
  width: 88px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%);
}

.layout7_166{
  position: absolute;
  height: 38px;
  left: 421px;
  top: 423px;
  width: 88px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%);
}


.layout7_167{
  position: absolute;
  height: 38px;
  left: 421px;
  top: 461px;
  width: 88px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%);
}

.layout7_168{
  position: absolute;
    height: 38px;
    left: 423px;
    top: 499px;
    width: 86px;
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
    clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%);

}

.layout7_169{
  position: absolute;
  height: 38px;
  left: 426px;
  top: 537px;
  width: 83px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%);
}
.layout7_170{
  position: absolute;
  height: 38px;
  left: 426px;
  top: 574px;
  width: 83px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%);
}
.layout7_171{
  position: absolute;
  height: 38px;
  left: 426px;
  top: 612px;
  width: 83px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%);
}
.layout7_172{
  position: absolute;
  height: 55px;
  left: 434px;
  top: 651px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  /* clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%); */
}

.layout7_173{
  position: absolute;
  height: 55px;
  left: 434px;
  top: 741px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  /* clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%); */
}
.layout7_174{
  position: absolute;
  height: 37px;
  left: 434px;
  top: 796px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  /* clip-path: polygon(0% 0%, 100% 0, 100% 100%, 3% 100%); */
}

.layout7_175{
  position: absolute;
  height: 37px;
  left: 434px;
  top: 834px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;

}

.layout7_176{
  position: absolute;
  height: 38px;
  left: 434px;
  top: 872px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;

}
.layout7_177{
  position: absolute;
  height: 38px;
  left: 434px;
  top: 910px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;

}
.layout7_178{
  position: absolute;
  height: 38px;
  left: 434px;
  top: 948px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;

}
.layout7_179{
  position: absolute;
  height: 38px;
  left: 434px;
  top: 986px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;

}

.layout7_180{
  position: absolute;
  height: 38px;
  left: 434px;
  top: 1024px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;

}
.layout7_181{
  position: absolute;
  height: 38px;
  left: 434px;
  top: 1062px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;

}
.layout7_182{
  position: absolute;
  height: 38px;
  left: 434px;
  top: 1100px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;

}
.layout7_183{
  position: absolute;
  height: 38px;
  left: 434px;
  top: 1138px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;

}

.layout7_184{
  position: absolute;
  height: 46px;
  left: 434px;
  top: 1176px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 86%);

}

.layout7_185{
  position: absolute;
  height: 49px;
  left: 323px;
  top: 1169px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 94%, 0% 100%);
}
.layout7_186{
  position: absolute;
  height: 38px;
  left: 323px;
  top: 1135px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_187{
  position: absolute;
  height: 38px;
  left: 323px;
  top: 1097px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}

.layout7_188{
  position: absolute;
  height: 38px;
  left: 323px;
  top: 1059px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_189{
  position: absolute;
  height: 38px;
  left: 323px;
  top: 1021px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}

.layout7_190{
  position: absolute;
  height: 38px;
  left: 323px;
  top: 983px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_191{
  position: absolute;
  height: 38px;
  left: 323px;
  top: 945px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_192{
  position: absolute;
  height: 38px;
  left: 323px;
  top: 907px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_193{
  position: absolute;
  height: 38px;
  left: 323px;
  top: 869px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_194{
  position: absolute;
  height: 38px;
  left: 323px;
  top: 831px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_195{
  position: absolute;
  height: 38px;
  left: 323px;
  top: 793px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_196{
  position: absolute;
  height: 53px;
  left: 323px;
  top: 741px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_197{
  position: absolute;
  height: 55px;
  left: 247px;
  top: 741px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_198{
  position: absolute;
  height: 38px;
  left: 248px;
  top: 796px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_199{
  position: absolute;
  height: 38px;
  left: 248px;
  top: 834px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}

.layout7_200{
  position: absolute;
  height: 38px;
  left: 248px;
  top: 872px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_201{
  position: absolute;
  height: 38px;
  left: 248px;
  top: 910px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_202{
  position: absolute;
  height: 38px;
  left: 248px;
  top: 948px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_203{
  position: absolute;
  height: 38px;
  left: 248px;
  top: 986px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_204{
  position: absolute;
  height: 38px;
  left: 248px;
  top: 1024px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_205{
  position: absolute;
  height: 38px;
  left: 248px;
  top: 1062px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_206{
  position: absolute;
  height: 38px;
  left: 248px;
  top: 1100px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_207{
  position: absolute;
  height: 38px;
  left: 248px;
  top: 1138px;
  width: 75px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_208{
    position: absolute;
    height: 46px;
    left: 248px;
    top: 1175px;
    width: 75px;
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(0deg);
    border: 2px;
    clip-path: polygon(0% 0%, 100% 0, 100% 93%, 0% 101%);
}

.layout7_209{
  position: absolute;
  height: 70px;
  left: 123px;
  top: 764px;
  width: 90px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 58%, 100% 0, 100% 100%, 3% 100%);
}

.layout7_210{
  position: absolute;
  height: 38px;
  left: 126px;
  top: 833px;
  width: 87px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}
.layout7_211{
  position: absolute;
  height: 38px;
  left: 126px;
  top: 871px;
  width: 87px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(-2% 0%, 100% 0, 100% 100%, 4% 100%);
}
.layout7_212{
  position: absolute;
  height: 38px;
  left: 129px;
  top: 909px;
  width: 85px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(-2% 0%, 100% 0, 100% 100%, 4% 100%);
}
.layout7_213{
  position: absolute;
  height: 38px;
  left: 132px;
  top: 947px;
  width: 81px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(-2% 0%, 100% 0, 100% 100%, 4% 100%);
}
.layout7_214{
  position: absolute;
  height: 38px;
  left: 135px;
  top: 985px;
  width: 78px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(-2% 0%, 100% 0, 100% 100%, 4% 100%);
}
.layout7_215{
  position: absolute;
  height: 38px;
  left: 136px;
  top: 1021px;
  width: 76px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(-2% 0%, 100% 0, 100% 100%, 4% 100%);
}
.layout7_216{
  position: absolute;
  height: 38px;
  left: 139px;
  top: 1059px;
  width: 74px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(-2% 0%, 100% 0, 100% 100%, 4% 100%);
}

.layout7_217{
  position: absolute;
  height: 38px;
  left: 142px;
  top: 1096px;
  width: 71px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(-2% 0%, 100% 0, 100% 100%, 4% 100%);

}
.layout7_218{
  position: absolute;
  height: 38px;
  left: 144px;
  top: 1134px;
  width: 68px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(-2% 0%, 100% 0, 100% 100%, 4% 100%);
}

.layout7_219{
  position: absolute;
  height: 53px;
  left: 144px;
  top: 1172px;
  width: 68px;
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(0deg);
  border: 2px;
  clip-path: polygon(-2% 0%, 100% 0, 100% 100%, 4% 100%);
}