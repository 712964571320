#container {
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    /* height: 1000px; */
    /* margin-left: 50px; */
    /* margin-right: 50px; */
}

.layout12 {
    /* display: inline-block; */
    /* Styling */
    /*  */
    border: 1px solid white;
    width: 1600px;
    height: 1500px;
}

.cen {
    text-align: center;
}

.center1 {
    position: relative;
    text-align: center;
}

.layout12_0 {
    position: absolute;
    top: 753px;
    left: 266px;
    width: 65px;
    height: 82px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_1 {
    position: absolute;
    top: 836px;
    left: 266px;
    width: 65px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_2 {
    position: absolute;
    top: 890px;
    left: 266px;
    width: 65px;
    height: 55px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_3 {
    position: absolute;
    top: 946px;
    left: 266px;
    width: 65px;
    height: 55px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_4 {
    position: absolute;
    top: 1002px;
    left: 266px;
    width: 65px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_5 {
    position: absolute;
    top: 1056px;
    left: 266px;
    width: 65px;
    height: 55px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_6 {
    position: absolute;
    top: 1112px;
    left: 266px;
    width: 65px;
    height: 60px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_7 {
    position: absolute;
    top: 1057px;
    left: 369px;
    width: 55px;
    height: 104px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 76%, 0% 100%);
}
.layout12_8 {
    position: absolute;
    top: 1002px;
    left: 369px;
    width: 55px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_9 {
    position: absolute;
    top: 947px;
    left: 369px;
    width: 55px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_10 {
    position: absolute;
    top: 891px;
    left: 369px;
    width: 55px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_11 {
    position: absolute;
    top: 836px;
    left: 369px;
    width: 55px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_12 {
    position: absolute;
    top: 752px;
    left: 369px;
    width: 55px;
    height: 83px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_13 {
    position: absolute;
    top: 596px;
    left: 369px;
    width: 55px;
    height: 83px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_14 {
    position: absolute;
    top: 542px;
    left: 369px;
    width: 55px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_15 {
    position: absolute;
    top: 487px;
    left: 369px;
    width: 55px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_16 {
    position: absolute;
    top: 432px;
    left: 369px;
    width: 55px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_17 {
    position: absolute;
    top: 375px;
    left: 369px;
    width: 55px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_18 {
    position: absolute;
    top: 319px;
    left: 369px;
    width: 55px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_19 {
    position: absolute;
    top: 319px;
    left: 426px;
    width: 61px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_20 {
    position: absolute;
    top: 374px;
    left: 426px;
    width: 61px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_21 {
    position: absolute;
    top: 429px;
    left: 426px;
    width: 61px;
    height: 56px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_22 {
    position: absolute;
    top: 486px;
    left: 426px;
    width: 61px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_23 {
    position: absolute;
    top: 541px;
    left: 426px;
    width: 61px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_24 {
    position: absolute;
    top: 596px;
    left: 426px;
    width: 61px;
    height: 82px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_25 {
    position: absolute;
    top: 752px;
    left: 425px;
    width: 63px;
    height: 82px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_26 {
    position: absolute;
    top: 836px;
    left: 425px;
    width: 63px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_27 {
    position: absolute;
    top: 891px;
    left: 425px;
    width: 63px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout12_28 {
    position: absolute;
    top: 946px;
    left: 425px;
    width: 63px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}

.layout12_29 {
    position: absolute;
    top: 1001px;
    left: 425px;
    width: 63px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 100%, 0% 100%);
}
.layout12_30 {
    position: absolute;
    top: 1056px;
    left: 425px;
    width: 63px;
    height: 80px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 64%, 0% 100%);
}
.layout12_31 {
    position: absolute;
    top: 1002px;
    left: 527px;
    width: 63px;
    height: 89px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 70%, 0% 100%);
}
.layout12_32 {
    position: absolute;
    top: 947px;
    left: 527px;
    width: 63px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 103%, 0% 100%);
}
.layout12_33 {
    position: absolute;
    top: 891px;
    left: 527px;
    width: 63px;
    height: 55px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 103%, 0% 100%);
}
.layout12_34 {
    position: absolute;
    top: 836px;
    left: 527px;
    width: 63px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 103%, 0% 100%);
}
.layout12_35 {
    position: absolute;
    top: 752px;
    left: 527px;
    width: 63px;
    height: 83px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 103%, 0% 100%);
}
.layout12_36 {
    position: absolute;
    top: 597px;
    left: 527px;
    width: 63px;
    height: 83px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 103%, 0% 100%);
}
.layout12_37 {
    position: absolute;
    top: 541px;
    left: 527px;
    width: 63px;
    height: 55px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 103%, 0% 100%);
}
.layout12_38 {
    position: absolute;
    top: 486px;
    left: 527px;
    width: 63px;
    height: 55px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 103%, 0% 100%);
}
.layout12_39 {
    position: absolute;
    top: 430px;
    left: 527px;
    width: 63px;
    height: 55px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 103%, 0% 100%);
}
.layout12_40 {
    position: absolute;
    top: 375px;
    left: 527px;
    width: 63px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 88% 0, 100% 103%, 0% 100%);
}
.layout12_41 {
    position: absolute;
    top: 315px;
    left: 527px;
    width: 55px;
    height: 59px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 91% 0, 100% 103%, 0% 100%);
}
.layout12_42 {
    position: absolute;
    top: 315px;
    left: 642px;
    width: 64px;
    height: 59px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 98% 0, 99% 103%, 8% 98%);
}
.layout12_43 {
    position: absolute;
    top: 374px;
    left: 646px;
    width: 60px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 98% 0, 99% 103%, 8% 98%);
}
.layout12_44 {
    position: absolute;
    top: 429px;
    left: 652px;
    width: 54px;
    height: 56px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 99% 103%, 2% 98%);
}
.layout12_45 {
    position: absolute;
    top: 486px;
    left: 654px;
    width: 51px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 99% 103%, 0% 98%);
}
.layout12_46 {
    position: absolute;
    top: 486px;
    left: 654px;
    width: 51px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 99% 103%, 0% 98%);
}
.layout12_47 {
    position: absolute;
    top: 542px;
    left: 654px;
    width: 51px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 99% 103%, 0% 98%);
}
.layout12_48 {
    position: absolute;
    top: 597px;
    left: 654px;
    width: 51px;
    height: 85px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_49 {
    position: absolute;
    top: 597px;
    left: 706px;
    width: 64px;
    height: 85px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_50 {
    position: absolute;
    top: 597px;
    left: 706px;
    width: 64px;
    height: 85px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_51 {
    position: absolute;
    top: 542px;
    left: 706px;
    width: 64px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_52 {
    position: absolute;
    top: 487px;
    left: 706px;
    width: 64px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_53 {
    position: absolute;
    top: 431px;
    left: 706px;
    width: 64px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_54 {
    position: absolute;
    top: 375px;
    left: 706px;
    width: 64px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_55 {
    position: absolute;
    top: 315px;
    left: 706px;
    width: 64px;
    height: 59px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_56 {
    position: absolute;
    top: 315px;
    left: 809px;
    width: 55px;
    height: 59px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_57 {
    position: absolute;
    top: 376px;
    left: 809px;
    width: 55px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_58 {
    position: absolute;
    top: 430px;
    left: 809px;
    width: 55px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_59 {
    position: absolute;
    top: 485px;
    left: 809px;
    width: 55px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_60 {
    position: absolute;
    top: 540px;
    left: 809px;
    width: 55px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_61 {
    position: absolute;
    top: 595px;
    left: 809px;
    width: 55px;
    height: 85px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_62 {
    position: absolute;
    top: 595px;
    left: 865px;
    width: 63px;
    height: 85px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_63 {
    position: absolute;
    top: 541px;
    left: 865px;
    width: 63px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_64 {
    position: absolute;
    top: 485px;
    left: 865px;
    width: 63px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_65 {
    position: absolute;
    top: 431px;
    left: 865px;
    width: 63px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_66 {
    position: absolute;
    top: 376px;
    left: 865px;
    width: 63px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_67 {
    position: absolute;
    top: 315px;
    left: 865px;
    width: 63px;
    height: 60px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_68 {
    position: absolute;
    top: 315px;
    left: 967px;
    width: 56px;
    height: 60px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_69 {
    position: absolute;
    top: 376px;
    left: 967px;
    width: 56px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_70 {
    position: absolute;
    top: 430px;
    left: 967px;
    width: 56px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_71 {
    position: absolute;
    top: 485px;
    left: 967px;
    width: 56px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_72 {
    position: absolute;
    top: 541px;
    left: 967px;
    width: 56px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_73 {
    position: absolute;
    top: 597px;
    left: 967px;
    width: 56px;
    height: 82px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_74 {
    position: absolute;
    top: 597px;
    left: 1025px;
    width: 62px;
    height: 82px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_75 {
    position: absolute;
    top: 541px;
    left: 1025px;
    width: 62px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_76 {
    position: absolute;
    top: 487px;
    left: 1025px;
    width: 62px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_77 {
    position: absolute;
    top: 431px;
    left: 1025px;
    width: 62px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_78 {
    position: absolute;
    top: 376px;
    left: 1025px;
    width: 62px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_79 {
    position: absolute;
    top: 316px;
    left: 1025px;
    width: 62px;
    height: 58px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_80 {
    position: absolute;
    top: 316px;
    left: 1126px;
    width: 56px;
    height: 58px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_81 {
    position: absolute;
    top: 376px;
    left: 1126px;
    width: 56px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_82 {
    position: absolute;
    top: 429px;
    left: 1126px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_83 {
    position: absolute;
    top: 486px;
    left: 1126px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_84 {
    position: absolute;
    top: 541px;
    left: 1126px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_85 {
    position: absolute;
    top: 597px;
    left: 1126px;
    width: 56px;
    height: 83px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_86 {
    position: absolute;
    top: 598px;
    left: 1184px;
    width: 62px;
    height: 83px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_87 {
    position: absolute;
    top: 542px;
    left: 1184px;
    width: 62px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_88 {
    position: absolute;
    top: 485px;
    left: 1184px;
    width: 62px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_89 {
    position: absolute;
    top: 430px;
    left: 1184px;
    width: 62px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_90 {
    position: absolute;
    top: 376px;
    left: 1184px;
    width: 62px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_91 {
    position: absolute;
    top: 318px;
    left: 1184px;
    width: 62px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_92 {
    position: absolute;
    top: 318px;
    left: 1285px;
    width: 56px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_93 {
    position: absolute;
    top: 376px;
    left: 1285px;
    width: 56px;
    height: 51px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_94 {
    position: absolute;
    top: 430px;
    left: 1285px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_95 {
    position: absolute;
    top: 485px;
    left: 1285px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_96 {
    position: absolute;
    top: 542px;
    left: 1285px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_97 {
    position: absolute;
    top: 597px;
    left: 1285px;
    width: 56px;
    height: 82px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_98 {
    position: absolute;
    top: 541px;
    left: 1343px;
    width: 68px;
    height: 137px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_99 {
    position: absolute;
    top: 431px;
    left: 1343px;
    width: 68px;
    height: 108px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_100 {
    position: absolute;
    top: 320px;
    left: 1343px;
    width: 68px;
    height: 108px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_101 {
    position: absolute;
    top: 755px;
    left: 1343px;
    width: 74px;
    height: 98px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_102 {
    position: absolute;
    top: 855px;
    left: 1343px;
    width: 78px;
    height: 106px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_103 {
    position: absolute;
    top: 755px;
    left: 1285px;
    width: 56px;
    height: 98px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_104 {
    position: absolute;
    top: 855px;
    left: 1285px;
    width: 56px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_105 {
    position: absolute;
    top: 909px;
    left: 1285px;
    width: 56px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 100% 103%, 0% 100%);
}
.layout12_106 {
    position: absolute;
    top: 965px;
    left: 1285px;
    width: 44px;
    height: 113px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 101% 0, 55% 103%, 0% 100%);
}
.layout12_107 {
    position: absolute;
    top: 1002px;
    left: 1184px;
    width: 62px;
    height: 113px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_108 {
    position: absolute;
    top: 947px;
    left: 1184px;
    width: 62px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_109 {
    position: absolute;
    top: 891px;
    left: 1184px;
    width: 62px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_110 {
    position: absolute;
    top: 835px;
    left: 1184px;
    width: 62px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_111 {
    position: absolute;
    top: 753px;
    left: 1184px;
    width: 62px;
    height: 82px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_112 {
    position: absolute;
    top: 753px;
    left: 1126px;
    width: 57px;
    height: 82px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_113 {
    position: absolute;
    top: 836px;
    left: 1126px;
    width: 57px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_114 {
    position: absolute;
    top: 891px;
    left: 1126px;
    width: 57px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_115 {
    position: absolute;
    top: 946px;
    left: 1126px;
    width: 57px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_116 {
    position: absolute;
    top: 1001px;
    left: 1126px;
    width: 57px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_117 {
    position: absolute;
    top: 1058px;
    left: 1126px;
    width: 57px;
    height: 79px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_118 {
    position: absolute;
    top: 1058px;
    left: 1025px;
    width: 62px;
    height: 123px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_119 {
    position: absolute;
    top: 1003px;
    left: 1025px;
    width: 62px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_120 {
    position: absolute;
    top: 947px;
    left: 1025px;
    width: 62px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_121 {
    position: absolute;
    top: 892px;
    left: 1025px;
    width: 62px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_122 {
    position: absolute;
    top: 836px;
    left: 1025px;
    width: 62px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_123 {
    position: absolute;
    top: 754px;
    left: 1025px;
    width: 62px;
    height: 80px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_124 {
    position: absolute;
    top: 754px;
    left: 967px;
    width: 56px;
    height: 80px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_125 {
    position: absolute;
    top: 836px;
    left: 967px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_126 {
    position: absolute;
    top: 891px;
    left: 967px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_127 {
    position: absolute;
    top: 947px;
    left: 967px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_128 {
    position: absolute;
    top: 1002px;
    left: 967px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_129 {
    position: absolute;
    top: 1057px;
    left: 967px;
    width: 56px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_130 {
    position: absolute;
    top: 1113px;
    left: 967px;
    width: 56px;
    height: 88px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_131 {
    position: absolute;
    top: 1168px;
    left: 865px;
    width: 63px;
    height: 79px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_132 {
    position: absolute;
    top: 1113px;
    left: 865px;
    width: 63px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_133 {
    position: absolute;
    top: 1057px;
    left: 865px;
    width: 63px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_134 {
    position: absolute;
    top: 1002px;
    left: 865px;
    width: 63px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_135 {
    position: absolute;
    top: 947px;
    left: 865px;
    width: 63px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_136 {
    position: absolute;
    top: 892px;
    left: 865px;
    width: 63px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_137 {
    position: absolute;
    top: 836px;
    left: 865px;
    width: 63px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_138 {
    position: absolute;
    top: 754px;
    left: 865px;
    width: 63px;
    height: 79px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_139 {
    position: absolute;
    top: 754px;
    left: 808px;
    width: 56px;
    height: 79px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_140 {
    position: absolute;
    top: 837px;
    left: 808px;
    width: 56px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_141 {
    position: absolute;
    top: 892px;
    left: 808px;
    width: 56px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_142 {
    position: absolute;
    top: 948px;
    left: 808px;
    width: 56px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_143 {
    position: absolute;
    top: 1002px;
    left: 808px;
    width: 56px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_144 {
    position: absolute;
    top: 1057px;
    left: 808px;
    width: 56px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_145 {
    position: absolute;
    top: 1114px;
    left: 808px;
    width: 56px;
    height: 52px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_146 {
    position: absolute;
    top: 1168px;
    left: 808px;
    width: 56px;
    height: 98px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_147 {
    position: absolute;
    top: 1013px;
    left: 707px;
    width: 62px;
    height: 62px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_148 {
    position: absolute;
    top: 955px;
    left: 707px;
    width: 62px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_149 {
    position: absolute;
    top: 894px;
    left: 707px;
    width: 62px;
    height: 59px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_150 {
    position: absolute;
    top: 833px;
    left: 707px;
    width: 62px;
    height: 59px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_151 {
    position: absolute;
    top: 754px;
    left: 707px;
    width: 62px;
    height: 77px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_152 {
    position: absolute;
    top: 754px;
    left: 654px;
    width: 52px;
    height: 77px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_153 {
    position: absolute;
    top: 832px;
    left: 654px;
    width: 52px;
    height: 59px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_154 {
    position: absolute;
    top: 893px;
    left: 654px;
    width: 52px;
    height: 59px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}
.layout12_155 {
    position: absolute;
    top: 955px;
    left: 654px;
    width: 52px;
    height: 58px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}

.layout12_156 {
    position: absolute;
    top: 1014px;
    left: 654px;
    width: 52px;
    height: 61px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 100% 103%, 0% 100%);
}