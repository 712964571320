/*------------------------------------------------------------------
Project:	 PERFECTLAYOUT
Version: 	1.0
Assigned to:	Alximicus
Primary use:	PERFECTLAYOUT
-------------------------------------------------------------------*/
/*************************/
/***** BASE STYLES *******/
/*************************/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font: inherit; font-size: 100%; vertical-align: baseline; }

html { line-height: 1; }

ol, ul { list-style: none; }

table { border-collapse: collapse; border-spacing: 0; }

caption, th, td { text-align: left; font-weight: normal; vertical-align: middle; }

q, blockquote { quotes: none; }
q:before, q:after, blockquote:before, blockquote:after { content: ""; content: none; }

a img { border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary { display: block; }

.menuButton {
  font-size: 15px !important;
            text-transform: uppercase !important;
            color: #fff !important;
            border-bottom: 1px solid transparent !important;
            transition: color 0.3s ease-out !important;
            padding-top: 15px !important;
            padding-bottom: 15px !important;
            line-height: 20px !important;
            position: relative !important;
            display: block !important;
            font-weight: bold;
}

/****************/
/**** COLORS ****/
/****************/
/*.blue-theme { $orange: #088cdc;
}*/
body { font-size: 16px; font-family: 'Montserrat', sans-serif; /*padding-bottom: 500px;*/ }

* { outline: none !important; }

/* font-family: 'Playfair Display', serif; font-style: italic;
*/
.vcenter { display: inline-block; vertical-align: middle; float: none; }

.img-responsive { display: inline-block; vertical-align: middle; }

.vtop { display: inline-block; vertical-align: top; float: none; }

.no-margin { margin: 0 !important; }

.row.no-gutter { margin-left: 0; margin-right: 0; }

.row.no-gutter [class*='col-']:not(:first-child), .row.no-gutter [class*='col-']:not(:last-child) { padding-left: 0; padding-right: 0; }

.relative { position: relative; }

.text-white { color: #fff; }

.invertX { -moz-transform: scaleX(-1); -o-transform: scaleX(-1); -webkit-transform: scaleX(-1); transform: scaleX(-1); filter: FlipH; -ms-filter: "FlipH"; }

em { font-family: 'Playfair Display', serif; font-style: italic; color: #999999; font-size: 13px; }

p { font-size: 12px; color: #333; line-height: 2; font-family: 'Raleway', sans-serif; margin: 0 0 2.14em; }
p.large { font-size: 15px; }

h3 { font-size: 24px; font-weight: bold; color: #333333; margin-bottom: 10px; text-transform: uppercase; }

h4 { font-size: 20px; font-weight: bold; margin-bottom: 30px; margin-top: 15px; line-height: 28px; }

h5 { color: #333333; font-size: 14px; text-transform: uppercase; margin-bottom: 25px; font-weight: bold; position: relative; }
h5.italic-title { font-size: 16px; color: #999999; margin-bottom: 10px; font-weight: normal; text-transform: none; font-family: 'Playfair Display', serif; font-style: italic; }
h5.italic-title:after { display: none; }
h5:after { content: ""; display: block; margin-top: 20px; width: 25px; height: 4px; background: #ff6400; }

ul.marker-list { margin-bottom: 15px; }
ul.marker-list li { color: #666666; font-size: 14px; font-family: Raleway, serif; line-height: 24px; padding: 3px 0; font-weight: 500; }
ul.marker-list li:before { content: "\f00c"; font-family: FontAwesome; font-size: 12px; display: inline-block; vertical-align: middle; margin-right: 15px; color: #ff6400; }

a[href^="http://maps.google.com/maps"] { display: none !important; }

a[href^="https://maps.google.com/maps"] { display: none !important; }

.gmnoprint a, .gmnoprint span, .gm-style-cc { display: none; }

.gmnoprint div { background: none !important; }

.btn { -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; font-size: 12px; text-transform: uppercase; padding: 15px 25px; line-height: 20px; }
.btn.btn-primary { background: #ff6400; border: 1px solid #ff6400; color: #fff; }
.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active:focus { background: #cc5000; border-color: #cc5000; }
.btn.btn-primary-warning { border-radius: 0; background: #fff; color: #666; border: 1px solid #eee; }
.btn.btn-primary-warning:hover { background: #ff6400; color: #fff; border-color: #ff6400; }
.btn.btn-info { background: rgba(255, 255, 255, 0.2); border: 1px solid rgba(255, 255, 255, 0.4); color: #fff; }
.btn.btn-info:hover, .btn.btn-info:focus, .btn.btn-info:active:focus { /*color: #7f858f;*/ background: rgba(255, 255, 255, 0.3); border: 1px solid #fff; }

textarea { max-width: 100%; }

.form-control { background: none; box-shadow: none !important; outline: none; border: none; border-bottom: 1px solid #dddddd; border-radius: 0; border-radius: none; font-size: 12px; padding-left: 0; height: 50px; font-size: 11px; color: #999; }
.form-control:focus, .form-control:active { border-color: #dddddd; }

.form-control-feedback { top: 8px; }

.navbar-brand { line-height: 70px; padding: 0; }

.navbar-brand > img { display: inline-block; height: 100px; }

/************************/
/*        LAYOUT        */
/******** HEADER *********/
/*****************/
/***** HEADER ****/
/*****************/
body.fixed-header .scrolling-header .navbar { background: rgba(255, 255, 255, 0.9); padding-top: 15px; padding-bottom:43 px; }
body.fixed-header .scrolling-header .navbar .navbar-nav li a { color: #333; }
body.fixed-header .scrolling-header .navbar .normal-logo { display: none; }
body.fixed-header .scrolling-header .navbar .scroll-logo { display: inline-block; }
body.fixed-header .scrolling-header .navbar .navbar-search .input-group .form-control { height: 80px; color: #7f858f; }
body.fixed-header .scrolling-header .navbar .navbar-search .input-group .search-close { color: #7f858f; }
body.fixed-header .scrolling-header .navbar .navbar-nav li .menuButton { color: #333 !important; }

.header.search-open .navbar .relative-nav-container { opacity: 0; }
.header.search-open .navbar .navbar-search { opacity: 1; visibility: visible; }
.header .navbar { padding-top: 35px; padding-bottom: 35px; margin-bottom: 0; background: none; border: none; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.header .navbar .relative-nav-container { -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.header .navbar .toggle-button { line-height: 40px; color: #999; cursor: pointer; }
.header .navbar .navbar-search { -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; opacity: 0; visibility: hidden; position: absolute; top: 0; left: 0; right: 0; bottom: 0; width: 100%; border-bottom: 1px solid rgba(255, 255, 255, 0.13); }
.header .navbar .navbar-search .input-group .form-control { -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; height: 120px; border: none; color: white; font-size: 30px; font-family: "Raleway", sans-serif; font-weight: normal; }
.header .navbar .navbar-search .input-group .search-close { background: none; border: none; color: #fff; }
.header .navbar .scroll-logo { display: none; }
.header .navbar .navbar-nav > li.active { background: none; border: none; }
.header .navbar .navbar-nav > li.active > a { background: none; border: none; border-bottom: 1px solid #ff6400; }
.header .navbar .navbar-nav > li > a { font-size: 15px; text-transform: uppercase; color:#fff; border-bottom: 1px solid transparent; -webkit-transition: color 0.3s ease-out; -moz-transition: color 0.3s ease-out; -o-transition: color 0.3s ease-out; font-weight:bold; transition: color 0.3s ease-out; }
.header .navbar .navbar-nav > li > a:hover { color: #fff; border-bottom: 1px solid #ff6400; }
.header .navbar .navbar-nav.wrap-user-control { margin-left: 15px; }
.header .navbar .navbar-nav.wrap-user-control li { color: #ff6400; }
.header .navbar .navbar-nav.wrap-user-control li a { color: #ff6400; padding-left: 10px; padding-right: 10px; border: none; }

.navbar-with-inside > li:hover .wrap-inside-nav { display: block; }

body.fixed-header .scrolling-header .navbar .wrap-inside-nav { /*top: 64px;*/ }

.wrap-inside-nav { position: absolute; left: 0; top: 50px; display: none; min-width: 220px; text-align: left; border-top: 2px solid #ff6400; background: #1a1a1a; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.wrap-inside-nav .inside-col { padding: 30px 0; }
.wrap-inside-nav .inside-col .inside-nav:after, .wrap-inside-nav .inside-col .inside-nav:before { display: table; clear: both; height: 0; content: ''; }
.wrap-inside-nav .inside-col .inside-nav li { line-height: 45px; min-height: 45px; }
.wrap-inside-nav .inside-col .inside-nav li a { display: block; padding-left: 20px; color: #7e848e !important; font-size: 14px; text-decoration: none; }
.wrap-inside-nav .inside-col .inside-nav li a:active, .wrap-inside-nav .inside-col .inside-nav li a:focus { display: block; padding-left: 20px; color: #ff6400 !important; }
.wrap-inside-nav .inside-col .inside-nav li a:hover, .wrap-inside-nav .inside-col .inside-nav li a:active:hover, .wrap-inside-nav .inside-col .inside-nav li a:visited:hover, .wrap-inside-nav .inside-col .inside-nav li a:focus:hover { color: #ff6400 !important; background-color: #26282d; }

/******** FOOTER *********/
/******************************/
/**** BUY SECTION ****/
/******************************/
.buy-section { background: #ff6400; padding: 80px 0; }
.buy-section.buy-text-section .section-text { padding-left: 0; }
.buy-section.buy-text-section .buy-text .top-text { margin-bottom: 30px; font-size: 14px; }
.buy-section.buy-text-section .buy-text .bottom-text { font-family: Raleway, sans-serif; font-size: 14px; font-style: normal; max-width: 780px; margin: 0 auto 50px; line-height: 2; color: #ffe6dd; }
.buy-section.buy-text-section .buy-text em { color: #ffe6dd; }
.buy-section .section-text { padding-left: 60px; position: relative; min-height: 46px; }
.buy-section .section-text .like { margin: 0; position: absolute; left: 0; }
.buy-section .section-text .like .icon { font-size: 46px; color: #fff; }
.buy-section .buy-text .top-text { text-transform: uppercase; font-size: 22px; color: #fff; }
.buy-section .buy-text .bottom-text { font-size: 18px; color: #ffe6dd; font-family: 'Playfair Display', serif; font-style: italic; }

/******************************/
/**** SOCIAL SECTION ****/
/******************************/
/* .social-section { padding: 50px 0; background: url(../img/sections/section-2.jpg) center no-repeat; background-size: cover; text-align: center; } */
.social-section.orange-social-section { position: relative; }
.social-section.orange-social-section:after { content: ""; position: absolute; top: 0; left: 0; bottom: 0; right: 0; display: block; background: #ff6400; opacity: 0.95; }
.social-section.orange-social-section .tt { color: #fff; }
.social-section.orange-social-section .list-socials li a { border-color: #fff; color: #fff; }
.social-section .tt { color: #999999; font-size: 16px; font-family: 'Playfair Display', serif; font-style: italic; }
.social-section .list-socials { padding: 15px 0 3px; }
.social-section .list-socials li { display: inline-block; }
.social-section .list-socials li a { display: block; line-height: 48px; text-align: center; width: 48px; height: 48px; -webkit-transition: all 0.5s ease-out; -moz-transition: all 0.5s ease-out; -o-transition: all 0.5s ease-out; transition: all 0.5s ease-out; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; border: 2px solid #5a5a5a; color: #999999; margin: 0 6px 12px; }
.social-section .list-socials li a:hover { -webkit-transition: all 0.1s ease-out; -moz-transition: all 0.1s ease-out; -o-transition: all 0.1s ease-out; transition: all 0.1s ease-out; background: #ff6400; color: #fff; border-color: #ff6400; }

.white-footer { color: #333333; }
.white-footer .footer-section { background: #eff2f7; }
.white-footer .footer-section h5 { color: #333333; }
.white-footer .footer-section p, .white-footer .footer-section .footer-nav li a { color: #666666; }
.white-footer .footer-section .form-control-feedback { color: #ff6400; }
.white-footer .copyright-section { background: #fff; }
.white-footer .copyright-section p { color: #999999; }
.white-footer .copyright-section p span { color: #cccccc; }

/******************************/
/**** FOOTER SECTION ****/
/******************************/
.footer-section { padding: 80px 0 70px; background: #1a1a1a; }
.footer-section h5 { color: #cccccc; }
.footer-section p { color: #999999; font-size: 12px; }
.footer-section .footer-nav li { line-height: 24px; }
.footer-section .footer-nav li.active a { color: #ff6400; }
.footer-section .footer-nav li a { font-size: 12px; line-height: 2; font-family: 'Raleway', sans-serif; color: #999999; text-decoration: none; }
.footer-section .footer-nav li a:hover { color: #ff6400; }
.footer-section .contacts-list i { min-width: 20px; margin-right: 10px; }
.footer-section .contacts-list p { margin-bottom: 0; }
.footer-section .contacts-list a { color: #999; text-decoration: none; }
.footer-section .form-control { border-color: #333333; }
.footer-section .form-control:focus, .footer-section .form-control:active { border-color: #999; }
.footer-section .form-control-feedback { color: #999; }

/******************************/
/**** COPYRIGHT SECTION ****/
/******************************/
.copyright-section { padding: 30px 0; text-align: center; background: #000000; }
.copyright-section p { margin-bottom: 0; font-size: 10px; text-transform: uppercase; color: #999; font-family: 'Montserrat', sans-serif; }
.copyright-section p span { color: #ccc; }

/******** SIDEBAR ********/
.sidebar .search { margin-bottom: 40px; }
.sidebar .search input { color: #2a2d32; }
.sidebar .search .icon { color: #ff6400; }
.sidebar .sidebar-box { padding: 25px; margin-bottom: 40px; border: 1px solid #eeeeee; }
.sidebar .sidebar-box h5 { margin-bottom: 35px; }
.sidebar .category-list li { padding: 10px 0; border-bottom: 1px solid #eee; }
.sidebar .category-list li:last-child { border: none; }
.sidebar .category-list li a { display: block; line-height: 20px; padding: 5px 0; font-size: 13px; color: #666666; }
.sidebar .category-list li a:before { content: "\e660"; font-family: 'Stroke-Gap-Icons'; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; font-size: 30px; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; font-size: 12px; margin-right: 15px; display: inline-block; vertical-align: baseline; color: #ff6400; }
.sidebar .recent-posts .post-item { margin-bottom: 30px; }
.sidebar .recent-posts .post-item .image { margin-bottom: 15px; }
.sidebar .recent-posts .post-item .image img { max-width: 100%; width: 100%; }
.sidebar .recent-posts .post-item h5 { padding-bottom: 0; margin-bottom: 10px; }
.sidebar .recent-posts .post-item h5:after { display: none; }
.sidebar .recent-posts .post-item .meta-item { font-size: 11px; font-weight: 600; text-transform: uppercase; color: #666666; font-family: Raleway, sans-serif; }
.sidebar .recent-posts .post-item .meta-item .icon { font-size: 11px; margin-right: 7px; vertical-align: middle; color: #666666; }
.sidebar .list-social:after { content: ""; clear: both; height: 0; display: table; }
.sidebar .list-social li { float: left; width: 33%; padding: 1.5%; }
.sidebar .list-social li a img { max-width: 100%; }
.sidebar .list-tags { font-size: 0; }
.sidebar .list-tags li { font-size: 12px; display: inline-block; vertical-align: top; margin: 0 6px 6px 0; }
.sidebar .list-tags li a { padding-left: 12px; padding-right: 12px; padding-top: 10px; padding-bottom: 10px; }

/*********  SECTIONS **********/
/*************************/
/**** HEADING SECTION ****/
/*************************/
.homeImage {background: url(../img/homepage.jpg) top center no-repeat; background-size: 100% 100% !important; min-height: 475px;}
.ourServiceImg {background: url(../img/service1.jpeg) top center no-repeat;}
.ourContectImg {background: url(../img/contactus1.jpg) top center no-repeat;}
.top-header { background-size: cover; min-height: 475px; }
/* .top-header.countact-us-header { background: url(../img/sections/section-6.jpg) top center no-repeat; background-size: cover; } */
/* .top-header.portfolio-header { background: url(../img/sections/section-7.jpg) top center no-repeat; background-size: cover; } */
.top-header.home-header { min-height: 500px; }
.top-header .header-container { position: relative; z-index: 2; }
.top-header .header-container .header-title { text-align: center; padding-top: 215px; padding-bottom: 30px; }
.top-header .header-container .header-title .header-icon { display: block; margin: 0 auto 15px; }
.top-header .header-container .header-title .header-icon .icon { font-size: 40px; color: #fff; }
.top-header .header-container .header-title .title { font-size: 38px; margin-bottom: 5px; color: #fff; text-transform: uppercase; }
.top-header .header-container .header-title em { font-size: 16px; color: #999999; }
.top-header .wrap-section-slider { min-height: 500px; max-width: none !important; }
.top-header .wrap-section-slider .sp-mask { height: 500px !important; width: 100% !important; background: #333; }
.top-header .wrap-section-slider .sp-slides-container { min-height: 800px; }
.top-header .wrap-section-slider .sp-layer { position: relative; }
.top-header .wrap-section-slider .slide-item { position: absolute; height: 800px !important; width: 100% !important; }
.top-header .wrap-section-slider .slide-item .slide-image { background: #000; height: 900px; overflow: hidden; }
.top-header .wrap-section-slider .slide-item .slide-image img { opacity: 0.5; max-height: 100%; }
.top-header .wrap-section-slider .slide-item .slide-content { padding-top: 250px; }

/******************************/
/**** FEATURES SECTION ****/
/******************************/
.features-section { padding: 0 0 40px; }
.features-section.about-features-section { padding-top: 70px; }

/******************************/
/**** LAPTOP SECTION ****/
/******************************/
.laptops-section { padding: 30px 0 60px; }
.laptops-section .laptops { margin-bottom: 40px; }

/******************************/
/**** AREAS SECTION ****/
/******************************/
.areas-section { padding: 150px 0 80px; }
.areas-section.service-areas-section { padding-top: 40px; }
.areas-section .above-title { font-size: 18px; color: #666666; text-transform: uppercase; }
.areas-section h4 { color: #333333; margin-top: 10px; font-weight: bold; font-size: 32px; text-transform: uppercase; margin-bottom: 15px; }
.areas-section em { font-size: 16px; display: inline-block; margin-bottom: 15px; }
.areas-section .design-arrow { margin-bottom: 45px; }
.areas-section p.large { font-weight: 500; margin-bottom: 20px; }

/******************************/
/**** ACHIEVEMENTS SECTION ****/
/******************************/
/* .achievements-section { padding: 100px 0 200px; background: url(../img/sections/section-1.jpg) center no-repeat; background-size: cover; } */

/******************************/
/**** LATEST WORKS SECTION ****/
/******************************/
.latest-works-section { padding-bottom: 100px; }
.latest-works-section .scroll-pane { overflow: auto; width: 100%; float: left; }
.latest-works-section .scroll-pane .scroll-content { display: table !important; float: left; margin-bottom: 60px; }
.latest-works-section .scroll-pane .scroll-content-item { display: table-cell; margin: 0; position: relative; cursor: pointer; }
.latest-works-section .scroll-pane .scroll-content-item:hover img { opacity: 0.15; }
.latest-works-section .scroll-pane .scroll-content-item:hover .name { opacity: 1; }
.latest-works-section .scroll-pane .scroll-content-item > a { display: block; position: relative; z-index: 2; background: #000; }
.latest-works-section .scroll-pane .scroll-content-item img { width: auto; height: 350px; vertical-align: middle; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.latest-works-section .scroll-pane .scroll-content-item .name { position: absolute; bottom: 25px; left: 25px; color: #fff; font-size: 12px; text-transform: uppercase; z-index: 3; opacity: 0; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.latest-works-section .scroll-pane .scroll-content-item .name:before { margin-bottom: 15px; content: ""; width: 12px; height: 12px; border: 2px solid #ff6400; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; display: block; }
.latest-works-section .scroll-pane .scroll-bar-wrap { clear: left; margin: 50px auto; }
.latest-works-section .scroll-pane .scroll-bar-wrap .scroll-bar { margin: 0 auto; max-width: 400px; }
.latest-works-section .scroll-pane .scroll-bar-wrap .scroll-bar .ui-handle-helper-parent { width: 100% !important; height: 14px; background: #e6e9ed; border: 1px solid #eeeeee; position: relative; width: 100%; height: 100%; margin: 0 auto; -webkit-border-radius: 14px; -moz-border-radius: 14px; -ms-border-radius: 14px; -o-border-radius: 14px; border-radius: 14px; -webkit-box-shadow: inset 0px 0px 0px 3px white; -moz-box-shadow: inset 0px 0px 0px 3px white; box-shadow: inset 0px 0px 0px 3px white; }
.latest-works-section .scroll-pane .scroll-bar-wrap .scroll-bar .ui-slider-handle { height: 24px; width: 24px !important; margin: -4px 0 -4px -12px !important; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; border: 1px solid #CECECE; background: #fff; display: block; position: relative; cursor: pointer; }

/******************************/
/**** STEPS SECTION ****/
/******************************/
.steps-section { padding: 80px 0 40px 0; background: url(../img/sections/section-4.jpg) top center no-repeat; }
.steps-section .results-container .skills li { background-color: #eff2f7; height: 5px; margin-top: 35px; margin-bottom: 55px; position: relative; }
.steps-section .results-container .skills li.orange .progress { background: #ff6400; }
.steps-section .results-container .skills li > span { color: #333333; font-weight: normal; left: 0; font-size: 11px; text-transform: uppercase; position: absolute; top: -26px; z-index: 1; }
.steps-section .results-container .skills li .progress { background-color: #666666; height: 100%; left: 0; overflow: visible !important; position: absolute; top: 0; -webkit-transition: width 1.2s ease-in-out 0s; -o-transition: width 1.2s ease-in-out 0s; transition: width 1.2s ease-in-out 0s; width: 0; }
.steps-section .results-container .skills li .progress .progress-percent { color: #666; font-size: 11px; height: 20px; line-height: 20px; position: absolute; right: 0; top: -28px; }

/*****************************/
/****** SERVICES SECTION *****/
/*****************************/
.services-section { padding: 30px 0 60px; }
.services-section .services-divider .col-md-4 { margin: 15px 0; }
.services-section .services-divider .col-md-4:after { content: ""; display: block; border-bottom: 1px solid #eff2f7; }
.services-section.service-service-section { background: url(../img/hand.jpg) center no-repeat; background-size: cover; padding: 40px 0 140px; }
.services-section.service-service-section .services-divider .col-md-4::after { border-color: #444444 !important; }
.services-section.service-service-section .service-item:hover .wrap-service-icon .service-icon, .services-section.service-service-section .service-item.active .wrap-service-icon .service-icon { background: #ff6400; color: #fff; }
.services-section.service-service-section .service-item .wrap-service-icon { width: 82px; height: 82px; line-height: 82px; background: none; border: 5px solid #fff; padding: 0; }
.services-section.service-service-section .service-item .wrap-service-icon .service-icon { background: none; width: 72px; height: 72px; border: none; }
.services-section.service-service-section .service-item h5 { color: #fff; }
.services-section.service-service-section .service-item p { color: #999999; }

/*****************************/
/******* VIDEO SECTION *******/
/*****************************/
.video-section { padding: 100px 0 180px; position: relative; }
.video-section .video-play { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
.video-section .title { font-family: Raleway, sans-serif; font-size: 40px; margin-bottom: 10px; color: #fff; font-weight: 100; }
.video-section em { font-size: 16px; }
.video-section .btn-play { display: block; margin: 30px auto 40px; font-size: 72px; color: #ff6400; background: none; border: none; box-shadow: none; width: 73px; height: 73px; background: url(../img/btn-play.png) center no-repeat; background-size: 100%; }
.video-section .duration { color: #dddddd; font-size: 14px; }
.Layout-video-play { align-items: center; justify-content: center; display: flex; flex-direction: column; }

/*****************************/
/******* TEAM SECTION ********/
/*****************************/
.team-section { padding: 0 0 100px 0; }

/*****************************/
/**** TEAM SECTION ****/
/*****************************/
.team-section .team-slider { margin-bottom: -120px; }

/*****************************/
/**** REVIEWS SECTION ****/
/*****************************/
.reviews-section { padding: 40px 0 130px; }
.reviews-section.with-top-effect { padding-top: 140px; padding-bottom: 90px; }

/*****************************/
/**** PRICE SECTION ****/
/*****************************/
.price-section { padding: 100px 0 80px; position: relative; }
/* .price-section .pricing-background { position: absolute; top: 0; width: 100%; height: 520px; background: url(../img/sections/section-5.jpg) center no-repeat; background-size: cover; } */
.price-section .section-heading { margin-bottom: 30px; }
.price-section .section-title { color: #fff; }
.price-section .section-subtitle { color: #fff; }
.price-section .dark-content { position: relative; z-index: 15; }
.price-section .plans-list { font-size: 0; }
.price-section .plans-list .col-md-4 { max-width: 300px; margin-bottom: 15px; }
.price-section .plans-list * { font-size: 14px; }

/*****************************/
/**** LATEST NEWS SECTION ****/
/*****************************/
.latest-news-section { padding: 0 0 50px; }
.latest-news-section.with-top-effect { padding-top: 120px; }

/******************************/
/**** SLIDER SECTION ****/
/******************************/
.slider-section { padding: 90px 0 180px; background: url(../img/area.png) bottom center no-repeat; background-size: cover; }
.slider-section .wrap-section-slider .slide-item { max-width: 870px; margin: 0 auto; padding-left: 15px; padding-right: 15px; }

/******************************/
/**** CLIENTS SECTION ****/
/******************************/
.clients-section { padding-bottom: 120px; }
.clients-section .list-clients { padding: 30px 0 15px; text-align: center; }

/******************************/
/*******  MAP SECTION *********/
/******************************/
.contact-map-section .map { height: 300px; }

/******************************/
/****  CONTACT US SECTION  ****/
/******************************/
.countact-us-section { padding: 30px 0 120px; }
.countact-us-section.contact-us-reverse-section .contact-block-heading em { margin-bottom: 15px; display: block; }
.countact-us-section.contact-us-reverse-section .contact-form h5 { font-size: 16px; line-height: 24px; }
.countact-us-section.contact-us-reverse-section .contact-block { margin-bottom: 30px; }
.countact-us-section.contact-us-reverse-section .contact-block .contacts-list .col-md-12 { padding: 15px; border-bottom: 1px solid #eeeeee; }
.countact-us-section .contact-form { margin-bottom: 15px; }
.countact-us-section .contact-form textarea.form-control { min-height: 155px; resize: none; margin-bottom: 30px; }
.countact-us-section .contact-form .form-heading em { margin-bottom: 15px; display: block; }
.countact-us-section .contact-map { height: 370px; width: 100%; margin-bottom: 30px; }
.countact-us-section .contact-map > div { height: 370px; }
.countact-us-section .contact-block .type-info { color: #2a2d32; font-size: 12px; line-height: 24px; text-transform: uppercase; }
.countact-us-section .contact-block .type-info i { min-width: 20px; font-size: 14px; color: #ff6400; display: inline-block; vertical-align: baseline; }
.countact-us-section .contact-block .info { color: #666666; font-family: Raleway, serif; }
.countact-us-section .socials-list { padding-top: 60px; }
.countact-us-section .socials-list .social-item { line-height: 50px; margin-left: -15px; margin-right: -15px; display: block; color: #fff; text-transform: uppercase; letter-spacing: 1px; font-size: 13px; text-align: center; text-decoration: none; }
.countact-us-section .socials-list .social-item.facebook-item { background: #3c66c4; }
.countact-us-section .socials-list .social-item.facebook-item:hover { background: #30529d; }
.countact-us-section .socials-list .social-item.twitter-item { background: #33ccff; }
.countact-us-section .socials-list .social-item.twitter-item:hover { background: deepskyblue; }
.countact-us-section .socials-list .social-item.google-plus-item { background: #cf4332; }
.countact-us-section .socials-list .social-item.google-plus-item:hover { background: #a73527; }
.countact-us-section .socials-list .social-item.linkedin-item { background: #0086b6; }
.countact-us-section .socials-list .social-item.linkedin-item:hover { background: #006083; }
.countact-us-section .socials-list .social-item.pinterest-item { background: #e02329; }
.countact-us-section .socials-list .social-item.pinterest-item:hover { background: #b61a1f; }
.countact-us-section .socials-list .social-item.behance-item { background: #2a5afc; }
.countact-us-section .socials-list .social-item.behance-item:hover { background: #0339f0; }

/******************************/
/*** PORTFOLIO LIST SECTION ***/
/******************************/
.portfolio-list-section { padding: 0 0 130px 0; }
.portfolio-list-section .list-works { padding-top: 30px; }
.portfolio-list-section .list-works.mini-list-works { margin-bottom: 50px; }
.portfolio-list-section .list-works.mini-list-works .portfolio-item .portfolio-image img { height: 250px; }

.beautiful-ideas-section { padding: 80px 0 0 0; border-top: 1px solid #eff2f7; }
.beautiful-ideas-section .mac-image { margin-bottom: -15px; }

.portfolio-related-projects-section { padding-top: 120px; padding-bottom: 130px; }
.portfolio-related-projects-section .list-works { padding-top: 30px; }

.portfolio-single-section { padding-bottom: 70px; padding-top: 40px; }
.portfolio-single-section .work-heading { position: relative; margin-bottom: 40px; padding-right: 75px; }
.portfolio-single-section .work-heading .category { font-size: 12px; color: #999999; font-family: Raleway, serif; margin-bottom: 5px; }
.portfolio-single-section .work-heading .views .icon { font-size: 16px; color: #666666; margin-right: 5px; vertical-align: middle; display: inline-block; }
.portfolio-single-section .work-heading .views span:not(.icon) { font-size: 12px; color: #999999; vertical-align: middle; display: inline-block; font-family: Raleway, serif; }
.portfolio-single-section .work-heading .controls { position: absolute; right: 0; top: 0; }
.portfolio-single-section .work-heading .controls ul li { display: inline-block; vertical-align: middle; margin: 0 5px; }
.portfolio-single-section .work-heading .controls ul li a { line-height: 24px; }
.portfolio-single-section .work-heading .controls ul li a:hover .fa { color: #ff6400; }
.portfolio-single-section .work-heading .controls ul li a .fa { font-size: 24px; color: #cccccc; line-height: 24px; }
.portfolio-single-section .work-heading .controls ul li a .fa.fa-th { font-size: 16px; line-height: 24px; }
.portfolio-single-section .work-image { margin-bottom: 60px; }
.portfolio-single-section .work-image .image { text-align: center; position: relative; }
.portfolio-single-section .work-image .image img { max-width: 100%; }
.portfolio-single-section .work-image .image .controls { position: absolute; bottom: 30px; right: 30px; }
.portfolio-single-section .work-image .image .controls .big-view a { display: block; width: 40px; height: 40px; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; background: #ff6400; color: #fff; text-align: center; line-height: 44px; text-decoration: none; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.portfolio-single-section .work-image .image .controls .big-view a:hover { background: #cc5000; }
.portfolio-single-section .work-image .image .controls .big-view a span { color: #fff; font-size: 16px; }
.portfolio-single-section .work-body { margin-bottom: 15px; }
.portfolio-single-section .work-body .work-body-left, .portfolio-single-section .work-body .work-body-right { margin-bottom: 30px; }
.portfolio-single-section .work-body .summary-list .col-md-12 { padding: 15px; border-bottom: 1px solid #eeeeee; }
.portfolio-single-section .work-body .summary-list .col-md-12:first-child { padding-top: 0; }
.portfolio-single-section .work-body .summary-list .type-info { color: #2a2d32; font-size: 12px; line-height: 24px; font-weight: bold; text-transform: uppercase; }
.portfolio-single-section .work-body .summary-list .type-info i { min-width: 20px; font-size: 14px; color: #666666; display: inline-block; vertical-align: baseline; }
.portfolio-single-section .work-body .summary-list .info { color: #666666; font-family: Raleway, serif; }
.portfolio-single-section .work-body .summary-list .info .list-socials li { display: inline-block; vertical-align: middle; padding: 0 3px; }
.portfolio-single-section .work-body .summary-list .info .list-socials li a { color: #7e848e; }

.about-header { background: url(../img/home1.jpg) center no-repeat; background-size: cover; }

.create-ideas-section { padding-top: 120px; }
.create-ideas-section .idea-image { text-align: center; padding-top: 30px; }
.create-ideas-section .idea-image img { max-width: 100%; margin-bottom: -15px; }

/* .services-header { background: url(../img/sections/section-8.jpg) center no-repeat; background-size: cover; } */

.core-features-section { padding: 0 0 100px; }

.offers-section { padding: 80px 0; }
.offers-section .offers-list { padding-top: 30px; }

.offers-section { border-top: 1px solid #eff2f7; }
.offers-section .col-image img { display: block; max-width: 100%; height: auto; }
.offers-section .text-item { position: relative; margin-bottom: 57px; }
.offers-section .text-item:after { position: absolute; content: ''; top: 3px; right: -10px; width: 10px; height: 10px; -moz-border-radius: 50%; -webkit-border-radius: 50%; border-radius: 50%; background: #ff6400; }
.offers-section .text-item h5 { font-size: 14px; margin-bottom: 10px; }
.offers-section .text-item h5:after { display: none; }
.offers-section .text-item em { margin-bottom: 10px; display: block; }
.offers-section .text-item .dot-line { position: absolute; top: 6px; width: 82px; height: 5px; right: -111px; border-top: 1px solid #e7e8ec; }
.offers-section .text-item .dot-line:after { content: ''; position: absolute; right: 0; top: -3px; width: 5px; height: 5px; -moz-border-radius: 50%; -webkit-border-radius: 50%; border-radius: 50%; background: #e7e8ec; }
.offers-section .text-item .dot-line:before { content: ''; position: absolute; left: 0; top: -3px; width: 5px; height: 5px; -moz-border-radius: 50%; -webkit-border-radius: 50%; border-radius: 50%; background: #e7e8ec; }
.offers-section .text-item.left-item { text-align: right; padding-right: 20px; }
.offers-section .text-item.right-item { padding-left: 20px; }
.offers-section .text-item.right-item:after { left: -10px; right: auto; }
.offers-section .text-item.right-item .dot-line { left: -111px; right: auto; }
.offers-section .text-item.right-item.middle-item .dot-line { width: 18px; left: -39px; }
.offers-section .text-item.top-item { margin-bottom: 55px; }
.offers-section .text-item.top-item:after { top: 21px; }
.offers-section .text-item.top-item .dot-line { top: 25px; }
.offers-section .text-item.middle-item { margin-bottom: 50px; }
.offers-section .text-item.middle-item .dot-line { top: 22px; width: 18px; right: -39px; }
.offers-section .text-item.middle-item:after { top: 17px; }
.offers-section .text-item.bottom-item .dot-line { top: 22px; }
.offers-section .text-item.bottom-item:after { top: 17px; }

.service-browse-section { text-align: center; }
.service-browse-section img { margin-bottom: -40px; }

/* .blog-header { background: url(../img/sections/section-10.jpg) center no-repeat; background-size: cover; } */

.blog-content-section { padding: 100px 0 100px 0; }
.blog-content-section .left-column .wrap-blog-post { margin-bottom: 40px; }
.blog-content-section .left-column .wrap-blog-post .carousel .carousel-control { width: 50px; height: 50px; top: 50%; background: #333; bottom: auto; background: rgba(0, 0, 0, 0.4); margin-top: -25px; }
.blog-content-section .left-column .wrap-blog-post .carousel .carousel-control .fa { line-height: 50px; text-align: center; font-size: 30px; }
.blog-content-section .left-column .wrap-blog-post .carousel .carousel-control.left { left: 0; -moz-border-radius-topright: 3px; -webkit-border-top-right-radius: 3px; border-top-right-radius: 3px; -moz-border-radius-bottomright: 3px; -webkit-border-bottom-right-radius: 3px; border-bottom-right-radius: 3px; }
.blog-content-section .left-column .wrap-blog-post .carousel .carousel-control.right { right: 0; -moz-border-radius-topleft: 3px; -webkit-border-top-left-radius: 3px; border-top-left-radius: 3px; -moz-border-radius-bottomleft: 3px; -webkit-border-bottom-left-radius: 3px; border-bottom-left-radius: 3px; }
.blog-content-section .left-column .wrap-blog-post .wrap-linked-image { position: relative; }
.blog-content-section .left-column .wrap-blog-post .wrap-linked-image a { display: block; text-align: center; position: absolute; top: 50%; margin: -10px auto 0; left: 0; right: 0; font-size: 26px; color: #c7c7c7; font-family: 'Playfair Display', serif; font-style: italic; }
.blog-content-section .left-column .wrap-blog-post .wrap-linked-image a .icon { font-size: 32px; color: #fff; display: inline-block; vertical-align: middle; margin-right: 10px; }
.blog-content-section .left-column .wrap-blog-post .wrap-video { position: relative; }
.blog-content-section .left-column .wrap-blog-post .wrap-video:after { content: ""; background: rgba(0, 0, 0, 0.3); position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: block; }
.blog-content-section .left-column .wrap-blog-post .wrap-video .play-video { top: 50%; left: 50%; margin: -50px 0 0 -50px; cursor: pointer; position: absolute; padding-left: 10px; z-index: 5; }
.blog-content-section .left-column .wrap-blog-post .wrap-video .play-video span { width: 82px; height: 82px; display: block; background: url(../img/btn-play-white.png) center no-repeat; }
.blog-content-section .left-column .wrap-blog-post .wrap-post-description { text-align: left; padding: 20px 0 15px; margin-top: -1px; background: #fff; position: relative; }
.blog-content-section .left-column .wrap-blog-post .wrap-post-description .post-avatar { position: absolute; right: 65px; top: -35px; }
.blog-content-section .left-column .wrap-blog-post .wrap-post-description .post-avatar img { width: 70px; height: 70px; -moz-border-radius: 50%; -webkit-border-radius: 50%; border-radius: 50%; border: 5px solid white; }
.blog-content-section .left-column .wrap-blog-post .wrap-post-description .meta { padding: 15px 0; margin-bottom: 25px; border-bottom: 1px solid #eff2f7; }
.blog-content-section .left-column .wrap-blog-post .wrap-post-description .meta .meta-item { display: inline-block; vertical-align: middle; font-size: 11px; font-weight: 600; text-transform: uppercase; color: #666666; margin-right: 10px; font-family: Raleway, sans-serif; }
.blog-content-section .left-column .wrap-blog-post .wrap-post-description .meta .meta-item .icon { font-size: 11px; margin-right: 7px; vertical-align: middle; color: #666666; }
.blog-content-section .left-column .wrap-blog-post .post-body h2 { font-size: 22px; color: #333333; font-weight: bold; text-transform: uppercase; position: relative; margin-bottom: 20px; }
.blog-content-section .left-column .wrap-blog-post .post-body h2:after { content: ""; display: block; margin-top: 20px; width: 25px; height: 4px; background: #ff6400; }
.blog-content-section .left-column .wrap-blog-post .post-body p { color: #666666; font-size: 12px; font-family: Raleway, sans-serif; }
.blog-content-section .left-column .wrap-blog-post .post-body img { max-width: 100%; margin-bottom: 15px; }
.blog-content-section .left-column .wrap-blog-post .post-body blockquote, .blog-content-section .left-column .wrap-blog-post .post-body .blockquote { border: 1px solid #eeeeee; padding: 50px; position: relative; background: #fff; -webkit-box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08); -moz-box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08); box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08); font-family: 'Playfair Display', serif; font-style: italic; color: #666666; font-size: 16px; line-height: 30px; margin: 0 15px 30px; }
.blog-content-section .left-column .wrap-blog-post .post-body blockquote:before, .blog-content-section .left-column .wrap-blog-post .post-body .blockquote:before { content: ""; width: 40px; height: 34px; display: block; position: absolute; top: -20px; background: url(../img/quote-orange.png) center no-repeat; right: 50px; }
.blog-content-section .left-column .wrap-blog-post .post-body blockquote .author, .blog-content-section .left-column .wrap-blog-post .post-body .blockquote .author { padding-top: 20px; font-size: 13px; color: #999999; text-transform: uppercase; font-style: normal; font-family: Monserat, sans-serif; }

/************************/
/*        MODULES       */
/****** LOADER ******/
#page-preloader { position: fixed; left: 0; top: 0; right: 0; bottom: 0; width: 100%; height: 100%; background: #f1f3f2; z-index: 100500; }

#page-preloader .spinner { display: block; position: absolute; left: 50%; top: 50%; width: 100px; height: 100px; margin-top: -50px; margin-left: -50px; border: 3px solid transparent; border-top-color: #ff6400; border-radius: 50%; z-index: 1001; -webkit-animation: spin 2.5s infinite linear; animation: spin 2.5s infinite linear; }

#page-preloader .spinner:before, #page-preloader .spinner:after { content: ''; position: absolute; border-radius: 50%; }

#page-preloader .spinner:before { top: 5px; left: 5px; right: 5px; bottom: 5px; border: 3px solid transparent; border-top-color: #088cdc; -webkit-animation: spin 2s infinite linear; animation: spin 2s infinite linear; }

#page-preloader .spinner:after { top: 15px; left: 15px; right: 15px; bottom: 15px; border: 3px solid transparent; border-top-color: #52bf81; -webkit-animation: spin 1s infinite linear; animation: spin 1s infinite linear; }

@-webkit-keyframes spin { 0% { -webkit-transform: rotate(0); transform: rotate(0); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } }

@keyframes spin { 0% { -webkit-transform: rotate(0); transform: rotate(0); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } }

/****** PAGINATION ******/
.wrap-pagination .pagination-list li { float: left; text-align: center; margin: 0 2px 10px; }
.wrap-pagination .pagination-list li a, .wrap-pagination .pagination-list li span { width: auto; display: block; width: 42px; height: 42px; line-height: 32px; border: 5px solid #eff2f7; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; text-align: center; font-size: 14px; color: #999999; background: #fff; -webkit-box-shadow: 0 0 0 1px #dcdfe4 inset; -moz-box-shadow: 0 0 0 1px #dcdfe4 inset; box-shadow: 0 0 0 1px #dcdfe4 inset; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; text-decoration: none; }
.wrap-pagination .pagination-list li a i, .wrap-pagination .pagination-list li span i { font-family: FontAwesome; font-size: 12px; }
.wrap-pagination .pagination-list li a:hover { -webkit-box-shadow: 0 0 0 1px #ff6400 inset; -moz-box-shadow: 0 0 0 1px #ff6400 inset; box-shadow: 0 0 0 1px #ff6400 inset; background: #ff6400; color: #fff; }
.wrap-pagination .pagination-list li.active a { -webkit-box-shadow: 0 0 0 1px #ff6400 inset; -moz-box-shadow: 0 0 0 1px #ff6400 inset; box-shadow: 0 0 0 1px #ff6400 inset; background: #ff6400; color: #fff; }

/****** CONTACT FORM ******/
#response .success { color: #02B701; }
#response .error { margin-bottom: 5px; color: #FF2020; }

/****** SECTION ICON ******/
.with-icon { position: relative; }
.with-icon .section-icon { position: absolute; top: -25px; left: 50%; margin-left: -25px; z-index: 11; width: 50px; height: 50px; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; background: #eff2f7; text-align: center; }
.with-icon .section-icon .icon, .with-icon .section-icon .fa { line-height: 50px; font-size: 16px; color: #666666; position: relative; z-index: 3; }
.with-icon .section-icon:after { content: ""; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; position: absolute; top: 4px; left: 4px; right: 4px; bottom: 4px; display: block; background: #fff; border: 1px solid #dcdfe4; }

/****** DARK EFFECT ******/
.dark { position: relative; }
.dark.dark-strong:after { opacity: 0.8; }
.dark:after { content: ""; display: block; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: #000000; opacity: 0.6; z-index: 1; }

.dark-content { position: relative; z-index: 2; }

.with-top-effect { background: url(../img/divider.svg) top center; background-repeat: no-repeat; }
.with-top-effect .section-icon { top: 17px; }

/******  BOTTOM EFFECT  ******/
.with-bottom-effect { position: relative; overflow: hidden; }
.with-bottom-effect.transparent-effect .bottom-effect { position: absolute; z-index: 10; bottom: -50px; width: 2500px; height: 148px; left: -100px; background: rgba(255, 255, 255, 0.6); -webkit-transform: rotate(-3deg); -moz-transform: rotate(-3deg); -ms-transform: rotate(-3deg); -o-transform: rotate(-3deg); transform: rotate(-3deg); padding-top: 10px; }
.with-bottom-effect.transparent-effect .bottom-effect:after { content: ''; display: block; background: rgba(255, 255, 255, 0.9); height: 148px; -webkit-box-shadow: inset 0px -12px 13px -2px white; -moz-box-shadow: inset 0px -12px 13px -2px white; box-shadow: inset 0px -12px 13px -2px white; }
.with-bottom-effect:before { content: ""; -webkit-box-shadow: inset 0px -12px 13px -2px white; -moz-box-shadow: inset 0px -12px 13px -2px white; box-shadow: inset 0px -12px 13px -2px white; display: block; width: 100%; position: absolute; z-index: 11; bottom: 0; height: 30px; }
.with-bottom-effect .bottom-effect { position: absolute;  bottom: -50px; width: 2500px; height: 20px; left: -100px; background: #fff;  padding-top: 10px; }
.with-bottom-effect .bottom-effect:after { content: ''; display: block; background: #fff; height: 148px; }

/******  SECTION SLIDER ******/
.wrap-section-slider { color: #fff; /*overflow: hidden;*/ }
.wrap-section-slider .owl-controls .owl-pagination { position: absolute; top: 100%; width: 100%; z-index: 9; padding: 15px 0; text-align: center; }
.wrap-section-slider .owl-controls .owl-pagination .owl-page { display: inline-block; vertical-align: middle; width: 10px; height: 10px; border: 1px solid #8e9195; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; margin: 0 4px; cursor: pointer; }
.wrap-section-slider .owl-controls .owl-pagination .owl-page.active { background: #ff6400; border-color: #ff6400; }
.wrap-section-slider .owl-wrapper:after { content: ""; clear: both; display: table; height: 0; }
.wrap-section-slider .owl-wrapper:before { content: ""; clear: both; display: table; height: 0; }
.wrap-section-slider .slide-item { width: 100%; text-align: center; position: relative; z-index: 5; }
.wrap-section-slider .slide-item .slider-title { font-size: 12px; text-transform: uppercase; color: #dddddd; font-family: 'Raleway', sans-serif; font-weight: bold; margin-bottom: 40px; }
.wrap-section-slider .slide-item .slider-title i { margin: 0 5px 0 8px; font-size: 24px; color: #ff6400; text-shadow: #393a39 2px 0px, #393a39 2px 2px,  #393a39 0px 2px, #393a39 -2px 2px, #393a39 -2px 0px,  #393a39 -2px -2px, #393a39 0px -2px, #393a39 2px -2px, #393a39 0 0 3px, #393a39 0 0 3px,  #393a39 0 0 3px, #393a39 0 0 3px, #393a39 0 0 3px, #393a39 0 0 3px,  #393a39 0 0 3px,  #393a39 0 0 3px; }
.wrap-section-slider .slide-item p { color: #fff; }
.wrap-section-slider .slide-item .slide-image img { width: auto; min-width: 100%; }
.wrap-section-slider .slide-item .time { color: #999999; font-size: 13px; font-family: 'Playfair Display', serif; font-style: italic; margin-bottom: 30px; display: inline-block; vertical-align: middle; }
.wrap-section-slider .slide-item .slide-content { padding: 0 15px; position: absolute; top: 0; left: 0; right: 0; }
.wrap-section-slider .slide-item .slide-content .top-title { text-transform: uppercase; color: #fff; font-size: 22px; margin-bottom: 10px; font-family: Monserat, sans-serif; }
.wrap-section-slider .slide-item .slide-content .title { text-transform: uppercase; color: #fff; font-size: 44px; font-weight: bold; margin-bottom: 10px; }
.wrap-section-slider .slide-item .slide-content .under-title { font-size: 22px; color: #fff; opacity: 0.5; font-family: 'Playfair Display', serif; font-style: italic; }
.wrap-section-slider .slide-item .slide-content .controls { padding: 30px 0; }
.wrap-section-slider .slide-item .slide-content .controls .btn { margin: 0 5px 15px; }

/******  SECTION TILE ******/
/*********/
/* SECTION TITLE */
/*********/
.section-heading { padding: 30px 0; }

.section-title { font-size: 26px; color: #333333; text-transform: uppercase; font-weight: bold; margin-bottom: 10px; text-align: center; letter-spacing: 1px; }

.section-subtitle { font-family: 'Playfair Display', serif; font-style: italic; font-size: 16px; color: #999999; margin-bottom: 15px; text-align: center; }

.design-arrow { width: 1px; height: 50px; background: #dddddd; position: relative; margin: 10px auto; }
.design-arrow.inline-arrow { display: inline-block; vertical-align: middle; float: none; }
.design-arrow:after { content: ""; display: block; position: absolute; width: 11px; height: 11px; border: 2px solid #ff6400; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; top: 100%; left: -5px; }

/******  CLIENTS ITEM ******/
.client-item { display: inline-block; vertical-align: middle; padding: 0 30px; width: 200px; text-align: center; margin-bottom: 15px; }
.client-item img { max-width: 100%; }

/******  SERVICE ITEM ******/
.service-item { padding: 40px 0 20px; max-width: 300px; margin: 0 auto; }
.service-item:hover .wrap-service-icon { border-color: #ddddde; }
.service-item:hover .wrap-service-icon .service-icon { background: #ff6400; border-color: #ff6400; }
.service-item:hover .wrap-service-icon .service-icon .icon { color: #fff; }
.service-item .wrap-service-icon { width: 78px; height: 78px; margin-right: 5px; background: #eff2f7; border: 1px solid #eff2f7; padding: 5px; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.service-item .wrap-service-icon .service-icon { width: 66px; height: 66px; border: 1px solid #ddddde; background: #ffffff; text-align: center; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.service-item .wrap-service-icon .service-icon .icon { font-size: 26px; line-height: 64px; color: #666666; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.service-item h5 { margin-bottom: 5px; }
.service-item h5:after { display: none; }
.service-item p { margin-bottom: 15px; }
.list-achieve{margin-top: 20px; margin-bottom: 20px;}
/******  PLANS ITEM ******/
.plan-item { background: #fff; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; border: 1px solid #eeeeee; }
.plan-item.active { border: none; border-top: 5px solid #ff6400; position: relative; z-index: 3; margin: -15px; padding-bottom: 15px; -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1); box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1); }
.plan-item.active .item-heading { padding-top: 54px; margin-bottom: 3px; }
.plan-item.active .item-body { border-top: 1px solid #e7e8ec; }
.plan-item.active .item-footer .btn { background: #ff6400; color: #fff; }
.plan-item .item-heading { padding-top: 40px; text-align: center; padding-bottom: 20px; border-bottom: 1px solid #e7e8ec; }
.plan-item .item-heading .name { font-size: 14px; color: #ff6400; text-transform: uppercase; font-weight: normal; margin-bottom: 15px; display: inline-block; }
.plan-item .item-heading .count { font-size: 50px; color: #2a2d32; margin-bottom: 5px; }
.plan-item .item-heading em { color: #cccccc; font-size: 14px; }
.plan-item .item-body .list-features { padding: 35px 30px 20px 55px; text-align: left; }
.plan-item .item-body .list-features li { font-family: Raleway, serif; padding: 11px 0; color: #cccccc; }
.plan-item .item-body .list-features li.active { color: #666666; }
.plan-item .item-body .list-features li.active:before { content: "\f00c"; color: #ff6400; }
.plan-item .item-body .list-features li:before { content: "\f00d"; font-family: FontAwesome; color: #cccccc; font-size: 12px; display: inline-block; margin-right: 10px; }
.plan-item .item-footer { padding: 20px 15px 35px; }
.plan-item .item-footer .btn { border-color: #eeeeee; font-size: 11px; color: #333333; padding-left: 30px; padding-right: 30px; }
.plan-item .item-footer .btn:hover { background: #ff6400; color: #fff; }

/******  NEWS ITEM ******/
.news-item { margin: 0 auto 15px; max-width: 360px; }
.news-item .meta { padding: 15px 0; }
.news-item .meta .meta-item { display: inline-block; vertical-align: middle; font-size: 11px; font-weight: 500; text-transform: uppercase; color: #666666; margin-right: 10px; }
.news-item .meta .meta-item .icon { font-size: 11px; margin-right: 7px; vertical-align: middle; color: #666666; }
.news-item .image { position: relative; }
.news-item .image img { vertical-align: middle; max-width: 100%; }
.news-item .image:hover .image-content { opacity: 1; }
.news-item .image .image-content { position: absolute; top: 0; left: 0; bottom: 0; right: 0; background: rgba(0, 0, 0, 0.8); display: table-cell; vertical-align: bottom; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; opacity: 0; }
.news-item .image .read-more { position: absolute; font-size: 12px; bottom: 35px; left: 30px; display: block; color: #fff; text-transform: uppercase; }
.news-item .image .read-more:before { margin-bottom: 15px; content: ""; width: 12px; height: 12px; display: inline-block; border: 2px solid #ff6400; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; display: block; }
.news-item .user-avatar { position: relative; margin-bottom: 30px; }
.news-item .user-avatar .avatar { float: right; margin-top: -40px; width: 70px; height: 70px; margin-right: 15px; border: 5px solid #fff; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; overflow: hidden; }
.news-item .user-avatar .avatar img { width: 100%; height: 100%; vertical-align: middle; }

/******  FEATURE ITEM ******/
.feature-item { text-align: center; margin: 0 auto 15px; max-width: 300px; }
.feature-item:hover .wrap-feature-icon, .feature-item.active .wrap-feature-icon { background: #ff6400; border-color: #ff6400; }
.feature-item:hover .wrap-feature-icon .feature-icon, .feature-item.active .wrap-feature-icon .feature-icon { background: #ff6400; border-color: #ffb17f; }
.feature-item:hover .wrap-feature-icon .feature-icon .icon, .feature-item.active .wrap-feature-icon .feature-icon .icon { color: #fff; }
.feature-item .wrap-feature-icon { width: 110px; height: 110px; border: 1px solid #eff2f7; background: #fff; padding: 10px; margin: 0 auto 50px; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.feature-item .wrap-feature-icon .feature-icon { height: 88px; width: 88px; border: 1px solid #eff2f7; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; background: #eff2f7; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.feature-item .wrap-feature-icon .feature-icon .icon { line-height: 88px; font-size: 32px; color: #666666; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.feature-item .title { color: #333333; font-size: 14px; font-weight: bold; text-transform: uppercase; }
.feature-item .title:after { content: ''; display: block; margin: 20px auto; height: 4px; width: 25px; background: #ff6400; }
.feature-item .text { font-family: 'Raleway', sans-serif; font-size: 12px; line-height: 26px; margin-bottom: 5px; }

/******  ARCHIVE ITEM ******/
.list-achieve .col-md-3:last-child .achieve-item:after { display: none; }
.list-achieve .col-md-3 .achieve-item { position: relative; }
.list-achieve .col-md-3 .achieve-item:after { content: ''; width: 12px; height: 12px; display: block; border: 2px solid #ff6400; position: absolute; top: 137px; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; right: -20px; }

.achieve-item { text-align: center; margin: 15px auto 15px; max-width: 500px; position: relative; }
.achieve-item .achieve-icon .icon { font-size: 46px;  color: #fff; }
.achieve-item .count {  text-align: center; margin: 20px 0 0  ; font-size: 30px; color: black; text-transform: uppercase; font-weight: bold; }
.achieve-item .name { width: 300px; color: black; font-size: 14px; margin-left: 10px; font-weight: bold }

/******  STEPS ITEM ******/
.steps-list .col-md-4:last-child .step-item:after { display: none; }
.steps-list .step-item { text-align: center; position: relative; margin-bottom: 120px; }
.steps-list .step-item:after { content: ''; display: block; width: 178px; height: 29px; background: url(../img/step-arrow.png) center no-repeat; position: absolute; top: 120%; left: 76%; }
.steps-list .step-item.invert .item-icon { background: #ff6400; }
.steps-list .step-item.invert .item-icon:before { background: #333; }
.steps-list .step-item .item-text { display: inline-block; vertical-align: middle; }
.steps-list .step-item .item-text h5 { margin: 0; border: 0; padding: 0; line-height: 24px; color: #333333; text-align: left; }
.steps-list .step-item .item-text h5:after { display: none; }
.steps-list .step-item .item-icon { position: relative; width: 75px; height: 75px; line-height: 80px; text-align: center; text-indent: 5px; background: #333333; display: inline-block; vertical-align: middle; margin-right: 15px; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; }
.steps-list .step-item .item-icon:before { content: attr(data-count); border: 5px solid #eff2f7; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; position: absolute; top: -5px; left: -5px; display: block; width: 30px; height: 30px; background: #ff6400; line-height: 20px; text-align: center; color: #fff; font-size: 14px; text-indent: 0; }
.steps-list .step-item .item-icon .icon { font-size: 22px; color: #fff; }

/******  REVIEWS SLIDER ******/
.reviews-slider { overflow: hidden; padding-top: 20px; }
.reviews-slider.review-slider-seconds .slide-item { margin-right: 30px; }
.reviews-slider.review-slider-seconds .slide-item .image-block { width: 200px; height: 200px; }
.reviews-slider.review-slider-seconds .slide-item .description-block .review { z-index: -1; padding: 50px 25px 50px 50px; }
.reviews-slider.review-slider-seconds .slide-item .description-block .review em { color: #666666; }
.reviews-slider.review-slider-seconds .owl-controls .owl-pagination { padding-top: 60px; text-align: center; }
.reviews-slider .owl-wrapper:after { content: ""; clear: both; display: table; height: 0; }
.reviews-slider .owl-wrapper:before { content: ""; clear: both; display: table; height: 0; }
.reviews-slider .owl-controls .owl-pagination { padding: 15px 0; text-align: right; }
.reviews-slider .owl-controls .owl-pagination .owl-page { display: inline-block; vertical-align: niddle; width: 10px; height: 10px; border: 1px solid #cfcfcf; background: #fff; margin: 0 5px; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; cursor: pointer; }
.reviews-slider .owl-controls .owl-pagination .owl-page.active, .reviews-slider .owl-controls .owl-pagination .owl-page:hover { background: #666; border-color: #666; }
.reviews-slider .slide-item:after { content: ""; clear: both; display: table; height: 0; }
.reviews-slider .slide-item:before { content: ""; clear: both; display: table; height: 0; }
.reviews-slider .slide-item .media-left { padding: 0; }
.reviews-slider .slide-item .media-body { overflow: visible; }
.reviews-slider .slide-item .image-block { width: 300px; }
.reviews-slider .slide-item .description-block .name { padding: 20px 15px 30px 35px; }
.reviews-slider .slide-item .description-block .name span { font-size: 14px; color: #333333; text-transform: uppercase; display: inline-block; vertical-align: middle; margin-right: 10px; font-weight: bold; }
.reviews-slider .slide-item .description-block .review { border: 1px solid #eeeeee; padding: 50px; margin-left: -30px; position: relative; background: #fff; -webkit-box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08); -moz-box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08); box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08); }
.reviews-slider .slide-item .description-block .review:before { content: ""; width: 40px; height: 34px; display: block; position: absolute; top: -20px; background: url(../img/quotes.png) center no-repeat; right: 50px; }
.reviews-slider .slide-item .description-block .review em { color: #666666; font-size: 16px; line-height: 30px; }

/******  TEAM SLIDER ******/
.wrap-team-slider { padding-left: 35px; padding-right: 35px; position: relative; }
.wrap-team-slider .prev-btn, .wrap-team-slider .next-btn { position: absolute; top: 50%; cursor: pointer; }
.wrap-team-slider .prev-btn span, .wrap-team-slider .next-btn span { display: inline-block; font-size: 32px; color: #cccccc; }
.wrap-team-slider .prev-btn span:hover, .wrap-team-slider .next-btn span:hover { color: #666; }
.wrap-team-slider .prev-btn { left: 0; }
.wrap-team-slider .next-btn { right: 0; }

.team-slider { padding-top: 30px; }
.team-slider .slick-list { text-align: center; }
.team-slider .slick-list .slick-track { display: inline-block; }
.team-slider .slick-list .slick-track .slide-item { position: relative; }
.team-slider .slick-list .slick-track .slide-item.slick-current + .slide-item .slide-description { opacity: 1; }
.team-slider .slick-list .slick-track .slide-item .image { text-align: center; margin-bottom: -80px; height: 800px; overflow: hidden; }
.team-slider .slick-list .slick-track .slide-item .image img { max-width: 100%; display: inline-block; }
.team-slider .slick-list .slick-track .slide-item .slide-description { position: absolute; opacity: 0; right: 0; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; bottom: 0; width: 100%; background: rgba(0, 0, 0, 0.8); color: #dddddd; }
.team-slider .slick-list .slick-track .slide-item .slide-description .member-info { padding: 35px 0 35px; }
.team-slider .slick-list .slick-track .slide-item .slide-description .member-info .name { font-size: 14px; color: #dddddd; text-transform: uppercase; font-weight: bold; margin-bottom: 10px; }
.team-slider .slick-list .slick-track .slide-item .slide-description .member-info .position { font-family: Raleway, serif; color: #dddddd; }
.team-slider .slick-list .slick-track .slide-item .slide-description .contacts { background: #ff6400; padding: 40px 0 120px; }
.team-slider .slick-list .slick-track .slide-item .slide-description .contacts ul li { display: inline-block; vertical-align: middle; margin: 0 5px; color: #fff; }
.team-slider .slick-list .slick-track .slide-item .slide-description .contacts ul li a { color: #fff; }

/******  PORTFOLIO SLIDER ******/
.portfolio-item { margin: 0 0 30px 0; }
.portfolio-item .portfolio-image { position: relative; cursor: pointer; overflow: hidden; }
.portfolio-item .portfolio-image:hover > a::after, .portfolio-item .portfolio-image:hover .portfolio-item-body { opacity: 1; }
.portfolio-item .portfolio-image > a { display: block; position: relative; }
.portfolio-item .portfolio-image > a:after { content: ""; background: rgba(0, 0, 0, 0.8); -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; opacity: 0; position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
.portfolio-item .portfolio-image img { width: auto; height: 350px; vertical-align: middle; }
.portfolio-item .portfolio-image .portfolio-item-body { position: absolute; z-index: 3; bottom: 35px; left: 35px; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; opacity: 0; }
.portfolio-item .portfolio-image .portfolio-item-body.center-body { top: 50%; margin-top: -20px; left: 0; right: 0; text-align: center; }
.portfolio-item .portfolio-image .portfolio-item-body.center-body ul li { display: inline-block; vertical-align: middle; margin: 0 5px; }
.portfolio-item .portfolio-image .portfolio-item-body.center-body ul li a { display: block; width: 40px; height: 40px; border: 1px solid #fff; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; text-align: center; text-decoration: none; color: #fff; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.portfolio-item .portfolio-image .portfolio-item-body.center-body ul li a:hover { background: #ff6400; border-color: #ff6400; }
.portfolio-item .portfolio-image .portfolio-item-body.center-body ul li a span { font-size: 16px; line-height: 40px; }
.portfolio-item .portfolio-image .portfolio-item-body .name { color: #fff; font-size: 12px; text-transform: uppercase; margin-bottom: 5px; }
.portfolio-item .portfolio-image .portfolio-item-body .name:before { margin-bottom: 15px; content: ""; width: 12px; height: 12px; border: 2px solid #ff6400; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; display: block; }
.portfolio-item .portfolio-image .portfolio-item-body .under-name { color: #fff; font-size: 12px; font-family: Raleway, serif; font-weight: normal; }
.portfolio-item .portfolio-item-footer { padding-top: 25px; position: relative; padding-bottom: 25px; border-bottom: 1px solid #eff2f7; }
.portfolio-item .portfolio-item-footer .name { font-size: 14px; color: #333; text-transform: uppercase; margin-bottom: 5px; font-weight: bold; }
.portfolio-item .portfolio-item-footer .under-name { color: #999999; font-size: 12px; font-family: Raleway, serif; }
.portfolio-item .portfolio-item-footer .views { position: absolute; line-height: 20px; top: 50%; margin-top: -10px; right: 0; }
.portfolio-item .portfolio-item-footer .views .icon { font-size: 16px; color: #666666; margin-right: 5px; vertical-align: middle; display: inline-block; }
.portfolio-item .portfolio-item-footer .views span:not(.icon) { font-size: 12px; color: #999999; vertical-align: middle; display: inline-block; font-family: Raleway, serif; }

/******  CONTENT TABS ******/
.content-tabs { text-align: center; border: none; font-size: 0; padding: 30px 0; }
.content-tabs > li { border: none; float: none; display: inline-block; vertical-align: middle; font-size: 14px; margin: 0 3px 15px 11px; }
.content-tabs > li a { border: none; padding: 0 35px; line-height: 45px; background: #eff2f7; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; color: #333; font-size: 11px; text-transform: uppercase; position: relative; margin: 0; }
.content-tabs > li a:after { content: ""; border: 10px solid transparent; top: 100%; border-top-color: #eff2f7; display: block; position: absolute; left: 50%; margin-left: -10px; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.content-tabs > li a:hover { background: #ff6400; color: #fff; }
.content-tabs > li a:hover:after { border-top-color: #ff6400; }
.content-tabs.nav-tabs > li.active > a, .content-tabs.nav-tabs > li.active > a:focus, .content-tabs.nav-tabs > li.active > a:hover { border: none; background: #ff6400; color: #fff; }
.content-tabs.nav-tabs > li.active > a:after, .content-tabs.nav-tabs > li.active > a:focus:after, .content-tabs.nav-tabs > li.active > a:hover:after { border-top-color: #ff6400; }

/******  TIMELINE ******/
.round-ico { width: 40px; height: 40px; background: #fff; border: 5px solid #eff2f7; border-radius: 50%; -webkit-box-shadow: inset 0px 0px 0px 2px #cacddc; -moz-box-shadow: inset 0px 0px 0px 2px #cacddc; box-shadow: inset 0px 0px 0px 2px #cacddc; }
.round-ico.little { width: 25px; height: 25px; background: #fff; border: 5px solid #eeeef2; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -webkit-box-shadow: inset 0px 0px 0px 2px #cacddc; -moz-box-shadow: inset 0px 0px 0px 2px #cacddc; box-shadow: inset 0px 0px 0px 2px #cacddc; }

.wrap-timeline { position: relative; }
.wrap-timeline:after { content: ''; position: absolute; top: 0; display: block; bottom: 110px; left: 50%; width: 2px; margin-left: -1px; background: #eff2f7; }
.wrap-timeline .top-row .time-title { text-align: center; height: 80px; position: relative; }
.wrap-timeline .top-row .time-title .round-ico { position: absolute; left: 50%; margin-left: -26px; bottom: 40px; z-index: 1; width: 52px; height: 52px; line-height: 44px; }
.wrap-timeline .top-row .time-title .round-ico .icon { font-size: 16px; text-align: center; color: #666666; }
.wrap-timeline .top-row .time-title .round-ico:after { content: ''; display: block; position: absolute; width: 2px; height: 52px; left: 50%; top: 42px; margin-left: -1px; background: #eff2f7; }
.wrap-timeline .row { position: relative; }
.wrap-timeline .row > .round-ico.big { position: absolute; top: -5px; left: 50%; margin-left: -20px; z-index: 1; }
.wrap-timeline .row > .round-ico.big:after { content: ''; display: block; position: absolute; width: 34px; height: 1px; left: 100%; top: 13px; background: #cacddc; }
.wrap-timeline .row > .round-ico.big:before { position: absolute; content: ''; display: block; width: 7px; height: 7px; right: -37px; top: 10px; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; background: #cacddc; }
.wrap-timeline .row > .round-ico.little { position: absolute; top: 4px; left: 50%; margin-left: -13px; z-index: 1; }
.wrap-timeline .row > .round-ico.little:after { content: ''; display: block; position: absolute; width: 34px; height: 1px; right: 100%; top: 6px; background: #cacddc; }
.wrap-timeline .row > .round-ico.little:before { position: absolute; content: ''; display: block; width: 7px; height: 7px; left: -37px; top: 3px; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; background: #cacddc; }
.wrap-timeline .left-row { text-align: right; margin-bottom: 20px; }
.wrap-timeline .right-row { text-align: left; margin-bottom: 20px; }
.wrap-timeline .right-row .time-item { padding-left: 80px; }
.wrap-timeline .left-row .time-item { padding-right: 80px; }
.wrap-timeline .time-item { padding-top: 10px; }
.wrap-timeline .time-item .date { font-size: 12px; text-transform: uppercase; color: #ff6400; margin-bottom: 10px; }
.wrap-timeline .time-item .time-image img { max-width: 100%; margin-bottom: 20px; width: auto; }
.wrap-timeline .time-item .title { font-size: 18px; color: #333333; font-weight: bold; margin-bottom: 15px; }
.wrap-timeline .time-item p { margin: 0; }
.wrap-timeline .plus { padding-bottom: 90px; }
.wrap-timeline .plus .plus-ico { display: block; z-index: 1; margin: 0 auto; position: relative; width: 50px; height: 50px; background: #fff; border: 5px solid #eeeef2; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -webkit-box-shadow: inset 0px 0px 0px 2px #cacddc; -moz-box-shadow: inset 0px 0px 0px 2px #cacddc; box-shadow: inset 0px 0px 0px 2px #cacddc; font-size: 30px; line-height: 40px; text-align: center; text-decoration: none; padding: 0; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; color: #cacddc; }
.wrap-timeline .plus .plus-ico:after { content: ''; display: block; position: absolute; width: 2px; height: 10px; left: 50%; margin-left: -1px; bottom: 100%; background: #eff2f7; }
.wrap-timeline .plus .plus-ico:hover { color: #fff; background: #ff6400; box-shadow: none; }

/******  SERVICE NAVIGATION ******/
.service-navigation { margin-bottom: 45px; }
.service-navigation li.active .navigation-icon, .service-navigation li:hover .navigation-icon { background: #ff6400; }
.service-navigation li.active .navigation-icon:after, .service-navigation li:hover .navigation-icon:after { border-top-color: #ff6400; }
.service-navigation li.active .navigation-icon .icon, .service-navigation li:hover .navigation-icon .icon { background: #ff6400; border-color: #ffb07c; color: #fff; }
.service-navigation .navigation-item { text-align: center; cursor: pointer; margin-bottom: 15px; }
.service-navigation .navigation-item .navigation-icon { margin: 0 auto 55px; width: 170px; max-width: 100%; background: #eff2f7; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; padding: 25px 0; text-align: center; position: relative; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.service-navigation .navigation-item .navigation-icon:after { content: ""; border: 15px solid transparent; border-top: 15px solid #eff2f7; display: block; position: absolute; top: 100%; left: 50%; margin-left: -15px; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.service-navigation .navigation-item .navigation-icon .icon { display: inline-block; width: 88px; height: 88px; line-height: 88px; border: 1px solid #fff; text-align: center; font-size: 32px; color: #666666; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; background: #fff; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.service-navigation .navigation-item h5:after { margin: 20px auto 0; }

.iphone-image img { display: block; margin-left: -280px; }

/******  SOCIAL LIST ******/
.social-list { margin-bottom: 15px; text-align: left; }
.social-list li { display: inline-block; margin: 0 2px; }
.social-list li a { display: block; width: 38px; height: 38px; border: 2px solid #eff2f7; -webkit-box-shadow: 0 0 1px #dfe1e5 inset; -moz-box-shadow: 0 0 1px #dfe1e5 inset; box-shadow: 0 0 1px #dfe1e5 inset; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; border-radius: 50%; line-height: 34px; text-align: center; background: #fff; }
.social-list li a i { font-size: 14px; color: #999999; -webkit-transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out; transition: all 0.3s ease-out; }
.social-list li a:hover { background: #ff6400; border-color: #ff6400; -webkit-box-shadow: 0 0 1px #ffb17f inset; -moz-box-shadow: 0 0 1px #ffb17f inset; box-shadow: 0 0 1px #ffb17f inset; }
.social-list li a:hover i { color: #fff; }

/******  COMMENTS ******/
.comments { padding: 30px 45px; border: 1px solid #eeeeee; margin-bottom: 40px; }
.comments h5 { margin-bottom: 45px; }
.comments .comment-item { padding: 15px 0 40px; margin-bottom: 25px; border-bottom: 1px solid #eee; }
.comments .comment-item:after { content: ""; clear: both; display: table; height: 0; }
.comments .comment-item:before { content: ""; clear: both; display: table; height: 0; }
.comments .comment-item:last-child { border-bottom: none; margin-bottom: 0; padding-bottom: 0; }
.comments .comment-item .media-left { padding-right: 30px; }
.comments .comment-item .media-left .avatar { width: 80px; height: 80px; }
.comments .comment-item .media-left .avatar img { width: 100%; }
.comments .comment-item .comment-title { margin-bottom: 15px; }
.comments .comment-item .comment-title span { color: #666666; font-size: 13px; }
.comments .comment-item .comment-title .name { color: #333333; font-size: 14px; font-weight: bold; text-transform: uppercase; }
.comments .comment-item .comment-title em { color: #999999; font-size: 13px; }
.comments .comment-item .btn-default { color: #999999; }
.comments .comment-item .btn-default:hover { color: #333; }

.comment-form { padding: 30px 45px; border: 1px solid #eeeeee; margin-bottom: 40px; }
.comment-form h5 { margin-bottom: 45px; }
.comment-form textarea { margin-bottom: 45px; resize: none; min-height: 120px; }
.comment-form .form-control { text-transform: uppercase; }

/****** LOADER ******/
.top-demo { padding: 50px 0 100px; background: url(../img/sections/section-11.jpg) top center no-repeat; background-size: cover; }
.top-demo .top-part img { margin-bottom: 50px; }

.demopage-preview { padding: 100px 0; }

.demopage-preview > div { padding-bottom: 70px; }

.demopage-preview_item { background-image: url("../img/demo/mac.png"); display: block; height: 314px; margin: 0 auto; padding: 17px 18px 85px 16px; width: 370px; }
.demopage-preview_item + h3 { text-align: center; font-size: 16px; line-height: 120%; color: #666; padding: 53px 0 29px; text-align: center; font-weight: normal; }

.demopage-preview_inner { float: left; height: 100%; overflow: hidden; position: relative; width: 100%; }

.demopage-preview_inner img { left: 0; position: absolute; top: 0; transition: all 4s ease 0s; width: 100%; }

.demopage-preview_inner:hover img { /*    top: -700px;*/ }

.demopage-footer { background-color: #333; padding: 46px 0; text-align: center; }
.demopage-footer span { color: #fff; font-size: 14px; font-weight: normal; text-align: center; }

/*MEDIA*/
@media (min-width: 1650px) { .with-top-effect { background-size: contain; } }
@media (max-width: 600px) { .offerimage{
  height: '30px';
  width: '100%';
  border-radius:'15px';  
  background-color: 'red'
}}
@media (max-width: 1200px) { .portfolio-item .portfolio-image img { height: 295px; }
  .portfolio-list-section .list-works.mini-list-works .portfolio-item .portfolio-image img { height: 212px; }
  .iphone-image img { margin-left: -180px; width: 484px; }
  .offers-section .text-item .dot-line { top: 6px; width: 73px; right: -98px; }
  .offers-section .text-item.top-item { padding-top: 0; margin-bottom: 0px; }
  .offers-section .text-item.top-item:after { top: 16px; }
  .offers-section .text-item.top-item .dot-line { top: 20px; }
  .offers-section .text-item.right-item.top-item .dot-line, .offers-section .text-item.right-item.bottom-item .dot-line { left: -98px; }
  .offers-section .text-item.middle-item { margin-bottom: 24px; } }
@media (max-width: 992px) { .portfolio-item .portfolio-image .portfolio-item-body { bottom: 15px; left: 15px; }
  .portfolio-item .portfolio-image img { height: 220px; }
  .achievements-section { padding-bottom: 150px; }
  .list-achieve .col-md-3:nth-child(2) .achieve-item:after { display: none; }
  .service-item h5 { text-align: center; }
  .service-item p { text-align: center; }
  .service-item .media-left { display: block; margin: 0 0 15px; text-align: center; padding-right: 0; }
  .service-item .media-left .wrap-service-icon { display: inline-block; margin: 0; }
  .service-item .media-body { width: auto; display: block; }
  .team-slider .slick-list .slick-track .slide-item { padding: 0 10px; }
  .team-slider .slick-list .slick-track .slide-item .image { margin-bottom: -200px; }
  .plan-item .item-body .list-features { padding: 35px 15px 20px 30px; }
  .iphone-image { margin-bottom: 30px; }
  .iphone-image img { margin-left: auto; margin-right: auto; width: auto; max-width: 100%; }
  .offers-section .text-item .dot-line { display: none; }
  .offers-section .text-item.left-item { text-align: left; padding-left: 20px; padding-right: 0; }
  .offers-section .text-item.left-item:after { left: -10px; right: auto; } }
@media (max-width: 767px) { .header { position: static !important; }
  .header .navbar { position: static !important; }
  body.fixed-header .scrolling-header .navbar { background: #2b2e33; padding-top: 35px; padding-bottom: 35px; }
  .portfolio-item .portfolio-image { height: 220px; }
  .portfolio-item .portfolio-image img { height: auto; min-height: 100%; min-width: 100%; max-width: 100%; }
  .portfolio-list-section .list-works.mini-list-works .portfolio-item .portfolio-image { height: 250px; }
  .portfolio-list-section .list-works.mini-list-works .portfolio-item .portfolio-image img { height: auto; min-height: 100%; min-width: 100%; max-width: 100%; }
  body.fixed-header .scrolling-header .navbar { background: #2b2e33; }
  body.fixed-header .scrolling-header .navbar .navbar-nav li a { color: #fff; }
  .header .navbar { background: #2b2e33; }
  .header .navbar .navbar-collapse { border: none; margin-top: 70px; }
  .header .navbar .navbar-collapse .navbar-nav { border: none; }
  .header .navbar .relative-nav-container .toggle-button { float: left; }
  .header .navbar .navbar-brand { display: block; left: 0; margin-left: auto; margin-right: auto; position: absolute; right: 0; width: 40px; }
  .header .navbar .navbar-brand img { margin: 0; }
  .header .navbar .navbar-nav.wrap-user-control { margin: 0; float: right; }
  .header .navbar .navbar-nav.wrap-user-control li { display: inline-block; vertical-align: middle; }
  .steps-list .step-item:after { display: none; }
  .list-achieve .col-md-3 .achieve-item:after { display: none; }
  .steps-list .step-item .item-icon { margin: 0 auto 15px; display: block; }
  .steps-list .step-item .item-text h5 { text-align: center; }
  .buy-section .section-text { margin-bottom: 15px; }
  .price-section .plans-list .col-md-4 { display: block; float: none; margin: 0 auto 30px; }
  .reviews-slider .slide-item .media-left { padding-right: 0; display: block; }
  .reviews-slider .slide-item .media-left .image-block { margin: 0 auto; }
  .reviews-slider .slide-item .media-body { width: auto; }
  .reviews-slider .slide-item .description-block .name { text-align: center; }
  .reviews-slider .slide-item .description-block .review { margin-left: 0; }
  .footer-section .col-md-3 { margin-bottom: 15px; }
  .wrap-timeline .container { padding-left: 40px; padding-right: 40px; }
  .wrap-timeline:after { content: ''; position: absolute; top: 0; display: block; bottom: 110px; left: 60px; width: 2px; margin-left: -1px; }
  .wrap-timeline .top-row .time-title { text-align: left; }
  .wrap-timeline .top-row .time-title .round-ico { position: absolute; left: 0; margin-left: -6px; text-align: center; bottom: 40px; }
  .wrap-timeline .row > .round-ico.little { left: 22px; margin-left: 0; }
  .wrap-timeline .row > .round-ico.little:after { left: 100%; }
  .wrap-timeline .row > .round-ico.little:before { right: -37px; left: auto; }
  .wrap-timeline .row > .round-ico.big { position: absolute; top: -5px; left: 35px; margin-left: -20px; }
  .wrap-timeline .right-row .time-item { padding-left: 110px; text-align: left; }
  .wrap-timeline .left-row .time-item { padding-left: 110px; text-align: left; padding-right: 0; }
  .wrap-timeline .time-item .time-image img { max-width: 100%; width: auto; }
  .wrap-timeline .plus { padding-bottom: 90px; }
  .wrap-timeline .plus .plus-ico { display: block; margin: 0 0 0 -5px; }
  .service-navigation .col-md-3 { width: 50%; }
  .wrap-inside-nav { position: static; padding: 0; }
  .wrap-inside-nav .inside-col { padding: 15px 0; border-right: none; border-bottom: 1px solid #26282d; }
  .wrap-inside-nav .inside-col h5 { display: none; }
  .wrap-inside-nav .inside-col .inside-nav li { float: left; width: 49%; }
  .wrap-inside-nav .inside-col .inside-nav li a { font-size: 12px; padding-left: 20px; } }
@media (max-width: 500px) { .buy-section { text-align: center; }
  .buy-section .section-text { padding-left: 0; }
  .buy-section .section-text .like { position: static; display: inline-block; margin-bottom: 15px; }
  .service-navigation .col-md-3 { width: 100%; }
  .content-tabs > li { margin: 0 0 20px 0; width: 100%; display: block; }
  /*.portfolio-list-section {*/
  .list-works .col-xs-6 { width: 100%; float: none; }
  .list-works .portfolio-item .portfolio-image { height: auto; }
  .list-works .portfolio-item .portfolio-image img { width: 100%; }
  /*}*/
  .list-works.mini-list-works .portfolio-item .portfolio-image { height: auto; }
  .list-works.mini-list-works .portfolio-item .portfolio-image img { width: 100%; }
  .services-section .col-md-4 { float: none; display: block; width: 100%; }
  .services-section .services-divider { display: none; }
  .team-slider .slick-list .slick-track .slide-item.slick-current + .slide-item .slide-description { opacity: 0; }
  .team-slider .slick-list .slick-track .slide-item.slick-current .slide-description { opacity: 1; }
  .list-achieve .col-md-3 { float: none; width: 100%; }
  .list-achieve .col-md-3 .achieve-item { margin-bottom: 30px; }
  .list-achieve .col-md-3 .achieve-item .count { padding: 10px 0; }
  .reviews-slider .slide-item .description-block .review { padding: 25px; }
  .latest-works-section .scroll-pane .scroll-bar-wrap { margin: 50px 15px; }
  .blog-content-section .left-column .wrap-blog-post .wrap-linked-image { min-height: 150px; }
  .comments .comment-item .media-left { display: block; width: auto; text-align: center; margin-bottom: 15px; }
  .comments .comment-item .media-left .avatar { display: inline-block; }
  .comments .comment-item .media-body { display: block; width: auto; }
  .latest-works-section .scroll-pane .scroll-bar-wrap .scroll-bar { max-width: 100%; } }
/*THEMES*/
.blue-theme .top-header { }
.blue-theme .btn.btn-primary:hover, .blue-theme .btn.btn-primary:focus, .blue-theme .btn.btn-primary:active:focus { background: #066dab; border-color: #066dab; }
.blue-theme .feature-item:hover .wrap-feature-icon .feature-icon, .blue-theme .feature-item.active .wrap-feature-icon .feature-icon { background: #088cdc; border-color: #5bb5eb; }
.blue-theme .feature-item:hover .wrap-feature-icon, .blue-theme .feature-item.active .wrap-feature-icon, .blue-theme .service-item:hover .wrap-service-icon .service-icon, .blue-theme .btn.btn-primary, .blue-theme .wrap-section-slider .owl-controls .owl-pagination .owl-page.active, .blue-theme .social-section .list-socials li a:hover { border-color: #088cdc; background: #088cdc; }
.blue-theme .feature-item .title:after, .blue-theme .steps-list .step-item .item-icon:before, .blue-theme .steps-list .step-item.invert .item-icon, .blue-theme .steps-section .results-container .skills li.orange .progress, .blue-theme .team-slider .slick-list .slick-track .slide-item .slide-description .contacts, .blue-theme .buy-section, .blue-theme .plan-item.active .item-footer .btn, .blue-theme h5:after { background: #088cdc; }
.blue-theme .list-achieve .col-md-3 .achieve-item:after, .blue-theme .latest-works-section .scroll-pane .scroll-content-item .name:before, .blue-theme .plan-item.active, .blue-theme .header .navbar .navbar-nav li.active a, .blue-theme .header .navbar .navbar-nav li a:hover, .blue-theme .design-arrow:after, .blue-theme .news-item .image .read-more:before { border-color: #088cdc; }
.blue-theme .video-section .btn-play, .blue-theme .plan-item .item-body .list-features li.active:before, .blue-theme .plan-item .item-heading .name, .blue-theme .wrap-section-slider .slide-item .slider-title i, .blue-theme .footer-section .footer-nav li a:hover, .blue-theme .footer-section .footer-nav li.active a, .blue-theme .header .navbar .navbar-nav.wrap-user-control li a { color: #088cdc; }
