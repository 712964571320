  @media (max-width: 700px) {
    .offerimage{
      height: 300px;
      width: 100%;
      border-radius:15px
  }
  }

   @media (min-width: 700px) {
    .offerimage{
      height: 500px;
      width: 100%;
      border-radius:15px
  }
  }

