.layout2 {
  width: 1500px;
  height: 2000px;
}
.layout2_0 {
  position: absolute;
  top: 222px;
  left: 233px;
  width: 46px;
  height: 39px;
  border: 2px solid black;
  clip-path: polygon(0% 9%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
}
.layout2_1 {
  position: absolute;
  top: 217px;
  left: 317px;
  width: 56px;
  height: 44px;
  border: 2px solid black;
  clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_2 {
  position: absolute;
  top: 213px;
  left: 373px;
  width: 56px;
  height: 48px;
  border: 2px solid black;
  clip-path: polygon(0% 8%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_3 {
  position: absolute;
  top: 206px;
  left: 466px;
  width: 56px;
  height: 55px;
  border: 2px solid black;
  clip-path: polygon(0% 7%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_4 {
  position: absolute;
  top: 203px;
  left: 521px;
  width: 58px;
  height: 58px;
  border: 2px solid black;
  clip-path: polygon(0% 6%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_5 {
  position: absolute;
  top: 197px;
  left: 615px;
  width: 56px;
  height: 28px;
  border: 2px solid black;
  clip-path: polygon(0% 13%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_6 {
  position: absolute;
  top: 193px;
  left: 670px;
  width: 57px;
  height: 32px;
  border: 2px solid black;
  clip-path: polygon(0% 14%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_7 {
  position: absolute;
  top: 187px;
  left: 765px;
  width: 56px;
  height: 38px;
  border: 2px solid black;
  clip-path: polygon(0% 8%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_8 {
  position: absolute;
  top: 183px;
  left: 820px;
  width: 57px;
  height: 42px;
  border: 2px solid black;
  clip-path: polygon(0% 9%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_9 {
  position: absolute;
  top: 176px;
  left: 926px;
  width: 57px;
  height: 49px;
  border: 2px solid black;
  clip-path: polygon(0% 8%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_10 {
  position: absolute;
  top: 172px;
  left: 982px;
  width: 57px;
  height: 53px;
  border: 2px solid black;
  clip-path: polygon(0% 7%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_11 {
  position: absolute;
   top: 166px;
  left: 1076px;
  width: 56px;
  height: 59px;
  border: 2px solid black;
  clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_12 {
  position: absolute;
  top: 162px;
  left: 1132px;
  width: 57px;
  height: 28px;
  border: 2px solid black;
  clip-path: polygon(0% 13%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_13 {
  position: absolute;
  top: 157px;
  left: 1225px;
  width: 54px;
  height: 33px;
  border: 2px solid black;
  clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout2_14 {
  position: absolute;
  top: 261px;
  left: 232px;
  width: 47px;
  height: 35px;
  border: 2px solid black;
  clip-path: polygon(2% 0%, 100% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
}
.layout2_15 {
  position: absolute;
  top: 261px;
  left: 317px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_16 {
  position: absolute;
  top: 261px;
  left: 373px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_17 {
  position: absolute;
  top: 261px;
  left: 466px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_18 {
  position: absolute;
  top: 261px;
  left: 522px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_19 {
  position: absolute;
  top: 225px;
  left: 615px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_20 {
  position: absolute;
  top: 225px;
  left: 671px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_21 {
  position: absolute;
  top: 225px;
  left: 765px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_22 {
  position: absolute;
  top: 225px;
  left: 821px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_23 {
  position: absolute;
  top: 225px;
  left: 926px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_24 {
  position: absolute;
  top: 225px;
  left: 982px;
  width: 57px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_25 {
  position: absolute;
  top: 190px;
  left: 1131px;
  width: 57px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_26 {
  position: absolute;
  top: 189px;
  left: 1225px;
  width: 54px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_27 {
  position: absolute;
  top: 225px;
  left: 1076px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_28 {
  position: absolute;
  top: 225px;
  left: 1132px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_29 {
  position: absolute;
  top: 225px;
  left: 1225px;
  width: 55px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_30 {
  position: absolute;
  top: 260px;
  left: 615px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_31 {
  position: absolute;
  top: 260px;
  left: 671px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_32 {
  position: absolute;
  top: 260px;
  left: 765px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_33 {
  position: absolute;
  top: 260px;
  left: 821px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_34 {
  position: absolute;
  top: 260px;
  left: 926px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_35 {
  position: absolute;
  top: 260px;
  left: 982px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_36 {
  position: absolute;
  top: 260px;
  left: 1076px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_37 {
  position: absolute;
  top: 260px;
  left: 1132px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_38 {
  position: absolute;
  top: 260px;
  left: 1225px;
  width: 55px;
  height: 35px;
  border: 2px solid black;
  clip-path: polygon(0 0, 98% 0, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
}
.layout2_39 {
  position: absolute;
  top: 296px;
  left: 231px;
  width: 48px;
  height: 35px;
  border: 2px solid black;
  clip-path: polygon(2% 0%, 100% 0px, 100% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
}
.layout2_40 {
  position: absolute;
  top: 296px;
  left: 317px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_41 {
  position: absolute;
  top: 296px;
  left: 373px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_42 {
  position: absolute;
  top: 296px;
  left: 466px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_43 {
  position: absolute;
  top: 296px;
  left: 522px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_44 {
  position: absolute;
  top: 295px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_45 {
  position: absolute;
  top: 295px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_46 {
  position: absolute;
  top: 295px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_47 {
  position: absolute;
  top: 295px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_48 {
  position: absolute;
  top: 295px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_49 {
  position: absolute;
  top: 295px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_50 {
  position: absolute;
  top: 295px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_51 {
  position: absolute;
  top: 295px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_52 {
  position: absolute;
  top: 295px;
  left: 1225px;
  width: 55px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_53 {
  position: absolute;
  top: 331px;
  left: 231px;
  width: 48px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0% 0%, 100% 0px, 100% 100%, 0% 100%);
}
.layout2_54 {
  position: absolute;
  top: 331px;
  left: 316px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_55 {
  position: absolute;
  top: 331px;
  left: 372px;
  width: 57px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_56 {
  position: absolute;
  top: 331px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_57 {
  position: absolute;
  top: 331px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_58 {
  position: absolute;
  top: 331px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_59 {
  position: absolute;
  top: 331px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_60 {
  position: absolute;
  top: 331px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_61 {
  position: absolute;
  top: 331px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_62 {
  position: absolute;
  top: 331px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_63 {
  position: absolute;
  top: 331px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_64 {
  position: absolute;
  top: 331px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_65 {
  position: absolute;
  top: 331px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_66 {
  position: absolute;
  top: 331px;
  left: 1225px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_67 {
  position: absolute;
  top: 367px;
  left: 230px;
  width: 49px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_68 {
  position: absolute;
  top: 367px;
  left: 317px;
  width: 55px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_69 {
  position: absolute;
  top: 367px;
  left: 317px;
  width: 55px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_70 {
  position: absolute;
  top: 367px;
  left: 372px;
  width: 57px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_71 {
  position: absolute;
  top: 367px;
  left: 466px;
  width: 56px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_72 {
  position: absolute;
  top: 367px;
  left: 522px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_73 {
  position: absolute;
  top: 367px;
  left: 615px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_74 {
  position: absolute;
  top: 367px;
  left: 671px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_75 {
  position: absolute;
  top: 367px;
  left: 765px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_76 {
  position: absolute;
  top: 367px;
  left: 821px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_77 {
  position: absolute;
  top: 367px;
  left: 926px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_78 {
  position: absolute;
  top: 367px;
  left: 982px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_79 {
  position: absolute;
  top: 367px;
  left: 1076px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_80 {
  position: absolute;
  top: 367px;
  left: 1132px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_81 {
  position: absolute;
  top: 367px;
  left: 1225px;
  width: 59px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_82 {
  position: absolute;
  top: 402px;
  left: 230px;
  width: 49px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_83 {
  position: absolute;
  top: 402px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_84 {
  position: absolute;
  top: 402px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_85 {
  position: absolute;
  top: 402px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_86 {
  position: absolute;
  top: 402px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_87 {
  position: absolute;
  top: 402px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_88 {
  position: absolute;
  top: 402px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_89 {
  position: absolute;
  top: 402px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_90 {
  position: absolute;
  top: 402px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_91 {
  position: absolute;
  top: 402px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_92 {
  position: absolute;
  top: 402px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_93 {
  position: absolute;
  top: 402px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_94 {
  position: absolute;
  top: 402px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_95 {
  position: absolute;
  top: 402px;
  left: 1225px;
  width: 63px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_96 {
  position: absolute;
  top: 438px;
  left: 229px;
  width: 50px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_97 {
  position: absolute;
   top: 438px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_98 {
  position: absolute;
   top: 438px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_99 {
  position: absolute;
   top: 438px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_100 {
  position: absolute;
   top: 438px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_101 {
  position: absolute;
   top: 438px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_102 {
  position: absolute;
   top: 438px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_103 {
  position: absolute;
   top: 438px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_104 {
  position: absolute;
   top: 438px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_105 {
  position: absolute;
   top: 438px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_106 {
  position: absolute;
   top: 438px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_107 {
  position: absolute;
   top: 438px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_108 {
  position: absolute;
   top: 438px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_109 {
  position: absolute;
  top: 438px;
  left: 1225px;
  width: 67px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_110 {
  position: absolute;
  top: 474px;
  left: 222px;
  width: 57px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_111 {
  position: absolute;
   top: 474px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_112 {
  position: absolute;
   top: 474px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_113 {
  position: absolute;
   top: 474px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_114 {
  position: absolute;
   top: 474px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_115 {
  position: absolute;
   top: 474px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_116 {
  position: absolute;
   top: 474px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_117 {
  position: absolute;
   top: 474px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_118 {
  position: absolute;
   top: 474px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_119 {
  position: absolute;
   top: 474px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_120 {
  position: absolute;
   top: 474px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_121 {
  position: absolute;
   top: 474px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_122 {
  position: absolute;
   top: 474px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_123 {
  position: absolute;
   top: 474px;
  left: 1225px;
  width: 71px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_124 {
  position: absolute;
   top: 510px;
  left: 215px;
  width: 64px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_125 {
  position: absolute;
   top: 510px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_126 {
  position: absolute;
   top: 510px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_127 {
  position: absolute;
   top: 510px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_128 {
  position: absolute;
   top: 510px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_129 {
  position: absolute;
   top: 510px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_130 {
  position: absolute;
   top: 510px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_131 {
  position: absolute;
   top: 510px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_132 {
  position: absolute;
   top: 510px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_133 {
  position: absolute;
   top: 510px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_134 {
  position: absolute;
   top: 510px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_135 {
  position: absolute;
   top: 510px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_136 {
  position: absolute;
   top: 510px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_137 {
  position: absolute;
   top: 510px;
  left: 1225px;
  width: 74px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_138 {
  position: absolute;
  top: 546px;
  left: 209px;
  width: 70px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_139 {
  position: absolute;
   top: 546px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_140 {
  position: absolute;
   top: 546px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_141 {
  position: absolute;
   top: 546px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_142 {
  position: absolute;
   top: 546px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_143 {
  position: absolute;
   top: 546px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_144 {
  position: absolute;
   top: 546px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_145 {
  position: absolute;
   top: 546px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_146 {
  position: absolute;
   top: 546px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_147 {
  position: absolute;
   top: 546px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_148 {
  position: absolute;
   top: 546px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_149 {
  position: absolute;
   top: 546px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_150 {
  position: absolute;
   top: 546px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_151 {
  position: absolute;
   top: 546px;
  left: 1225px;
  width: 78px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_152 {
  position: absolute;
  top: 582px;
  left: 201px;
  width: 78px;
  height: 34px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_153 {
  position: absolute;
  top: 582px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_154 {
  position: absolute;
  top: 582px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_155 {
  position: absolute;
  top: 582px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_156 {
  position: absolute;
  top: 582px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_157 {
  position: absolute;
  top: 582px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_158 {
  position: absolute;
  top: 582px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_159 {
  position: absolute;
  top: 582px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_160 {
  position: absolute;
  top: 582px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_161 {
  position: absolute;
  top: 582px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_162 {
  position: absolute;
  top: 582px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_163 {
  position: absolute;
  top: 582px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_164 {
  position: absolute;
  top: 582px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_165 {
  position: absolute;
  top: 582px;
  left: 1225px;
  width: 83px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_166 {
  position: absolute;
  top: 616px;
  left: 194px;
  width: 85px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_167 {
  position: absolute;
  top: 616px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_168 {
  position: absolute;
  top: 616px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_169 {
  position: absolute;
  top: 616px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_170 {
  position: absolute;
  top: 616px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_171 {
  position: absolute;
  top: 616px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_172 {
  position: absolute;
  top: 616px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_173 {
  position: absolute;
  top: 616px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_174 {
  position: absolute;
  top: 616px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_175 {
  position: absolute;
  top: 616px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_176 {
  position: absolute;
  top: 616px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_177 {
  position: absolute;
  top: 616px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_178 {
  position: absolute;
  top: 616px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_179 {
  position: absolute;
  top: 616px;
  left: 1225px;
  width: 86px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_180 {
  position: absolute;
  top: 651px;
  left: 188px;
  width: 91px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_181 {
  position: absolute;
  top: 651px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_182 {
  position: absolute;
  top: 651px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_183 {
  position: absolute;
  top: 651px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_184 {
  position: absolute;
  top: 651px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_185 {
  position: absolute;
  top: 651px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_186 {
  position: absolute;
  top: 651px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_187 {
  position: absolute;
  top: 651px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_188 {
  position: absolute;
  top: 651px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_189 {
  position: absolute;
  top: 651px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_190 {
  position: absolute;
  top: 651px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_191 {
  position: absolute;
  top: 651px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_192 {
  position: absolute;
  top: 651px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_193 {
  position: absolute;
  top: 651px;
  left: 1225px;
  width: 90px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_194 {
  position: absolute;
   top: 687px;
  left: 181px;
  width: 98px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_195 {
  position: absolute;
   top: 687px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_196 {
  position: absolute;
   top: 687px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_197 {
  position: absolute;
   top: 687px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_198 {
  position: absolute;
   top: 687px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_199 {
  position: absolute;
   top: 687px;
  left: 615px;
  width: 56px;
  height: 143px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_200 {
  position: absolute;
   top: 687px;
  left: 671px;
  width: 56px;
  height: 143px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_201 {
  position: absolute;
   top: 687px;
  left: 765px;
  width: 56px;
  height: 143px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_202 {
  position: absolute;
   top: 687px;
  left: 821px;
  width: 56px;
  height: 143px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_203 {
  position: absolute;
   top: 687px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_204 {
  position: absolute;
   top: 687px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_205 {
  position: absolute;
   top: 687px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_206 {
  position: absolute;
   top: 687px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_207 {
  position: absolute;
   top: 687px;
  left: 1225px;
  width: 94px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_208 {
  position: absolute;
   top: 722px;
  left: 174px;
  width: 105px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_209 {
  position: absolute;
   top: 722px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_210 {
  position: absolute;
   top: 722px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_211 {
  position: absolute;
   top: 722px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_212 {
  position: absolute;
   top: 722px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_213 {
  position: absolute;
   top: 722px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_214 {
  position: absolute;
   top: 722px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_215 {
  position: absolute;
   top: 722px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_216 {
  position: absolute;
   top: 722px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_217 {
  position: absolute;
   top: 722px;
  left: 1225px;
  width: 97px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_218 {
  position: absolute;
   top: 758px;
  left: 167px;
  width: 112px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_219 {
  position: absolute;
   top: 758px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_220 {
  position: absolute;
   top: 758px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_221 {
  position: absolute;
   top: 758px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_222 {
  position: absolute;
   top: 758px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_223 {
  position: absolute;
   top: 758px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_224 {
  position: absolute;
   top: 758px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_225 {
  position: absolute;
   top: 758px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_226 {
  position: absolute;
   top: 758px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_227 {
  position: absolute;
   top: 758px;
  left: 1225px;
  width: 113px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 1%, 89% 0, 90% 51%, 100% 71%, 100% 100%, 0 100%);
}
.layout2_228 {
  position: absolute;
   top: 794px;
  left: 160px;
  width: 119px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_229 {
  position: absolute;
   top: 794px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_230 {
  position: absolute;
   top: 794px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_231 {
  position: absolute;
   top: 794px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_232 {
  position: absolute;
   top: 794px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_233 {
  position: absolute;
   top: 794px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_234 {
  position: absolute;
   top: 794px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_235 {
  position: absolute;
   top: 794px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_236 {
  position: absolute;
   top: 794px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_237 {
  position: absolute;
   top: 794px;
  left: 1225px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_238 {
  position: absolute;
   top: 794px;
  left: 1281px;
  width: 57px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_239 {
  position: absolute;
  top: 783px;
  left: 1338px;
  width: 109px;
  height: 47px;
  border: 1px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 0 100%, 100% 100%);
}
.layout2_240 {
  position: absolute;
   top: 830px;
  left: 153px;
  width: 126px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_241 {
  position: absolute;
   top: 830px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_242 {
  position: absolute;
   top: 830px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_243 {
  position: absolute;
   top: 830px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_244 {
  position: absolute;
  top: 829px;
  left: 522px;
  width: 56px;
  height: 108px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_245 {
  position: absolute;
   top: 830px;
  left: 926px;
  width: 56px;
  height: 37px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_246 {
  position: absolute;
   top: 830px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_247 {
  position: absolute;
   top: 830px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_248 {
  position: absolute;
   top: 830px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_249 {
  position: absolute;
  top: 830px;
  left: 1375px;
  width: 69px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
}
.layout2_250 {
  position: absolute;
  top: 866px;
  left: 146px;
  width: 133px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_251 {
  position: absolute;
   top: 866px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_252 {
  position: absolute;
   top: 866px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_253 {
  position: absolute;
   top: 866px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_254 {
  position: absolute;
   top: 866px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_255 {
  position: absolute;
   top: 866px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_256 {
  position: absolute;
   top: 866px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_257 {
  position: absolute;
   top: 866px;
  left: 1225px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_258 {
  position: absolute;
   top: 866px;
  left: 1281px;
  width: 57px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_259 {
  position: absolute;
   top: 865px;
  left: 1375px;
  width: 55px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 0%, 78% 100%, 0 100%);
}
.layout2_260 {
  position: absolute;
  top: 901px;
  left: 167px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_261 {
  position: absolute;
  top: 901px;
  left: 223px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_262 {
  position: absolute;
  top: 901px;
  left: 317px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_263 {
  position: absolute;
  top: 901px;
  left: 373px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_264 {
  position: absolute;
  top: 901px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
/* .layout2_265 {
  position: absolute;
  top: 867px;
  left: 522px;
  width: 56px;
  height: 69px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
} */
.layout2_266 {
  position: absolute;
  top: 866px;
  left: 926px;
  width: 56px;
  height: 70px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_267 {
  position: absolute;
  top: 901px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_268 {
  position: absolute;
  top: 901px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_269 {
  position: absolute;
  top: 901px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_270 {
  position: absolute;
  top: 901px;
  left: 1225px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_271 {
  position: absolute;
  top: 901px;
  left: 1281px;
  width: 57px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_272 {
  position: absolute;
  top: 901px;
  left: 1375px;
  width: 42px;
  height: 107px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(100% 0, 0 0, 0 100%);
}
.layout2_273 {
  position: absolute;
  top: 937px;
  left: 167px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_274 {
  position: absolute;
  top: 937px;
  left: 223px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_275 {
  position: absolute;
  top: 937px;
  left: 317px;
  width: 56px;
  height: 142px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_276 {
  position: absolute;
  top: 937px;
  left: 373px;
  width: 56px;
  height: 142px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_277 {
  position: absolute;
  top: 937px;
  left: 466px;
  width: 56px;
  height: 142px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_278 {
  position: absolute;
  top: 936px;
  left: 522px;
  width: 56px;
  height: 142px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_279 {
  position: absolute;
  top: 936px;
  left: 926px;
  width: 56px;
  height: 142px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_280 {
  position: absolute;
  top: 937px;
  left: 982px;
  width: 56px;
  height: 142px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_281 {
  position: absolute;
  top: 937px;
  left: 1076px;
  width: 56px;
  height: 142px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_282 {
  position: absolute;
  top: 937px;
  left: 1132px;
  width: 56px;
  height: 142px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_283 {
  position: absolute;
  top: 937px;
  left: 1225px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_284 {
  position: absolute;
  top: 937px;
  left: 1281px;
  width: 57px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_285 {
  position: absolute;
  top: 960px;
  left: 105px;
  width: 25px;
  height: 118px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}
.layout2_286 {
  position: absolute;
   top: 972px;
  left: 167px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_287 {
  position: absolute;
   top: 972px;
  left: 223px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_288 {
  position: absolute;
   top: 972px;
  left: 1225px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_289 {
  position: absolute;
   top: 972px;
  left: 1281px;
  width: 57px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_290 {
  position: absolute;
  top: 1008px;
  left: 167px;
  width: 56px;
  height: 70px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_291 {
  position: absolute;
   top: 1008px;
  left: 223px;
  width: 56px;
  height: 70px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
/* .layout2_292 {
  position: absolute;
  top: 987px;
  left: 317px;
  width: 56px;
  height: 91px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_293 {
  position: absolute;
   top: 987px;
  left: 373px;
  width: 56px;
  height: 91px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_294 {
  position: absolute;
  top: 987px;
  left: 465px;
  width: 56px;
  height: 91px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_295 {
  position: absolute;
  top: 986px;
  left: 521px;
  width: 56px;
  height: 92px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_296 {
  position: absolute;
  top: 986px;
  left: 927px;
  width: 56px;
  height: 92px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_297 {
  position: absolute;
  top: 987px;
  left: 983px;
  width: 56px;
  height: 91px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_298 {
  position: absolute;
  top: 987px;
  left: 1076px;
  width: 56px;
  height: 91px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_299 {
  position: absolute;
  top: 987px;
  left: 1132px;
  width: 56px;
  height: 91px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
} */
.layout2_300 {
  position: absolute;
   top: 1008px;
  left: 1225px;
  width: 56px;
  height: 70px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_301 {
  position: absolute;
   top: 1008px;
  left: 1281px;
  width: 57px;
  height: 70px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_302 {
  position: absolute;
  top: 1126px;
  left: 219px;
  width: 60px;
  height: 70px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_303 {
  position: absolute;
   top: 1126px;
  left: 317px;
  width: 112px;
  height: 70px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_304 {
  position: absolute;
   top: 1126px;
  left: 466px;
  width: 56px;
  height: 141px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_305 {
  position: absolute;
   top: 1126px;
  left: 522px;
  width: 56px;
  height: 141px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_306 {
  position: absolute;
   top: 1126px;
  left: 615px;
  width: 56px;
  height: 141px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_307 {
  position: absolute;
   top: 1126px;
  left: 671px;
  width: 56px;
  height: 141px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_308 {
  position: absolute;
   top: 1126px;
  left: 765px;
  width: 56px;
  height: 141px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_309 {
  position: absolute;
   top: 1126px;
  left: 821px;
  width: 56px;
  height: 141px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_310 {
  position: absolute;
   top: 1126px;
  left: 926px;
  width: 56px;
  height: 141px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_311 {
  position: absolute;
   top: 1126px;
  left: 982px;
  width: 56px;
  height: 141px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_312 {
  position: absolute;
   top: 1126px;
  left: 1076px;
  width: 56px;
  height: 141px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_313 {
  position: absolute;
   top: 1126px;
  left: 1132px;
  width: 56px;
  height: 141px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_314 {
  position: absolute;
   top: 1126px;
  left: 1225px;
  width: 56px;
  height: 70px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_315 {
  position: absolute;
   top: 1126px;
  left: 1281px;
  width: 47px;
  height: 70px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 43% 100%, 0% 100%);
}
.layout2_316 {
  position: absolute;
  top: 1196px;
  left: 204px;
  width: 75px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_317 {
  position: absolute;
  top: 1196px;
  left: 317px;
  width: 112px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_318 {
  position: absolute;
  top: 1196px;
  left: 1225px;
  width: 77px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 82% 100%, 0% 100%);
}
.layout2_319 {
  position: absolute;
  top: 1231px;
  left: 197px;
  width: 82px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_320 {
  position: absolute;
  top: 1231px;
  left: 317px;
  width: 112px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_321 {
  position: absolute;
  top: 1231px;
  left: 1225px;
  width: 64px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 76% 100%, 0% 100%);
}
.layout2_322 {
  position: absolute;
  top: 1267px;
  left: 190px;
  width: 89px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_323 {
  position: absolute;
  top: 1267px;
  left: 317px;
  width: 112px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_324 {
  position: absolute;
  top: 1267px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_325 {
  position: absolute;
  top: 1267px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_326 {
  position: absolute;
  top: 1267px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_327 {
  position: absolute;
  top: 1267px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_328 {
  position: absolute;
  top: 1267px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_329 {
  position: absolute;
  top: 1267px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_330 {
  position: absolute;
  top: 1267px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_331 {
  position: absolute;
  top: 1267px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_332 {
  position: absolute;
  top: 1267px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_333 {
  position: absolute;
  top: 1267px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_334 {
  position: absolute;
  top: 1267px;
  left: 1225px;
  width: 49px;
  height: 127px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 0% 100%, 0% 100%);
}
.layout2_335 {
  position: absolute;
  top: 1302px;
  left: 183px;
  width: 96px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_336 {
  position: absolute;
  top: 1302px;
  left: 317px;
  width: 112px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_337 {
  position: absolute;
  top: 1302px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_338 {
  position: absolute;
  top: 1302px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_339 {
  position: absolute;
  top: 1302px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_340 {
  position: absolute;
  top: 1302px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_341 {
  position: absolute;
  top: 1302px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_342 {
  position: absolute;
  top: 1302px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_343 {
  position: absolute;
  top: 1302px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_344 {
  position: absolute;
  top: 1302px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_345 {
  position: absolute;
  top: 1302px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_346 {
  position: absolute;
  top: 1302px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_347 {
  position: absolute;
  top: 1338px;
  left: 176px;
  width: 103px;
  height: 28px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_348 {
  position: absolute;
  top: 1338px;
  left: 317px;
  width: 112px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_349 {
  position: absolute;
  top: 1338px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_350 {
  position: absolute;
  top: 1338px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_351 {
  position: absolute;
  top: 1338px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_352 {
  position: absolute;
  top: 1338px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_353 {
  position: absolute;
  top: 1338px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_354 {
  position: absolute;
  top: 1338px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_355 {
  position: absolute;
  top: 1338px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_356 {
  position: absolute;
  top: 1338px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_357 {
  position: absolute;
  top: 1338px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_358 {
  position: absolute;
  top: 1338px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_359 {
  position: absolute;
  top: 1374px;
  left: 304px;
  width: 125px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_360 {
  position: absolute;
  top: 1374px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_361 {
  position: absolute;
  top: 1374px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_362 {
  position: absolute;
  top: 1374px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_363 {
  position: absolute;
  top: 1374px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_364 {
  position: absolute;
  top: 1374px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_365 {
  position: absolute;
  top: 1374px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_366 {
  position: absolute;
  top: 1374px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_367 {
  position: absolute;
  top: 1374px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_368 {
  position: absolute;
  top: 1374px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_369 {
  position: absolute;
  top: 1374px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_370 {
  position: absolute;
  top: 1410px;
  left: 310px;
  width: 119px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_371 {
  position: absolute;
  top: 1410px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_372 {
  position: absolute;
  top: 1410px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_373 {
  position: absolute;
  top: 1410px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_374 {
  position: absolute;
  top: 1410px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_375 {
  position: absolute;
  top: 1410px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_376 {
  position: absolute;
  top: 1410px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_377 {
  position: absolute;
  top: 1410px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_378 {
  position: absolute;
  top: 1410px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_379 {
  position: absolute;
  top: 1410px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_380 {
  position: absolute;
  top: 1410px;
  left: 1132px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_381 {
  position: absolute;
  top: 1445px;
  left: 315px;
  width: 114px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_382 {
  position: absolute;
  top: 1445px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_383 {
  position: absolute;
  top: 1445px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_384 {
  position: absolute;
  top: 1445px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_385 {
  position: absolute;
  top: 1445px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_386 {
  position: absolute;
  top: 1445px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_387 {
  position: absolute;
  top: 1445px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_388 {
  position: absolute;
  top: 1445px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_389 {
  position: absolute;
  top: 1445px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_390 {
  position: absolute;
  top: 1445px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_391 {
  position: absolute;
  top: 1445px;
  left: 1132px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_392 {
  position: absolute;
  top: 1481px;
  left: 320px;
  width: 109px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_393 {
  position: absolute;
  top: 1481px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_394 {
  position: absolute;
  top: 1481px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_395 {
  position: absolute;
  top: 1481px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_396 {
  position: absolute;
  top: 1481px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_397 {
  position: absolute;
  top: 1481px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_398 {
  position: absolute;
  top: 1481px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_399 {
  position: absolute;
  top: 1481px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_400 {
  position: absolute;
  top: 1481px;
  left: 982px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_401 {
  position: absolute;
  top: 1481px;
  left: 1076px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_402 {
  position: absolute;
  top: 1479px;
  left: 1132px;
  width: 56px;
  height: 38px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 1%, 100% 0, 100% 23%, 81% 98%, 0 100%, 0% 50%);
}
.layout2_403 {
  position: absolute;
  top: 1516px;
  left: 325px;
  width: 104px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_404 {
  position: absolute;
  top: 1516px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_405 {
  position: absolute;
  top: 1516px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_406 {
  position: absolute;
  top: 1516px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_407 {
  position: absolute;
  top: 1516px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_408 {
  position: absolute;
  top: 1516px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_409 {
  position: absolute;
  top: 1516px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_410 {
  position: absolute;
  top: 1516px;
  left: 926px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_411 {
  position: absolute;
  top: 1516px;
  left: 982px;
  width: 57px;
  height: 29px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 100% 95%, 9% 59%, 0 100%);
}
.layout2_412 {
  position: absolute;
  top: 1516px;
  left: 1076px;
  width: 102px;
  height: 54px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 2%, 78% 100%, 0 68%);
}
.layout2_413 {
  position: absolute;
  top: 1552px;
  left: 330px;
  width: 99px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_414 {
  position: absolute;
  top: 1552px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_415 {
  position: absolute;
  top: 1552px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_416 {
  position: absolute;
  top: 1552px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_417 {
  position: absolute;
  top: 1552px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_418 {
  position: absolute;
  top: 1552px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_419 {
  position: absolute;
  top: 1552px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_420 {
  position: absolute;
  top: 1552px;
  left: 926px;
  width: 57px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 1%, 100% 0, 100% 0%, 87% 98%, 0 100%, 0% 50%);
}
.layout2_421 {
  position: absolute;
  top: 1588px;
  left: 334px;
  width: 95px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_422 {
  position: absolute;
  top: 1588px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_423 {
  position: absolute;
  top: 1588px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_424 {
  position: absolute;
  top: 1588px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_425 {
  position: absolute;
  top: 1588px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_426 {
  position: absolute;
  top: 1588px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_427 {
  position: absolute;
  top: 1588px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_428 {
  position: absolute;
  top: 1587px;
  left: 926px;
  width: 50px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 1%, 100% 0, 100% 0%, 87% 98%, 0 100%, 0% 50%);
}
.layout2_429 {
  position: absolute;
  top: 1623px;
  left: 338px;
  width: 91px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_430 {
  position: absolute;
  top: 1623px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_431 {
  position: absolute;
  top: 1623px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_432 {
  position: absolute;
  top: 1623px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_433 {
  position: absolute;
  top: 1623px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_434 {
  position: absolute;
  top: 1623px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_435 {
  position: absolute;
  top: 1623px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_436 {
  position: absolute;
  top: 1622px;
  left: 926px;
  width: 44px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 1%, 100% 0, 100% 0%, 87% 98%, 0 100%, 0% 50%);
}
.layout2_437 {
  position: absolute;
  top: 1659px;
  left: 342px;
  width: 87px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_438 {
  position: absolute;
  top: 1659px;
  left: 466px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_439 {
  position: absolute;
  top: 1659px;
  left: 522px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_440 {
  position: absolute;
  top: 1659px;
  left: 615px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_441 {
  position: absolute;
  top: 1659px;
  left: 671px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_442 {
  position: absolute;
  top: 1659px;
  left: 765px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_443 {
  position: absolute;
  top: 1659px;
  left: 821px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_444 {
  position: absolute;
  top: 1657px;
  left: 926px;
  width: 39px;
  height: 26px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 88% 100%, 0% 100%);
}
.layout2_445 {
  position: absolute;
  top: 1694px;
  left: 346px;
  width: 83px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_446 {
  position: absolute;
  top: 1694px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_447 {
  position: absolute;
  top: 1694px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_448 {
  position: absolute;
  top: 1694px;
  left: 615px;
  width: 56px;
  height: 39px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_449 {
  position: absolute;
  top: 1694px;
  left: 671px;
  width: 56px;
  height: 39px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_450 {
  position: absolute;
  top: 1694px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_451 {
  position: absolute;
  top: 1694px;
  left: 821px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_452 {
  position: absolute;
  top: 1683px;
  left: 927px;
  width: 34px;
  height: 47px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
}
.layout2_453 {
  position: absolute;
  top: 1730px;
  left: 351px;
  width: 78px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_454 {
  position: absolute;
  top: 1730px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_455 {
  position: absolute;
  top: 1730px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_456 {
  position: absolute;
  top: 1730px;
  left: 615px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_457 {
  position: absolute;
  top: 1730px;
  left: 671px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_458 {
  position: absolute;
  top: 1730px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_459 {
  position: absolute;
  top: 1730px;
  left: 821px;
  width: 56px;
  height: 160px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_460 {
  position: absolute;
  top: 1730px;
  left: 926px;
  width: 28px;
  height: 170px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 5% 100%, 0% 100%);
}
.layout2_461 {
  position: absolute;
  top: 1765px;
  left: 355px;
  width: 74px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_462 {
  position: absolute;
  top: 1765px;
  left: 466px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_463 {
  position: absolute;
  top: 1765px;
  left: 522px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_464 {
  position: absolute;
  top: 1765px;
  left: 615px;
  width: 56px;
  height: 39px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_465 {
  position: absolute;
  top: 1765px;
  left: 671px;
  width: 56px;
  height: 39px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_466 {
  position: absolute;
  top: 1765px;
  left: 765px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_467 {
  position: absolute;
  top: 1801px;
  left: 360px;
  width: 69px;
  height: 45px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_468 {
  position: absolute;
  top: 1801px;
  left: 466px;
  width: 56px;
  height: 55px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_469 {
  position: absolute;
  top: 1801px;
  left: 522px;
  width: 56px;
  height: 60px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_470 {
  position: absolute;
  top: 1801px;
  left: 615px;
  width: 56px;
  height: 38px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_471 {
  position: absolute;
  top: 1801px;
  left: 671px;
  width: 56px;
  height: 38px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_472 {
  position: absolute;
  top: 1801px;
  left: 765px;
  width: 56px;
  height: 35px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_473 {
  position: absolute;
  top: 1839px;
  left: 616px;
  width: 56px;
  height: 31px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_474 {
  position: absolute;
  top: 1839px;
  left: 672px;
  width: 56px;
  height: 36px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
.layout2_475 {
  position: absolute;
  top: 1836px;
  left: 766px;
  width: 55px;
  height: 48px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
}
