.layout6 {
    width: 1500px;
    height: 1500px;
  }
  
  .layout6_0 {
    position: absolute;
    height: 43px;
    left: 209px;
    top: 431px;
    width: 79px;
    clip-path: polygon(10% 0%, 81% 0, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(34deg);
    border: 2px;
  }
  
  .layout6_1 {
    position: absolute;
    height: 43px;
    left: 292px;
    top: 489px;
    width: 73px;
    clip-path: polygon(0 0, 80% 0, 100% 100%, 19% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(34deg);
    border: 2px;
    }
  .layout6_2 {
    position: absolute;
    height: 45px;
    left: 342px;
    top: 523px;
    width: 75px;
    clip-path: polygon(0 0, 80% 0, 100% 100%, 19% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(34deg);
    border: 2px;
  }
  
  .layout6_3 {
    position: absolute;
    height: 44px;
    left: 419px;
    top: 579px;
    width: 75px;
    clip-path: polygon(0 0, 80% 0, 100% 100%, 19% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(34deg);
    border: 2px;
  }
  
  .layout6_4 {
    position: absolute;
    height: 46px;
    left: 470px;
    top: 614px;
    width: 74px;
    clip-path: polygon(0 0, 80% 0, 100% 100%, 19% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(34deg);
    border: 2px;
  }
  
  .layout6_5 {
    position: absolute;
    height: 104px;
    left: 171px;
    top: 449px;
    width: 47px;
    clip-path: polygon(34% 0%, 97% 9%, 100% 100%, 19% 87%, 0% 34%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(17deg);
    border: 2px;
  }
  
  .layout6_6 {
    position: absolute;
    height: 94px;
    left: 200px;
    top: 480px;
    width: 70px;
    clip-path: polygon(0 0, 67% 4%, 100% 100%, 34% 97%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(31deg);
    border: 2px;
  }
  
  .layout6_7 {
    position: absolute;
  height: 95px;
  left: 267px;
  top: 532px;
  width: 85px;
  border: 2px;
  clip-path: polygon(0 0, 70% 5%, 100% 100%, 30% 97%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(31deg);
  }
  
  .layout6_8 {
    position: absolute;
    height: 95px;
    left: 316px;
    top: 567px;
    width: 85px;
  border: 2px;
  clip-path: polygon(0 0, 70% 5%, 100% 100%, 30% 97%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(31deg);
  }
  
  .layout6_9 {
    position: absolute;
  height: 95px;
  left: 394px;
  top: 624px;
  width: 85px;
  border: 2px;
  clip-path: polygon(0 0, 70% 4%, 100% 100%, 30% 97%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(31deg);
  }
  
  .layout6_10 {
    position: absolute;
    height: 95px;
    left: 444px;
    top: 659px;
    width: 85px;
  border: 2px;
  clip-path: polygon(0 0, 70% 5%, 100% 100%, 30% 97%);
  cursor: pointer;
  text-align: center;
  transform: rotateY(0deg) rotate(31deg);
  }
  
  .layout6_11 {
    position: absolute;
    top: 566px;
    left: 595px;
    width: 59px;
    height: 60px;
     border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(0% 0%, 87% 0%, 100% 100%, 12% 91%);
    cursor: pointer;
    text-align: center;
    transform-origin: right top;
  transform: rotateY(0deg) rotate(24deg);
}
  
  .layout6_12 {
    position: absolute;
    top: 626px;
    left: 576px;
    width: 65px;
    height: 50px;
     border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(0% 0%, 84% 0%, 100% 100%, 14% 84%);
    cursor: pointer;
    text-align: center;
    transform-origin: right top;
    transform: rotateY(0deg) rotate(29deg);
}
  
  .layout6_13 {
    position: absolute;
  top: 673px;
  left: 561px;
  width: 66px;
  height: 50px;
   border: 2px solid black;
  border-radius: 2px;
  clip-path: polygon(0% 0%, 84% 0%, 100% 100%, 19% 87%);
  cursor: pointer;
  text-align: center;
  transform-origin: right top;
  transform: rotateY(0deg) rotate(32deg);
  }
  
  .layout6_14 {
    position: absolute;
  top: 725px;
  left: 544px;
  width: 72px;
  height: 45px;
   border: 2px solid black;
  border-radius: 2px;
  clip-path: polygon(0% 0%, 75% 0%, 100% 100%, 22% 100%);
  cursor: pointer;
  text-align: center;
  transform-origin: right top;
  transform: rotateY(0deg) rotate(36deg);
  }
  
  .layout6_15 {
    position: absolute;
    top: 769px;
    left: 533px;
    width: 74px;
    height: 65px;
     border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(0% 0%, 75% 0%, 100% 100%, 22% 100%);
    cursor: pointer;
    text-align: center;
    transform-origin: right top;
    transform: rotateY(0deg) rotate(34deg);
  }
  
  .layout6_16 {
    position: absolute;
    top: 468px;
    left: 668px;
    width: 55px;
    height: 89px;
     border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(23% 0, 100% 23%, 100% 100%, 0% 100%, 0% 38%);
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_17 {
    position: absolute;
    top: 555px;
    left: 668px;
    width: 55px;
    height: 54px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_18 {
    position: absolute;
    top: 607px;
  left: 668px;
  width: 55px;
  height: 52px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_19 {
    position: absolute;
    top: 658px;
    left: 668px;
    width: 55px;
    height: 53px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
  }
  
  .layout6_20 {
    position: absolute;
    top: 710px;
    left: 668px;
    width: 55px;
    height: 52px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_21 {
    position: absolute;
    top: 761px;
    left: 668px;
    width: 55px;
    height: 77px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_22 {
    position: absolute;
    top: 465px;
    left: 755px;
    width: 55px;
    height: 101px;
    border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(53% 55%, 78% 0%, 100% 6%, 100% 100%, 0% 100%, 0% 43%);
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_23 {
    position: absolute;
    top: 564px;
    left: 755px;
    width: 55px;
    height: 54px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;

  }
  
  .layout6_24 {
    position: absolute;
    top: 616px;
  left: 755px;
  width: 55px;
  height: 51px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;

  }
  
  .layout6_25 {
    position: absolute;
    top: 666px;
    left: 755px;
    width: 55px;
    height: 53px;
         border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;

  }
  
  .layout6_26 {
    position: absolute;
    top: 718px;
    left: 755px;
    width: 55px;
    height: 52px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;

  }
  
  .layout6_27 {
    position: absolute;
    top: 768px;
    left: 755px;
    width: 55px;
    height: 71px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_28 {
    position: absolute;
  top: 471px;
  left: 808px;
  width: 55px;
  height: 146px;
  border: 2px solid black;
  border-radius: 2px;
  clip-path: polygon(0 0, 85% 19%, 100% 38%, 100% 100%, 0 100%);
  cursor: pointer;
  text-align: center;

  }
  
  .layout6_29 {
    position: absolute;
    top: 616px;
    left: 808px;
    width: 55px;
    height: 52px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;

  }
  
  .layout6_30 {
    position: absolute;
    top: 667px;
  left: 808px;
  width: 55px;
  height: 52px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;

  }
  
  .layout6_31 {
    position: absolute;
    top: 718px;
    left: 808px;
    width: 55px;
    height: 52px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;

  }
  
  .layout6_32 {
    position: absolute;
    top: 769px;
  left: 808px;
  width: 55px;
  height: 70px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    
  }
  
  .layout6_33 {
    position: absolute;
  top: 533px;
  left: 895px;
  width: 83px;
  height: 75px;
  border: 2px solid black;
  border-radius: 2px;
  clip-path: polygon(0 0, 100% 59%, 89% 100%, 0% 100%);
  cursor: pointer;
  text-align: center;
    
  }
  
  .layout6_34 {
    position: absolute;
    top: 608px;
    left: 895px;
    width: 74px;
    height: 51px;
    border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(100% 0, 92% 39%, 90% 100%, 0 100%, 0 0);
    cursor: pointer;
    text-align: center;
    
  }
  
  .layout6_35 {
    position: absolute;
    top: 658px;
    left: 895px;
    width: 65px;
    height: 53px;
    border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(0 0, 100% 0, 94% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;  
  }
  
  .layout6_36 {
    position: absolute;
    top: 710px;
    left: 895px;
    width: 61px;
    height: 53px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    
  }
  
  .layout6_37 {
    position: absolute;
    top: 762px;
    left: 895px;
    width: 61px;
    height: 77px;
  border: 2px solid black;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
    
  }
  
  .layout6_38 {
    position: absolute;
    top: 711px;
    left: 955px;
    width: 33px;
    height: 128px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 100% 23%, 100% 100%, 0% 100%);
    
  }
  
  .layout6_39 {
    position: absolute;
    top: 742px;
    left: 987px;
    width: 34px;
    height: 97px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 100% 23%, 100% 100%, 0% 100%);
    
  }
  
  .layout6_40 {
    position: absolute;
    top: 767px;
    left: 1019px;
    width: 50px;
    height: 73px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 100% 63%, 71% 100%, 0% 100%);

  }
  
  .layout6_41 {
    position: absolute;
    top: 610px;
    left: 1155px;
    width: 54px;
    height: 229px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 44%, 100% 0, 100% 100%, 0% 100%);
  }
  
  .layout6_42 {
    position: absolute;
    top: 505px;
    left: 1207px;
    width: 56px;
    height: 108px;
    border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_43 {
    position: absolute;
    top: 611px;
    left: 1207px;
    width: 56px;
    height: 49px;
     border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    
  }
  
  .layout6_44 {
    position: absolute;
    top: 659px;
    left: 1207px;
    width: 56px;
    height: 52px;
     border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%);
    cursor: pointer;
    text-align: center;
    
  }
  
  .layout6_45 {
    position: absolute;
    top: 710px;
    left: 1207px;
    width: 56px;
    height: 51px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;

  }
  
  .layout6_46 {
    position: absolute;
    top: 759px;
    left: 1207px;
    width: 56px;
    height: 80px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;

  }
  
  .layout6_47 {
    position: absolute;
    top: 520px;
    left: 1294px;
    width: 95px;
    height: 139px;
    border: 2px solid black;
    border-radius: 2px;
    clip-path: polygon(0 0, 21% 22%, 96% 81%, 100% 100%, 0 100%);
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_48 {
    position: absolute;
    top: 658px;
    left: 1295px;
    width: 49px;
    height: 53px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    
  }
  
  .layout6_49 {
    position: absolute;
    top: 710px;
    left: 1295px;
    width: 49px;
    height: 53px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;

  }
  
  .layout6_50 {
    position: absolute;
    top: 762px;
    left: 1295px;
    width: 49px;
    height: 77px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_51 {
    position: absolute;
    top: 658px;
    left: 1343px;
    width: 49px;
    height: 104px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 99% 2%, 100% 64%, 90% 100%, 0% 100%);
  }
  
  .layout6_52 {
    position: absolute;
    top: 761px;
    left: 1343px;
    width: 45px;
    height: 78px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 87% 100%, 0% 100%);
  }
  
  .layout6_53 {
    position: absolute;
    height: 64px;
    left: 131px;
    top: 635px;
    width: 134px;
    clip-path: polygon(0% 2%, 90% 6%, 100% 100%, 80% 86%, 7% 34%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(34deg);
    border: 2px;
  }
  
  .layout6_54 {
    position: absolute;
    height: 79px;
    left: 238px;
    top: 681px;
    width: 42px;
    clip-path: polygon(0% 0%, 64% 0%, 100% 96%, 16% 79%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(27deg);
    border: 2px;
  }
  
  .layout6_55 {
    position: absolute;
    height: 94px;
    left: 265px;
    top: 698px;
    width: 45px;
    clip-path: polygon(0% 0%, 76% 9%, 100% 100%, 12% 82%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(21deg);
    border: 2px;
  }
  
  .layout6_56 {
    position: absolute;
    height: 115px;
    left: 292px;
    top: 723px;
    width: 65px;
    clip-path: polygon(0% 0%, 67% 8%, 77% 100%, 12% 77%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(19deg);
    border: 2px;
  }
  
  .layout6_57 {
    position: absolute;
    height: 114px;
    left: 347px;
    top: 772px;
    width: 74px;
    border: 2px;
    clip-path: polygon(0 0, 66% 6%, 100% 90%, 37% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(31deg);
  }
  
  .layout6_58 {
    position: absolute;
    height: 97px;
    left: 395px;
    top: 800px;
    width: 53px;
    border: 2px;
    clip-path: polygon(0 0, 66% 4%, 100% 94%, 42% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(29deg);
  }
  
  .layout6_59 {
    position: absolute;
    height: 86px;
    left: 427px;
    top: 821px;
    width: 55px;
    border: 2px;
    clip-path: polygon(0 0, 66% 6%, 100% 90%, 34% 100%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(29deg);
  }
  
  .layout6_60 {
    position: absolute;
    height: 80px;
    left: 460px;
    top: 842px;
    width: 52px;
    border: 2px;
    clip-path: polygon(0 0, 66% 6%, 100% 100%, 35% 95%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(28deg);
  }
  
  .layout6_61 {
    position: absolute;
    height: 80px;
    left: 489px;
    top: 865px;
    width: 52px;
    border: 2px;
    clip-path: polygon(0 0, 66% 6%, 100% 100%, 35% 91%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(29deg);
  }
  
  .layout6_62 {
    position: absolute;
    height: 115px;
    left: 509px;
    top: 888px;
    width: 74px;
    border: 2px;
    clip-path: polygon(1% 0, 67% 6%, 100% 100%, 26% 74%);
    cursor: pointer;
    text-align: center;
    transform: rotateY(0deg) rotate(28deg);
  }
  
  .layout6_63 {
    position: absolute;
    top: 905px;
    left: 652px;
    width: 31px;
    height: 103px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_64 {
    position: absolute;
    top: 905px;
    left: 682px;
    width: 42px;
    height: 103px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_65 {
    position: absolute;
    top: 1007px;
    left: 652px;
    width: 72px;
    height: 58px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_66 {
    position: absolute;
    top: 1064px;
  left: 652px;
  width: 72px;
  height: 58px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_67 {
    position: absolute;
    top: 1121px;
    left: 652px;
    width: 71px;
    height: 96px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(50% 0%, 100% 0, 100% 48%, 100% 67%, 34% 100%, 17% 97%, 6% 80%, 0% 76%, 0 0);
  }
  
  .layout6_68 {
    position: absolute;
    top: 905px;
  left: 755px;
  width: 55px;
  height: 78px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_69 {
    position: absolute;
    top: 905px;
    left: 809px;
    width: 55px;
    height: 78px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    
  }
  
  .layout6_70 {
    position: absolute;
    top: 905px;
    left: 895px;
    width: 55px;
    height: 78px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    
  }
  
  .layout6_71 {
    position: absolute;
    top: 982px;
    left: 755px;
    width: 55px;
    height: 51px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_72 {
    position: absolute;
    top: 982px;
    left: 809px;
    width: 55px;
    height: 51px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_73 {
    position: absolute;
    top: 982px;
    left: 895px;
    width: 55px;
    height: 51px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_74 {
    position: absolute;
    top: 1032px;
    left: 755px;
    width: 55px;
    height: 52px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_75 {
    position: absolute;
    top: 1032px;
    left: 809px;
    width: 55px;
    height: 52px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_76 {
    position: absolute;
    top: 1032px;
    left: 895px;
    width: 55px;
    height: 108px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(100% 0, 100% 25%, 8% 100%, 0 100%, 0 0);
  }
  
  .layout6_77 {
    position: absolute;
    top: 1083px;
    left: 755px;
    width: 55px;
    height: 52px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_78 {
    position: absolute;
    top: 1083px;
    left: 809px;
    width: 55px;
    height: 52px;
     border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
  
  .layout6_79 {
    position: absolute;
    top: 1134px;
    left: 755px;
    width: 55px;
    height: 139px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 54%);
  }
  
  .layout6_80 {
    position: absolute;
    top: 1134px;
    left: 809px;
    width: 55px;
    height: 166px;
    border: 2px solid black;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(100% 0, 100% 30%, 40% 100%, 0 84%, 0 0);
  }
  
  .layout6_81 {
    position: absolute;
    top: 905px;
    left: 1067px;
    width: 55px;
    height: 78px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(33% 0%, 100% 0, 100% 100%, 0% 100%);
  }
  .layout6_82 {
    position: absolute;
    top: 905px;
    left: 1154px;
    width: 55px;
    height: 78px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_83 {
    position: absolute;
    top: 905px;
    left: 1208px;
    width: 55px;
    height: 78px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_84 {
    position: absolute;
  top: 905px;
  left: 1295px;
  width: 89px;
  height: 77px;
  border: 2px solid black;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(96% 0, 95% 48%, 100% 100%, 0 100%, 0 0);
  }
  .layout6_85 {
    position: absolute;
    top: 982px;
    left: 1057px;
    width: 65px;
    height: 50px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(18% 0%, 100% 0, 100% 100%, 0% 100%);
  }
  .layout6_86 {
    position: absolute;
    top: 982px;
    left: 1154px;
    width: 55px;
    height: 50px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_87 {
    position: absolute;
    top: 982px;
    left: 1208px;
    width: 55px;
    height: 50px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_88 {
    position: absolute;
    top: 982px;
    left: 1295px;
    width: 94px;
    height: 50px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 92% 0%, 100% 100%, 0% 100%);
  }
  .layout6_89 {
    position: absolute;
    top: 1030px;
    left: 1057px;
    width: 65px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_90 {
    position: absolute;
    top: 1030px;
  left: 1155px;
  width: 54px;
  height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_91 {
    position: absolute;
    top: 1030px;
    left: 1208px;
    width: 55px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_92 {
    position: absolute;
    top: 1031px;
    left: 1295px;
    width: 49px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_93 {
    position: absolute;
    top: 1031px;
    left: 1343px;
    width: 54px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 83% 0%, 100% 100%, 0% 100%);
  }
  .layout6_94 {
    position: absolute;
    top: 1082px;
    left: 1057px;
    width: 65px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_95 {
    position: absolute;
    top: 1084px;
    left: 1154px;
    width: 54px;
  height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_96 {
    position: absolute;
    top: 1084px;
    left: 1208px;
    width: 55px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_97 {
    position: absolute;
    top: 1084px;
    left: 1295px;
    width: 49px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_98 {
    position: absolute;
    top: 1084px;
    left: 1343px;
    width: 57px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 93% 0%, 100% 100%, 0% 100%);
  }
  .layout6_99 {
    position: absolute;
    top: 1135px;
    left: 1057px;
    width: 65px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_100 {
    position: absolute;
    top: 1135px;
  left: 1155px;
  width: 54px;
  height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_101 {
    position: absolute;
    top: 1135px;
    left: 1208px;
    width: 55px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_102 {
    position: absolute;
    top: 1135px;
    left: 1295px;
    width: 49px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_103 {
    position: absolute;
    top: 1135px;
    left: 1343px;
    width: 57px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 102% 0%, 100% 100%, 0% 100%);
  }
  .layout6_104 {
    position: absolute;
    top: 1187px;
    left: 1057px;
    width: 65px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_105 {
    position: absolute;
    top: 1187px;
  left: 1155px;
  width: 54px;
  height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_106 {
    position: absolute;
    top: 1187px;
    left: 1208px;
    width: 55px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_107 {
    position: absolute;
    top: 1187px;
    left: 1295px;
    width: 49px;
    height: 53px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_108 {
    position: absolute;
    top: 1187px;
    left: 1343px;
    width: 58px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 97% 0%, 100% 100%, 0% 100%);
  }
 
  .layout6_109 {
    position: absolute;
    top: 1240px;
    left: 1057px;
    width: 65px;
    height: 59px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 42%);
  }
  .layout6_110 {
    position: absolute;
    top: 1240px;
    left: 1155px;
    width: 54px;
    height: 98px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 77%);
  }
  .layout6_111 {
    position: absolute;
    top: 1240px;
    left: 1208px;
    width: 55px;
    height: 47px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_112 {
    position: absolute;
    top: 1240px;
    left: 1295px;
    width: 49px;
    height: 47px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
  }
  .layout6_113 {
    position: absolute;
    top: 1240px;
    left: 1343px;
    width: 60px;
    height: 48px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 96% 0%, 100% 100%, 0% 100%);
  }
  .layout6_114 {
    position: absolute;
    top: 1286px;
    left: 1208px;
    width: 55px;
    height: 65px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 79%);
  }
  .layout6_115 {
    position: absolute;
    top: 1286px;
    left: 1295px;
    width: 49px;
    height: 87px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 86%);
  }
  .layout6_116 {
    position: absolute;
    top: 1286px;
    left: 1343px;
    width: 67px;
    height: 98px;
    border: 2px solid black;
    cursor: pointer;
    text-align: center;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0 89%);
  }