#container {
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    /* height: 1000px; */
    /* margin-left: 50px; */
    /* margin-right: 50px; */
}

.layout10 {
    /* display: inline-block; */
    /* Styling */
    /*  */
    border: 1px solid white;
    width: 1500px;
    height: 700px;
}

.cen {
    text-align: center;
}

.center1 {
    position: relative;
    text-align: center;
}

.layout10_0 {
    position: absolute;
    top: 277px;
    left: 176px;
    width: 172px;
    height: 115px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 5% 100%);
}
.layout10_1 {
    position: absolute;
    top: 393px;
    left: 181px;
    width: 168px;
    height: 102px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 5% 100%);
}
.layout10_2 {
    position: absolute;
    top: 496px;
    left: 192px;
    width: 157px;
    height: 102px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 5% 100%);
}
.layout10_3 {
    position: absolute;
    top: 281px;
    left: 348px;
    width: 129px;
    height: 106px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_4 {
    position: absolute;
    top: 388px;
    left: 348px;
    width: 129px;
    height: 67px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_5 {
    position: absolute;
    top: 456px;
    left: 348px;
    width: 129px;
    height: 67px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_6 {
    position: absolute;
    top: 524px;
    left: 349px;
    width: 129px;
    height: 73px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_7 {
    position: absolute;
    top: 524px;
    left: 540px;
    width: 129px;
    height: 73px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_8 {
    position: absolute;
    top: 458px;
    left: 541px;
    width: 128px;
    height: 65px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_9 {
    position: absolute;
    top: 390px;
    left: 541px;
    width: 128px;
    height: 65px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_10 {
    position: absolute;
    top: 283px;
    left: 541px;
    width: 128px;
    height: 104px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_11 {
    position: absolute;
    top: 283px;
    left: 669px;
    width: 128px;
    height: 104px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_12 {
    position: absolute;
    top: 390px;
    left: 669px;
    width: 128px;
    height: 66px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_13 {
    position: absolute;
    top: 458px;
    left: 669px;
    width: 128px;
    height: 66px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_14 {
    position: absolute;
    top: 525px;
    left: 669px;
    width: 128px;
    height: 71px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_15 {
    position: absolute;
    top: 528px;
    left: 861px;
    width: 128px;
    height: 71px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_16 {
    position: absolute;
    top: 457px;
    left: 861px;
    width: 128px;
    height: 71px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_17 {
    position: absolute;
    top: 391px;
    left: 861px;
    width: 128px;
    height: 66px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_18 {
    position: absolute;
    top: 284px;
    left: 861px;
    width: 128px;
    height: 105px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_19 {
    position: absolute;
    top: 284px;
    left: 990px;
    width: 128px;
    height: 105px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_20 {
    position: absolute;
    top: 391px;
    left: 990px;
    width: 128px;
    height: 66px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_21 {
    position: absolute;
    top: 459px;
    left: 990px;
    width: 128px;
    height: 68px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_22 {
    position: absolute;
    top: 528px;
    left: 990px;
    width: 128px;
    height: 68px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_23 {
    position: absolute;
    top: 390px;
    left: 1179px;
    width: 128px;
    height: 81px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 1% 100%);
}
.layout10_24 {
    position: absolute;
    top: 472px;
    left: 1180px;
    width: 66px;
    height: 121px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 0% 100%);
}
.layout10_25 {
    position: absolute;
    top: 472px;
    left: 1247px;
    width: 65px;
    height: 121px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 102% 110%, 0% 100%);
}
.layout10_26 {
    position: absolute;
    top: 472px;
    left: 1313px;
    width: 85px;
    height: 121px;
    border: 2px solid black;
    cursor: pointer;
    transform: rotateY(0deg) rotate(0deg);
    clip-path: polygon(0 0, 100% 0, 68% 101%, 0% 108%);
}