#container {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  /* height: 1000px; */
  /* margin-left: 50px; */
  /* margin-right: 50px; */
}

.layout1 {
  /* display: inline-block; */
  /* Styling */
  /*  */
  border: 1px solid white;
  width: 1500px;
  height: 700px;
}
.cen {
  text-align: center;
}
.center1{
  position: relative;
  text-align: center;
}
.layout1_0 {
  position: absolute;
  top: 249px;
  left: 539px;
  width: 76px;
  height: 43px;
  border: 2px solid black;
  
  clip-path: polygon(12% 0, 100% 11%, 100% 100%, 0% 100%);
  border-top-right-radius: 11px;
  cursor: pointer;
  text-align: center;
}
.layout1_1 {
  position: absolute;
  top: 291px;
  left: 534px;
  width: 81px;
  height: 23px;
  border: 2px solid black;
  
  clip-path: polygon(6% 0, 100% 1%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout1_2 {
  position: absolute;
  top: 313px;
  left: 529px;
  width: 86px;
  height: 23px;
  border: 2px solid black;
  
  clip-path: polygon(7% 0, 100% 1%, 100% 100%, 0% 100%);
  cursor: pointer;
}
.layout1_3 {
  position: absolute;
  top: 335px;
  left: 525px;
  width: 90px;
  height: 22px;
  border: 2px solid black;
  
  clip-path: polygon(30% 100%, 0 80%, 5% 0, 100% 0, 100% 100%);
  cursor: pointer;
}
.layout1_4 {
  position: absolute;
  top: 356px;
  left: 556px;
  width: 59px;
  height: 22px;
  border: 2px solid black;
  
  clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 5% 100%, 5% 66%);
  cursor: pointer;
}

.layout1_5 {
  position: absolute;
  top: 378px;
  left: 559px;
  width: 56px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_6 {
  position: absolute;
  top: 399px;
  left: 559px;
  width: 56px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_7 {
  position: absolute;
  top: 421px;
  left: 559px;
  width: 56px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_8 {
  position: absolute;
  top: 443px;
  left: 560px;
  width: 55px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_9 {
  position: absolute;
  top: 464px;
  left: 561px;
  width: 54px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_10 {
  position: absolute;
  top: 486px;
  left: 562px;
  width: 53px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_11 {
  position: absolute;
  top: 507px;
  left: 562px;
  width: 52px;
  height: 25px;
  border: 2px solid black;
  
  border-bottom-right-radius: 5px;
  cursor: pointer;
}
.layout1_12 {
  position: absolute;
  top: 553px;
  left: 563px;
  width: 52px;
  height: 38px;
  border: 2px solid black;
  
  border-top-right-radius: 5px;
  cursor: pointer;
}
.layout1_13 {
  position: absolute;
  top: 591px;
  left: 564px;
  width: 51px;
  height: 38px;
  border: 2px solid black;
  
  border-bottom-right-radius: 5px;
  cursor: pointer;
}
.layout1_14 {
  position: absolute;
  top: 255px;
  left: 644px;
  width: 54px;
  height: 37px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 7%, 100% 100%, 0 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.layout1_15 {
  position: absolute;
  top: 257px;
  left: 698px;
  width: 53px;
  height: 35px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 11px;
}
.layout1_16 {
  position: absolute;
  top: 292px;
  left: 644px;
  width: 54px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_17 {
  position: absolute;
  top: 292px;
  left: 698px;
  width: 53px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_18 {
  position: absolute;
  top: 313px;
  left: 644px;
  width: 54px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_19 {
  position: absolute;
  top: 313px;
  left: 698px;
  width: 53px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_20 {
  position: absolute;
  top: 335px;
  left: 644px;
  width: 54px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_21 {
  position: absolute;
  top: 335px;
  left: 698px;
  width: 53px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_22 {
  position: absolute;
  top: 357px;
  left: 644px;
  width: 54px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_23 {
  position: absolute;
  top: 357px;
  left: 698px;
  width: 53px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_24 {
  position: absolute;
  top: 377px;
  left: 644px;
  width: 54px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_25 {
  position: absolute;
  top: 378px;
  left: 698px;
  width: 53px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_26 {
  position: absolute;
  top: 399px;
  left: 644px;
  width: 54px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_27 {
  position: absolute;
  top: 399px;
  left: 698px;
  width: 53px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_28 {
  position: absolute;
  top: 421px;
  left: 644px;
  width: 54px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_29 {
  position: absolute;
  top: 421px;
  left: 698px;
  width: 53px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_30 {
  position: absolute;
  top: 443px;
  left: 644px;
  width: 54px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_31 {
  position: absolute;
  top: 443px;
  left: 698px;
  width: 53px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_32 {
  position: absolute;
  top: 465px;
  left: 644px;
  width: 54px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_33 {
  position: absolute;
  top: 464px;
  left: 698px;
  width: 54px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_34 {
  position: absolute;
  top: 486px;
  left: 644px;
  width: 54px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_35 {
  position: absolute;
  top: 486px;
  left: 698px;
  width: 54px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_36 {
  position: absolute;
  top: 508px;
  left: 644px;
  width: 54px;
  height: 24px;
  border: 2px solid black;
  
  cursor: pointer;
  border-bottom-left-radius: 9px;
}
.layout1_37 {
  position: absolute;
  top: 508px;
  left: 698px;
  width: 54px;
  height: 24px;
  border: 2px solid black;
  
  cursor: pointer;
  border-bottom-right-radius: 9px;
}
.layout1_38 {
  position: absolute;
  top: 553px;
  left: 644px;
  width: 54px;
  height: 38px;
  border: 2px solid black;
  
  cursor: pointer;
  border-top-left-radius: 6px;
}
.layout1_39 {
  position: absolute;
  top: 553px;
  left: 698px;
  width: 54px;
  height: 38px;
  border: 2px solid black;
  
  cursor: pointer;
  border-top-right-radius: 6px;
}
.layout1_40 {
  position: absolute;
  top: 591px;
  left: 644px;
  width: 54px;
  height: 36px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_41 {
  position: absolute;
  top: 591px;
  left: 698px;
  width: 54px;
  height: 36px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_42 {
  position: absolute;
  top: 262px;
  left: 781px;
  width: 82px;
  height: 30px;
  border: 2px solid black;
  
  cursor: pointer;
  border-top-left-radius: 9px;
  border-top-right-radius: 15px;
  clip-path: polygon(0 0, 98% 14%, 93% 100%, 0 100%);
}
.layout1_43 {
  position: absolute;
  top: 292px;
  left: 781px;
  width: 77px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 96% 100%, 0 100%);
}
.layout1_44 {
  position: absolute;
  top: 313px;
  left: 781px;
  width: 74px;
  height: 23px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 94% 100%, 0 100%);
}
.layout1_45 {
  position: absolute;
  top: 335px;
  left: 781px;
  width: 71px;
  height: 23px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 96% 100%, 0 100%);
}
.layout1_46 {
  position: absolute;
  top: 357px;
  left: 781px;
  width: 68px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 96% 100%, 0 100%);
}
.layout1_47 {
  position: absolute;
  top: 378px;
  left: 781px;
  width: 66px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 96% 100%, 0 100%);
}
.layout1_48 {
  position: absolute;
  top: 400px;
  left: 781px;
  width: 63px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 96% 100%, 0 100%);
}
.layout1_49 {
  position: absolute;
  top: 421px;
  left: 781px;
  width: 61px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 96% 100%, 0 100%);
}
.layout1_50 {
  position: absolute;
  top: 443px;
  left: 781px;
  width: 58px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 94% 100%, 0 100%);
}
.layout1_51 {
  position: absolute;
  top: 464px;
  left: 781px;
  width: 55px;
  height: 22px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 96% 100%, 0 100%);
}
.layout1_52 {
  position: absolute;
  top: 486px;
  left: 781px;
  width: 52px;
  height: 21px;
  border: 2px solid black;
  
  cursor: pointer;
}
.layout1_53 {
  position: absolute;
  top: 507px;
  left: 781px;
  width: 52px;
  height: 25px;
  border: 2px solid black;
  
  cursor: pointer;
  border-bottom-left-radius: 9px;
}
.layout1_54 {
  position: absolute;
  top: 490px;
  left: 833px;
  width: 55px;
  height: 42px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%);
  border-bottom-right-radius: 10px;
}
.layout1_55 {
  position: absolute;
  top: 552px;
  left: 781px;
  width: 52px;
  height: 39px;
  border: 2px solid black;
  
  cursor: pointer;
  border-top-left-radius: 9px;
}
.layout1_56 {
  position: absolute;
  top: 553px;
  left: 833px;
  width: 53px;
  height: 38px;
  border: 2px solid black;
  
  cursor: pointer;
  border-top-right-radius: 9px;
}
.layout1_57 {
  position: absolute;
  top: 591px;
  left: 781px;
  width: 53px;
  height: 38px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 94%);
}
.layout1_58 {
  position: absolute;
  top: 591px;
  left: 833px;
  width: 53px;
  height: 40px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%);
}
.layout1_59 {
  position: absolute;
  top: 495px;
  left: 915px;
  width: 120px;
  height: 37px;
  border: 2px solid black;
  
  cursor: pointer;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 100%);
}
.layout1_60 {
  position: absolute;
  top: 552px;
  left: 915px;
  width: 57px;
  height: 39px;
  border: 2px solid black;
  
  cursor: pointer;
  border-top-left-radius: 9px;
}
.layout1_61 {
  position: absolute;
  top: 553px;
  left: 972px;
  width: 62px;
  height: 38px;
  border: 2px solid black;
  
  cursor: pointer;
  border-top-right-radius: 7px;
}
.layout1_62 {
  position: absolute;
  top: 591px;
  left: 915px;
  width: 57px;
  height: 44px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 91%);
}
.layout1_63 {
  position: absolute;
  top: 591px;
  left: 972px;
  width: 62px;
  height: 46px;
  border: 2px solid black;
  
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%);
}
