#container {
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    /* height: 1000px; */
    /* margin-left: 50px; */
    /* margin-right: 50px; */
}

.layout5 {
    /* display: inline-block; */
    /* Styling */
    /*  */
    border: 1px solid white;
    width: 1500px;
    height: 700px;
}

.cen {
    text-align: center;
}

.center1 {
    position: relative;
    text-align: center;
}

.layout5_0 {
    position: absolute;
    top: 148px;
    left: 199px;
    width: 137px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 7% 100%);
}

.layout5_1 {
    position: absolute;
    top: 148px;
    left: 336px;
    width: 101px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_2 {
    position: absolute;
    top: 148px;
    left: 438px;
    width: 89px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_3 {
    position: absolute;
    top: 148px;
    left: 527px;
    width: 88px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_4 {
    position: absolute;
    top: 148px;
    left: 614px;
    width: 84px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_5 {
    position: absolute;
    top: 148px;
    left: 698px;
    width: 80px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_6 {
    position: absolute;
    top: 148px;
    left: 779px;
    width: 80px;
    height: 55px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_7 {
    position: absolute;
    top: 145px;
    left: 859px;
    width: 77px;
    height: 58px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_8 {
    position: absolute;
    top: 145px;
    left: 936px;
    width: 80px;
    height: 58px;
    border: 2px solid black;

    cursor: pointer;
}

.layout5_9 {
    position: absolute;
    top: 145px;
    left: 1016px;
    width: 76px;
    height: 58px;
    border: 2px solid black;

    cursor: pointer;
}

.layout5_10 {
    position: absolute;
    top: 145px;
    left: 1092px;
    width: 84px;
    height: 58px;
    border: 2px solid black;

    cursor: pointer;
}

.layout5_11 {
    position: absolute;
    top: 247px;
    left: 216px;
    width: 123px;
    height: 74px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4px 100%, 6% 73%);
}

.layout5_12 {
    position: absolute;
    top: 246px;
    left: 339px;
    width: 101px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_13 {
    position: absolute;
    top: 246px;
    left: 440px;
    width: 90px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_14 {
    position: absolute;
    top: 246px;
    left: 530px;
    width: 88px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_15 {
    position: absolute;
    top: 246px;
    left: 618px;
    width: 82px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_16 {
    position: absolute;
    top: 246px;
    left: 701px;
    width: 80px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_17 {
    position: absolute;
    top: 246px;
    left: 781px;
    width: 80px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_18 {
    position: absolute;
    top: 245px;
    left: 861px;
    width: 78px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_19 {
    position: absolute;
    top: 245px;
    left: 939px;
    width: 78px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_20 {
    position: absolute;
    top: 244px;
    left: 1017px;
    width: 79px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_21 {
    position: absolute;
    top: 244px;
    left: 1096px;
    width: 84px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_22 {
    position: absolute;
    top: 320px;
    left: 211px;
    width: 128px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);
}

.layout5_23 {
    position: absolute;
    top: 320px;
    left: 339px;
    width: 102px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_24 {
    position: absolute;
    top: 320px;
    left: 441px;
    width: 90px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_25 {
    position: absolute;
    top: 320px;
    left: 531px;
    width: 88px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_26 {
    position: absolute;
    top: 320px;
    left: 619px;
    width: 83px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_27 {
    position: absolute;
    top: 320px;
    left: 702px;
    width: 80px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_28 {
    position: absolute;
    top: 320px;
    left: 782px;
    width: 80px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_29 {
    position: absolute;
    top: 320px;
    left: 863px;
    width: 76px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_30 {
    position: absolute;
    top: 320px;
    left: 939px;
    width: 79px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_31 {
    position: absolute;
    top: 319px;
    left: 1018px;
    width: 79px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_32 {
    position: absolute;
    top: 319px;
    left: 1097px;
    width: 83px;
    height: 75px;
    border: 2px solid black;
    cursor: pointer;
}

.layout5_33 {
    position: absolute;
    top: 437px;
    left: 201px;
    width: 244px;
    height: 51px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(2% 0, 100% 0, 100% 100%, 0 91%);
    border-top-left-radius: 4px;
}

.layout5_34 {
    position: absolute;
    top: 436px;
    left: 445px;
    width: 87px;
    height: 54px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
}

.layout5_35 {
    position: absolute;
    top: 436px;
    left: 533px;
    width: 87px;
    height: 58px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%);
}

.layout5_36 {
    position: absolute;
    top: 436px;
    left: 621px;
    width: 82px;
    height: 59px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 94%);
}

.layout5_37 {
    position: absolute;
    top: 435px;
    left: 702px;
    width: 82px;
    height: 60px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
}

.layout5_38 {
    position: absolute;
    top: 435px;
    left: 784px;
    width: 80px;
    height: 61px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
}

.layout5_39 {
    position: absolute;
    top: 435px;
    left: 864px;
    width: 78px;
    height: 63px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
}

.layout5_40 {
    position: absolute;
    top: 435px;
    left: 941px;
    width: 78px;
    height: 64px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
}

.layout5_41 {
    position: absolute;
    top: 434px;
    left: 1019px;
    width: 82px;
    height: 67px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
}

.layout5_42 {
    position: absolute;
    top: 434px;
    left: 1101px;
    width: 82px;
    height: 68px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 97%);
}

.layout5_43 {
    position: absolute;
    top: 146px;
    left: 1268px;
    width: 136px;
    height: 57px;
    border: 2px solid black;
    cursor: pointer;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 97%);
}